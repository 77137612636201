function buildFilterMap(filters) {
    if (!filters) return;

    var processedFilterForSearchRequest = [];
    filters.forEach(element => {
        //generate a key for each distinct sourceTyp
        if(!processedFilterForSearchRequest[element.sourceType])
            processedFilterForSearchRequest[element.sourceType] = {}
        if(element.requestObjectFieldType === "string")
            processedFilterForSearchRequest[element.sourceType][element.field] =  element.values.join(" "+element.filterType+" ")
        else
            processedFilterForSearchRequest[element.sourceType][element.field] =  element.values[0]
    })

    return processedFilterForSearchRequest;
}

function getSessionDurationSeconds(startTimeStamp) {
    var startTime = new Date(startTimeStamp).getTime();
    var now = new Date().getTime();
    var seconds = (now - startTime) / 1000;
    return Math.round(seconds);
}


export default function buildCustomRequest(state, configJson) {
    var sortField,sortOrder;

    if(state.sortField !== "relevance") {
        sortField = state.sortField;
        sortOrder = state.sortDirection;
    }

    var auth = sessionStorage.getItem("jwtToken");

    const queryString = state.searchTerm;
    const filterMap = buildFilterMap(state.filters, configJson);


    var guiLanguage = state.guiLanguage;


    var userID = sessionStorage.getItem("userID");
    var sessionDuration = getSessionDurationSeconds(sessionStorage.getItem("startTime"));

    var aggregations  = {
        fields: configJson.filterMenu.filter(element => element.showAsDashboardFacet && element.sourceType !== "metadataDateMap").map(element => element.field),
        size: configJson.generalOptions.globalSize
    }

    const body = {
        ...(auth && {auth}),
        ...(queryString && { queryString }),
        ...(sortOrder && { sortOrder }),
        ...(sortField && { sortField }),
        ...(guiLanguage && { guiLanguage }),
        ...(filterMap && filterMap),
        ...(userID && { userID }),
        ...(sessionDuration && { sessionDuration }),
        aggs: aggregations,

    };
    return body
}
