import {pushStateURLState, SET_INITIAL_STATE_FROM_URL} from "../actions/URLActions";
import {CHANGE_FILTER, CHANGE_SEARCH_TERM, CLEARED_FILTER, DEBOUNCE_SEARCH_TERM} from "../actions/FilterActions";
import {CHANGE_GUI_LANGUAGE, CHANGE_SORTING} from "../actions/DashboardAction";
import {SAVE_SINGLE_DOC} from "../actions/CacheViewActions";

const initialState = {
    filters: [],
    guiLanguage: localStorage.getItem('language') ? localStorage.getItem('language') : "de"
};

function URLReducer(state = initialState, action) {
    switch (action.type) {
        case SET_INITIAL_STATE_FROM_URL:
            // If the application is loaded the URL state is extracted from the URL.
            // If no url params are set, the url state is set to some default values.
            state =  action.payload.URLState
            state.URLString = pushStateURLState(state);
            return {
                ...state
            }
        case CHANGE_FILTER:
            // The filter array is replaced, which provokes a re-rendering in all relevant components.
            state.filters = action.payload.filters
            // If we are in the "cacheview" we have an id in the url state.
            // If we change the view to "dashboard", we need to remove the id from the url state.
            delete state['id']
            // We keep the url state in the url and inside this reducer, but we also keep a urlString which contains all the url params.
            // This string is used in case of a redirect.
            state.URLString = pushStateURLState(state);
            return {
                ...state
            }
        case CHANGE_SEARCH_TERM:
            state.searchTerm = action.payload;
            state.URLString = pushStateURLState(state);
            return {
                ...state
            }
        case DEBOUNCE_SEARCH_TERM:
            // If we change the search term in the "searchbox.js" components, the search term in the url is updated even when no search is triggered.
            // If we then reload the application, the search term from the url is used for a new initial search request.
            // If we add a filter the new search term is used for the search.
            state.searchTerm = action.payload;
            state.URLString = pushStateURLState(state);
            return {
                ...state
            }
        case CLEARED_FILTER:
            state.filters = action.payload.filters
            state.URLString = pushStateURLState(state);
            return {
                ...state
            }
        case CHANGE_GUI_LANGUAGE:
            localStorage.setItem('language', action.payload);
            state.guiLanguage =  action.payload
            state.URLString = pushStateURLState(state);
            return {
                ...state
            }
        case CHANGE_SORTING:
            state.sortDirection = action.payload.direction
            state.sortField = action.payload.field
            state.URLString = pushStateURLState(state);
            return{
                ...state
            }
        case SAVE_SINGLE_DOC:
            // If we change the document in the "cacheview", we update the id in the url state.
            state.id = action.leid
            state.URLString = pushStateURLState(state);
            return {
                ...state
            }
        default:
            return state
    }
}
export default URLReducer;