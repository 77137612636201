import React from "react";
import {connect} from "react-redux";
import "./weblaw_titlelabel.css"
import "./weblaw_titlelabel.css"
import {format} from "date-fns";


class TitleLabel extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        };

    }

    getHeaderPrefix = () => {
        return this.props.item.headerPrefix ? this.props.item.headerPrefix[this.props.guiLanguage] : ""
    }

    generateMetadataKeywordTextMap = () => {
        return String(this.getHeaderPrefix() +  this.props.keyWordTextValue[0]).replace(/ /g, '\u00a0')
                + (this.props.keyWordTextValue.length>1 ? ", " : " ")
                + this.props.keyWordTextValue.slice(1,this.props.keyWordTextValue.length).map(element => element.replace(/ /g, '\u00a0')).join(", ")
    }

    generateMetadataDateMap = () => {
        return this.getHeaderPrefix() +  format(new Date(this.props.dateValue), this.props.configJson.generalOptions.dateFormatLabels)
    }

    render() {

        return  <span className={"titleLabel"}>
                    {this.props.item.metadataKeywordTextMap ? this.generateMetadataKeywordTextMap() : null}
                    {this.props.item.metadataDateMap ? this.generateMetadataDateMap() : null}
                </span>
    }
}

function mapStateToProps(state) {
    const { ConfigReducer, URLReducer} = state
    const {  configJson, translationsConfig} = ConfigReducer
    const { guiLanguage } = URLReducer
    return {configJson, translationsConfig, guiLanguage}
}

export default connect(mapStateToProps) (TitleLabel);