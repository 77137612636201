import {insertReferencesInto, markSearchTerms} from "./MarkService";
import MarkedHTMLContent from "../views/CacheView/MarkedElementsComponents/MarkedHTMLContent";
import {createReactJSXFromString} from "./ReactAndHTMLParserService";
import React from "react";
import MarkedSearchTerm from "../views/CacheView/MarkedElementsComponents/MarkedSearchTerm";
import {attributesToProps} from "html-react-parser";
import { LangSplitter } from "./LangSplitter";

export function prepareHTMLContentForCacheView(response, configJson, URLState) {
    var currentDocumentData = response;
    var html = currentDocumentData.content;
    var title = 'title' in currentDocumentData.metadataKeywordTextMap ? currentDocumentData.metadataKeywordTextMap.title : currentDocumentData.metadataKeywordTextMap.fileName;
    var convertedStringTOHTMLForMarkJS
    var convertedHTMLToStringForReactParser;
    var convertedTitleStringTOHTMLForMarkJS;
    var convertedTitleHTMLToStringForReactParser;
    var numberOfMarkedWords = 0;
    var setOverflowProperty;
    var parsedJSX;
    var parsedJSXMap = [];
    var parsedJSXHeader

// If a list is in the "isMarkable" property, then it is not markable, otherwise the "isMarkable" entry is undefined, and then it is markable
    if (!currentDocumentData?.metadataKeywordTextMap?.isMarkable) {
        convertedStringTOHTMLForMarkJS = document.createElement('div');

        convertedStringTOHTMLForMarkJS.innerHTML = (JSON.parse(sessionStorage.getItem('turnOnReference')) ? insertReferencesInto(html, currentDocumentData, configJson.filterMenu) : html)
            .toString()
            .replace("<p>", "<div>")
            .replace("</p>", "</div>")
            .replace(/'>/gi, "'> ")
            .trim();


        markSearchTerms(convertedStringTOHTMLForMarkJS, URLState, configJson)

        convertedHTMLToStringForReactParser = convertedStringTOHTMLForMarkJS.innerHTML

        convertedTitleStringTOHTMLForMarkJS = document.createElement('div');

        if (title)
            document.title = LangSplitter.getKeyFromLanguage(URLState.guiLanguage,String(title))

        convertedTitleStringTOHTMLForMarkJS.innerHTML = String(title).replace(/_/g, '_&#8203;').trim()

        markSearchTerms(convertedTitleStringTOHTMLForMarkJS, URLState, configJson)

        convertedTitleHTMLToStringForReactParser = convertedTitleStringTOHTMLForMarkJS.innerHTML

        setOverflowProperty = convertedHTMLToStringForReactParser.includes("<table>");

        // You can set the overflow property global at this point
        //setOverflowProperty = true

        function textNodesUnder(node){
            var all = [];
            for (node=node.firstChild;node;node=node.nextSibling){
                if (node.nodeType===3) all.push(node.data);
                else all = all.concat(textNodesUnder(node));
            }
            return all;
        }

        var htmlReplaceCallback = (domNode) => {
            if (domNode.attribs && domNode.attribs.class === 'replace') {
                const props = attributesToProps(domNode.attribs);
                return <MarkedHTMLContent key={props.occurrence} {...props}/>;
            }
            if (domNode.attribs && domNode.attribs.class === 'selectedSearchTermWord') {
                var allTextNodes = textNodesUnder(domNode)
                return <MarkedSearchTerm text={allTextNodes.join("").toString()} number={numberOfMarkedWords++}/>;
            }
        }
        parsedJSXHeader = createReactJSXFromString(convertedTitleHTMLToStringForReactParser, htmlReplaceCallback);
        parsedJSX = createReactJSXFromString(convertedHTMLToStringForReactParser, htmlReplaceCallback);

    } else {
        convertedHTMLToStringForReactParser = html

        parsedJSXHeader = title && createReactJSXFromString(String(title).replace(/_/g, '_<wbr/>'));

        parsedJSXMap.push(createReactJSXFromString(LangSplitter.getKeyFromLanguage("de",convertedHTMLToStringForReactParser)));
        parsedJSXMap.push(createReactJSXFromString(LangSplitter.getKeyFromLanguage("fr",convertedHTMLToStringForReactParser)));
        parsedJSXMap.push(createReactJSXFromString(LangSplitter.getKeyFromLanguage("it",convertedHTMLToStringForReactParser)));
        parsedJSXMap.push(createReactJSXFromString(LangSplitter.getKeyFromLanguage("en",convertedHTMLToStringForReactParser)));
    }

    return {
        numberOfMarkedWords: numberOfMarkedWords,
        parsedJSXHeader: parsedJSXHeader,
        parsedJSX: parsedJSX,
        parsedJSXMap: parsedJSXMap,
        setOverflowProperty: setOverflowProperty
    }

}