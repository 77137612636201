export function getLinkStringForLanguage(reference, guiLanguage) {
    let linkString ;
    reference.meta?.forEach(item => {
        if(item.key === "linkString"){
            if(item.value[guiLanguage]!== undefined) {
                linkString = item.value[guiLanguage];
            }
        }
    });
    return linkString;
}

export function hasLinkStringForLanguage(reference, guiLanguage) {
    let linkString ;
    reference.meta?.forEach(item => {
        if(item.key === "linkString"){
            if(item.value[guiLanguage]!== undefined) {
                linkString = item.value[guiLanguage];
            }
        }
    });
    if(linkString === undefined) {
        return false;
    }else {
        return true;
    }
}

export function getLinkStringMap(reference) {
    let linkStringMap ;
    reference.meta?.forEach(item => {
        if(item.key === "linkString"){
            linkStringMap = item.value;
        }
    });
    return linkStringMap;
}