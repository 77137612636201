import React from "react";
import {Button, Icon} from "semantic-ui-react";
import {saveSortInformationToRedux} from "../../../actions/CacheViewActions";
import {connect} from "react-redux";


class SortingButtons extends  React.Component {
    constructor(props) {
        super(props);
        this.state = {
            sortingIcon : sessionStorage.getItem('sortingIcon' ) ? sessionStorage.getItem('sortingIcon') : 'undo alternate'
        }
    }

    sortFilterFieldsByText = (index, sortOrder) => {
        this.setState({sortingIcon: sortOrder === 1 ? 'sort alphabet down' : 'sort alphabet up'})
        sessionStorage.setItem('sortingIcon', sortOrder === 1 ? 'sort alphabet down' : 'sort alphabet up')
        let labelSorting = {"turnOnSorting": true, "alphabeticSorting": true, "sortDirection": sortOrder};
        this.props.saveSortInformationToRedux(labelSorting);
    }

    sortFilterFieldsByOccurence=(index, sortOrder) => {
        this.setState({sortingIcon: sortOrder === 1 ? 'sort numeric down' : 'sort numeric up'})
        sessionStorage.setItem('sortingIcon',sortOrder === 1 ? 'sort numeric down' : 'sort numeric up')
        let labelSorting = {"turnOnSorting": true, "alphabeticSorting": false, "sortDirection": sortOrder};
        this.props.saveSortInformationToRedux(labelSorting);
    }

    sortDefault= () => {
        this.setState({sortingIcon: 'undo alternate'})
        sessionStorage.setItem('sortingIcon', 'undo alternate')
        let labelSorting = {"turnOnSorting": null, "alphabeticSorting": null, "sortDirection": null};
        this.props.saveSortInformationToRedux(labelSorting);
    }

    render() {
        return <div id={"switchShowMarkingContainer"} style={{marginBottom: "40px"}}>
                    <Button onClick={() => this.sortDefault()} id={(this.state.sortingIcon === "undo alternate" ? "activeMarkingButton" : "notActiveMarkingButton")} basic>
                        <Icon name={"undo alternate"}/>
                    </Button>
                    <Button onClick={() => this.sortFilterFieldsByText("",1)} id={(this.state.sortingIcon === "sort alphabet down" ? "activeMarkingButton" : "notActiveMarkingButton")} basic>
                        <Icon name={"sort alphabet down"}/>
                    </Button>
                    <Button onClick={() => this.sortFilterFieldsByText("",-1)} id={(this.state.sortingIcon === "sort alphabet up" ? "activeMarkingButton" : "notActiveMarkingButton")} basic>
                        <Icon name={"sort alphabet up"}/>
                    </Button>
                    <Button onClick={() => this.sortFilterFieldsByOccurence("",1)} id={(this.state.sortingIcon === "sort numeric down" ? "activeMarkingButton" : "notActiveMarkingButton")} basic>
                        <Icon name={"sort numeric down"}/>
                    </Button>
                    <Button onClick={() => this.sortFilterFieldsByOccurence("",-1)} id={(this.state.sortingIcon === "sort numeric up" ? "activeMarkingButton" : "notActiveMarkingButton")} basic>
                        <Icon name={"sort numeric up"}/>
                    </Button>
                </div>;
    }
}


function mapStateToProps(state) {
    return {};
}
const mapDispatchToProps = {
    saveSortInformationToRedux
};

export default connect(mapStateToProps,mapDispatchToProps)( SortingButtons);