import { sessionService } from 'redux-react-session';
import thunk from 'redux-thunk';
import rootReducer from '../reducer/rootReducer';
import { configureStore } from '@reduxjs/toolkit'

export default function configStore(initialState) {
  const store = configureStore({
    reducer: rootReducer,
    preloadedState: initialState,
    middleware: [thunk]
  })
  sessionService.initSessionService(store);
  return store;
}
