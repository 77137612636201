import React from "react";
import {connect} from "react-redux";
import {Divider, Grid, Header, Loader, Ref, Segment} from "semantic-ui-react";
import JumpPortal from "../../MarkedElementsComponents/JumpPortal";
import {goBackToDashboard, sendContentBoxDetails} from "../../../../actions/CacheViewActions";
import SideMenuComputer from "./ContentSegmentComputerComponents/SideMenuComputer";
import OriginalLink from "../../../sharedComponents/OriginalLink";
import "./weblaw_contentsegment_computer.css"
import TitleLabel from "../../../sharedComponents/TitleLabel/TitleLabel";
import { LangSplitter } from "../../../../services/LangSplitter";

class ContentSegmentComputer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        }
        this.refToKeepTheSideMenuSticky = React.createRef();
        this.refWhichContainsAllMarkedHTMLelements = React.createRef();
    }

    componentDidMount() {
        this.props.sendContentBoxDetails({width: this.refWhichContainsAllMarkedHTMLelements?.current?.offsetWidth, xPosition: this.refWhichContainsAllMarkedHTMLelements.current?.getBoundingClientRect()?.x})
    }

    componentDidUpdate(prevProps: Readonly<P>, prevState: Readonly<S>, snapshot: SS) {
        //if the size of refWhichContainsAllMarkedHTMLelements changes, we update the redux store
        // those values are used in, marked html component to compute the translation and scaling of the labels
        if(this.props.contentBoxXPosition !== this.refWhichContainsAllMarkedHTMLelements.current?.getBoundingClientRect()?.x || this.props.contentBoxWidth !== this.refWhichContainsAllMarkedHTMLelements?.current?.offsetWidth)
            this.props.sendContentBoxDetails({width: this.refWhichContainsAllMarkedHTMLelements?.current?.offsetWidth, xPosition: this.refWhichContainsAllMarkedHTMLelements.current?.getBoundingClientRect()?.x})
    }

    computeContentWidth = () => {
        const constantSizeOfSideContent = 450
        return (this.props.viewportWidth - constantSizeOfSideContent)*0.90
    }

    filterAndEnrichConfigAccordingToValuesFromDocument = (element) => {
        element.metadataKeywordTextMap = element.field in this.props.metadataKeywordTextMap
        element.metadataDateMap = element.field.replace(/List$/, '') in this.props.metadataDateMap
        return element.metadataKeywordTextMap || element.metadataDateMap
    }


    hasTitleLabels = () => {
        return this.props.metadataKeywordTextMap &&
            this.props.configJson.filterMenu.filter(element => element.showInCacheViewHeader)
            .filter(element => this.filterAndEnrichConfigAccordingToValuesFromDocument(element)).length>0
    }

    render() {
        if (this.props.singleDocumentData.metadataKeywordTextMap && this.props.singleDocumentData.metadataKeywordTextMap["title"] && LangSplitter.getKeyFromLanguage(this.props.guiLanguage,String(this.props.singleDocumentData.metadataKeywordTextMap["title"])))
            document.title = LangSplitter.getKeyFromLanguage(this.props.guiLanguage,String(this.props.singleDocumentData.metadataKeywordTextMap["title"]))
        return  <>
                    <Ref innerRef={this.refToKeepTheSideMenuSticky}>
                        <SideMenuComputer contextRef={this.refToKeepTheSideMenuSticky}/>
                    </Ref>
                    <Grid.Column style={{width: this.computeContentWidth() + "px", maxWidth: "1150px"}} only='tablet computer'>
                        <Segment id={"customContentSegment"} style={{"overflowX" : (this.props.setOverflowProperty ? "scroll" : undefined )}}>
                            {this.props.singleDocLoaded
                                ?
                                <Grid style={{padding: "50px", paddingBottom: "30px"}} stretched>
                                    <Grid.Column computer={15} tablet={14}>
                                        {this.props.singleDocumentData.metadataKeywordTextMap["title"] ? <Header as='h2'>{LangSplitter.getKeyFromLanguage(this.props.guiLanguage,String(this.props.singleDocumentData.metadataKeywordTextMap["title"]))}</Header> : null}
                                    </Grid.Column>
                                    <Grid.Column tablet={1} computer={1}>
                                        {this.props.singleDocumentData.metadataKeywordTextMap?.originalUrl
                                            ?
                                            <OriginalLink originalURL={this.props.singleDocumentData.metadataKeywordTextMap.originalUrl}/>
                                            : null }
                                    </Grid.Column>
                                </Grid>
                                :
                            null }
                            {!this.props.singleDocLoaded
                                ?
                                <Grid container centered stretched columns={1} style={{height: '75vh'}}>
                                    <Grid.Column verticalAlign={"middle"}>
                                        <Loader inline='centered' active size="large">
                                            {this.props.translationsConfig.loading[this.props.guiLanguage]}
                                        </Loader>
                                    </Grid.Column>
                                </Grid>
                                : null }
                            {this.hasTitleLabels()?
                                <div style={{paddingLeft: "50px"}}>
                                    {this.props.configJson.filterMenu.filter(element => element.showInCacheViewHeader)
                                    .filter(element => this.filterAndEnrichConfigAccordingToValuesFromDocument(element))
                                    .sort((element1, element2) => element1.headerIndex-element2.headerIndex)
                                    .map(element => <TitleLabel item={element}
                                                                keyWordTextValue={this.props.singleDocumentData.metadataKeywordTextMap[element.field.replace(/List$/, '')]}
                                                                dateValue={this.props.metadataDateMap[element.field.replace(/List$/, '') ]}/>)}
                                </div>
                            : null}
                            {this.props.singleDocLoaded ? <Divider style={{marginTop: "18px"}}/> : null}
                            {this.props.parsedJSXMap && this.props.singleDocLoaded ? <div ref={this.refWhichContainsAllMarkedHTMLelements} style={{margin: "50px"}}>{this.props.parsedJSXMap[LangSplitter.guiLanguageToNr(this.props.guiLanguage)]}</div> : null}
                            {this.props.parsedJSX && this.props.singleDocLoaded ? <div ref={this.refWhichContainsAllMarkedHTMLelements} style={{margin: "50px"}}>{this.props.parsedJSX}</div> : null}
                        </Segment>
                    </Grid.Column>
                    {this.props.jumpPortalState ? <JumpPortal/> : null}
                </>
    }
}



function mapStateToProps(state) {
    const { LEv4Reducer, CacheViewReducer, ConfigReducer, URLReducer } = state
    const { guiLanguage } = URLReducer
    const { jumpPortalState,singleDocLoaded } = CacheViewReducer
    const parsedJSX = CacheViewReducer.parsedContentForCacheView?.parsedJSX;
    const parsedJSXMap = CacheViewReducer.parsedContentForCacheView?.parsedJSXMap;
    const parsedJSXHeader = CacheViewReducer.parsedContentForCacheView?.parsedJSXHeader;
    const setOverflowProperty = CacheViewReducer.parsedContentForCacheView?.setOverflowProperty;
    const {contentBoxXPosition, contentBoxWidth, viewportWidth} = LEv4Reducer
    const {translationsConfig, configJson} = ConfigReducer
    const { singleDocumentData }= CacheViewReducer
    const nestedMetadataMap = singleDocumentData.nestedMetadataMap;
    const metadataKeywordTextMap = singleDocumentData.metadataKeywordTextMap;
    const metadataDateMap = singleDocumentData.metadataDateMap;
    return {parsedJSX,parsedJSXMap, parsedJSXHeader, translationsConfig, configJson, singleDocumentData, nestedMetadataMap, metadataKeywordTextMap, metadataDateMap,
        guiLanguage, jumpPortalState, contentBoxXPosition, contentBoxWidth, setOverflowProperty, singleDocLoaded, viewportWidth,}
}

const mapDispatchToProps = {
    goBackToDashboard,
    sendContentBoxDetails
};

export default connect(mapStateToProps, mapDispatchToProps) (ContentSegmentComputer);