import React from "react";
import {connect} from "react-redux";
import {changeSorting} from "../../../../../../actions/DashboardAction";
import {Accordion, Dropdown, Grid, Header, Icon, Label} from "semantic-ui-react";
import MultiCheckboxFacetComputer from "./SideContentComputerComponents/multiCheckboxFacet/MultiCheckboxFacetComputer";
import DateRangePickerContainerComputer from "./SideContentComputerComponents/dateRangePickerContainer/DateRangePickerContainerComputer";
import {clearFilters} from "../../../../../../actions/FilterActions";

import './weblaw_sidecontent_computer.css';
import MultiCheckboxTreeFacetComputer
    from "./SideContentComputerComponents/multiCheckboxFacet/MultiCheckboxTreeFacetComputer";


const mapDispatchToProps = {
    changeSorting,
    clearFilters
};

class SideContentComputer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            defaultValue: "",
            options: [],
            sortingAccordion: true
        };
        this.createOptionsFromSorting = this.createOptionsFromSorting.bind(this);
        this.getDefaultValueForSorting = this.getDefaultValueForSorting.bind(this);
    }


    getDefaultValueForSorting = () => {
        var currentSorting = this.props.configJson.sorting.find((element, index) => this.props.URLReducer.sortDirection === element.direction && this.props.URLReducer.sortField === element.value)
        return currentSorting.value + currentSorting.direction +this.props.configJson.sorting.indexOf(currentSorting)
    }

    createOptionsFromSorting = () => {
        return this.props.configJson.sorting.map((element, index) => {
            return ({
                key: element.value + element.direction + index,
                value: element.value + element.direction + index,
                text: Object.values(element.name)[0][this.props.guiLanguage],
                additionalinfo: {field: element.value, direction: element.direction}
            })
        })
    }


    handleSortingChange = (event, data) => {
        var newSorting = data.options.find((element) => element.value === data.value).additionalinfo
        this.props.changeSorting(newSorting.field, newSorting.direction)
    }

    generateCheckBoxesOrDateRangePicker = (item, index) => {
        if (item.sourceType === "metadataDateMap" && item.requestObjectFieldType === 'range')
            if (item.showAsDashboardFacet)
                return  <DateRangePickerContainerComputer key={index} item={item} index={index}/>

        if (item.showAsDashboardFacet)
            if ((item.filterType === 'AND' || item.filterType === 'OR') && Object.entries(this.props.aggregations[item.field]).length > 0 )
                return <MultiCheckboxFacetComputer key={index} item={item}/>

        if (item.showAsDashboardTreeFacet)
                return <MultiCheckboxTreeFacetComputer key={index} item={item}/>

        return null
    }


    render() {

        return  <Accordion className={"accordionAccordionSideContent"} fluid exclusive={false}>
                    <Grid.Row >
                        <div className={"accordionHeader"}>{this.props.translationsConfig.filterContributions ? this.props.translationsConfig.filterContributions[this.props.guiLanguage] : "no text"}
                        {this.props.filters && this.props.filters.length > 0 ?
                            <Label className={"removeFilterLabel"}  onClick={() => {this.props.clearFilters(this.props.filters)}}>
                                <span className={"infiniteScrollerItemSubHeader"} >
                                    {this.props.translationsConfig.clearAllButton[this.props.guiLanguage]}
                                </span>
                                <Icon size={"small"} name="minus" />
                            </Label> : null}
                        </div>
                    </Grid.Row>
                    {this.props.configJson.generalOptions.showSorting  && this.props.configJson.sorting ?
                    <Grid.Row className={"generalGridRow " + (this.state.sortingAccordion ? "paddingBottomGrindRowActivated" : "paddingBottomGrindRowDeactivated")}>
                    <Accordion.Title className={"accordionTitle"} style={{verticalAlign: "middle"}} active={this.state.sortingAccordion}
                                     onClick={() => this.setState({sortingAccordion: !this.state.sortingAccordion})}>
                        <Label className={"accordionLabelSideContent"}>
                            <Header className={"accordionHeaderSideContent"} as='h4'>
                                {this.props.translationsConfig.sortingLabel[this.props.guiLanguage]}
                            </Header>
                            <Icon size={"large"} className={"accordionIconSideContent"} name={this.state.sortingAccordion ?"angle down" :'angle up'}/>
                        </Label>
                    </Accordion.Title>
                        <Accordion.Content active={this.state.sortingAccordion}>
                            <div style={{marginLeft: "42px", marginRight: "42px", marginTop : "19px", cursor: "pointer"}}>
                                <Dropdown className={"dropdownCustomStyle"} style={{width: "100%", cursor: "pointer"}} placeholder={this.props.translationsConfig.sortingLabel[this.props.guiLanguage]}
                                          selection
                                          options={this.createOptionsFromSorting()}
                                          defaultValue={this.getDefaultValueForSorting()}
                                          onChange={this.handleSortingChange}/>
                            </div>
                        </Accordion.Content>
                    </Grid.Row>
                        : null}
                    {this.props.configJson.filterMenu && this.props.configJson.filterMenu.map((item, index) => this.generateCheckBoxesOrDateRangePicker(item,index))}
                </Accordion>
    }

};
function mapStateToProps(state) {
    const { LEv4Reducer, URLReducer, ConfigReducer, DashboardReducer } = state
    const {filters,guiLanguage} = URLReducer
    const {configJson, translationsConfig } = ConfigReducer
    const {openSidebarToggle} = LEv4Reducer
    const {fetchedDocumentsFromDashboard } = DashboardReducer
    const aggregations = fetchedDocumentsFromDashboard.aggregations
    return {configJson, translationsConfig, openSidebarToggle, filters, guiLanguage, URLReducer, aggregations}
}

export default connect(mapStateToProps, mapDispatchToProps)(SideContentComputer);