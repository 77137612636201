import {Accordion, Divider, Grid} from "semantic-ui-react";
import React from "react";
import {connect} from "react-redux";
import {Redirect} from "react-router";
import {
    closeJumpPortal,
    goBackToDashboard,
    openJumpPortal, saveSortInformationToRedux,
    toggleSidebar
} from "../../../../../actions/CacheViewActions";
import SearchTermLabel from "../../../../sharedComponents/CacheViewSideMenuComponents/SearchTermLabel";
import UsedFiltersAccordion from "../../../../sharedComponents/CacheViewSideMenuComponents/UsedFiltersAccordion";
import LinkButtons from "../../../../sharedComponents/auxiliaryPopUp/LinkButtons";
import SortingButtons from "../../../../sharedComponents/CacheViewSideMenuComponents/SortingButtons";
import ReferenceButtons from "../../../../sharedComponents/CacheViewSideMenuComponents/ReferenceButtons";
import AccordionLabelContainer from "../../../../sharedComponents/CacheViewSideMenuComponents/AccordionLabelContainer";

class SideMenuMobile extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }

    hasFilter = () => {
        return this.props.filters?.length>0
    }

    filterAndEnrichConfigAccordingToValuesFromDocument = (element) => {
        element.nestedMetadataMap = element.field.replace(/List$/, '') in this.props.nestedMetadataMap
        element.nestedReference = element.field.replace(/List$/, '') in this.props.nestedMetadataMap
        element.metadataKeywordTextMap = element.field in this.props.metadataKeywordTextMap
        element.metadataDateMap = element.field.replace(/List$/, '') in this.props.metadataDateMap
        return element.nestedMetadataMap || element.nestedReference || element.metadataKeywordTextMap || element.metadataDateMap
    }


    render() {
        return <Grid.Column style={{width:"400px"}} only={"computer tablet"}>
                    <Accordion
                        onScroll={()=>this.setState({changeSubcomponents : !this.state.changeSubcomponents })}
                        style={{maxHeight: window.innerHeight}}
                        id={"sideMenuCacheViewAccordionComputer"}
                        fluid
                        exclusive={false}>
                        {this.props.resultsLoaded && this.props.nestedMetadataMap ?
                            <>
                                <div style={{textAlign: "left", marginTop: "20px"}}>
                                    <LinkButtons />
                                </div>
                                <Divider/>
                                {(Object.keys(this.props.nestedMetadataMap).length > 0) && !this.props.metadataKeywordTextMap?.isMarkable ? <ReferenceButtons/> : null}
                                <SortingButtons/>
                                <div>
                                    {this.props.singleDocumentData && <SearchTermLabel index={10000}/>}
                                    {this.props.configJson.filterMenu.filter(element => element.showInCacheView)
                                        .filter(element => this.filterAndEnrichConfigAccordingToValuesFromDocument(element))
                                        .map((item, index) => <AccordionLabelContainer key={index} item={item}/>)}
                                </div>
                                {this.hasFilter() ?<Divider />:null}
                                {this.hasFilter() ? <UsedFiltersAccordion cacheView={true}  toggle={this.toggle} index={10001}/> :null}
                                {this.props.newSearch ? <Redirect to={`/dashboard?` + this.props.URLString} /> : null }
                            </> : null}
                    </Accordion>
        </Grid.Column>

    }
}

function mapStateToProps(state) {
    const { LEv4Reducer, URLReducer, CacheViewReducer, DashboardReducer, ConfigReducer } = state
    const {fetchedDocumentsFromDashboard, resultsLoaded } = DashboardReducer
    const {URLString, filters, guiLanguage } = URLReducer
    const {newSearch} = LEv4Reducer;
    const { singleDocumentData } = CacheViewReducer
    const {  configJson, translationsConfig} = ConfigReducer
    const nestedMetadataMap = singleDocumentData.nestedMetadataMap;
    const metadataKeywordTextMap = singleDocumentData.metadataKeywordTextMap;
    const metadataDateMap = singleDocumentData.metadataDateMap;
    return {configJson, translationsConfig, singleDocumentData, guiLanguage,fetchedDocumentsFromDashboard, filters, URLString,
        newSearch, resultsLoaded, nestedMetadataMap, metadataKeywordTextMap, metadataDateMap}

}

const mapDispatchToProps = {
    toggleSidebar,
    goBackToDashboard,
    openJumpPortal,
    closeJumpPortal,
    saveSortInformationToRedux
};

export default connect(mapStateToProps, mapDispatchToProps)(SideMenuMobile);