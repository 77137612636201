import {logoutUser} from "./AuthActions";
import {prepareHTMLContentForCacheView} from "../services/PrepareCacheViewHTMLContentService";

export const SAVE_SINGLE_DOC = 'SAVE_SINGLE_DOC'
export const SAVE_SORTING = 'SAVE_SORTING'
export const BACK_TO_DASHBOARD = 'BACK_TO_DASHBOARD'
export const CHANGE_DOCUMENT_LANGUAGE ="CHANGE_DOCUMENT_LANGUAGE"
export const OPEN_JUMP_PORTAL = "OPEN_JUMP_PORTAL"
export const CLOSE_JUMP_PORTAL = "CLOSE_JUMP_PORTAL"
export const FOCUS_OCCURRENCE = "FOCUS_OCCURRENCE"
export const TOGGLE_SIDEBAR = "TOGGLE_SIDEBAR"
export const UPDATE_CONTENT_BOX_DETAILS = "UPDATE_CONTENT_BOX_DETAILS"
export const LOADING_SEARCH_RESULTS_CACHEVIEW =  "LOADING_SEARCH_RESULTS_CACHEVIEW"
export const LOADED_SEARCH_RESULTS_CACHEVIEW = "LOADED_SEARCH_RESULTS_CACHEVIEW"
export const REPLACE_REFERENCE_STATE_OBSERVABLE_LIST = "REPLACE_REFERENCE_STATE_OBSERVABLE_LIST"
export const SET_NEW_FOCUSED_LABEL = "SET_NEW_FOCUSED_LABEL"

function getSessionDurationSeconds(startTimeStamp) {
    var startTime = new Date(startTimeStamp).getTime();
    var now = new Date().getTime();
    var seconds = (now - startTime) / 1000;
    return Math.round(seconds);
}


export const fetchCustomDocPost = (id, guiLanguage, configJson, URLState, searchResultDocumentIdList) => async (dispatch) => {
    try {

        dispatch(dispatchLoadingCacheView());
        let response = await runCustomRequestForID(id, guiLanguage, configJson);

        let parsedContentForCacheView = prepareHTMLContentForCacheView(response, configJson, URLState);
        let currentIndex = searchResultDocumentIdList?.indexOf(id)

        dispatch(dispatchSingleDocument(response, id, parsedContentForCacheView, currentIndex));
        dispatch(dispatchLoadedCacheView());
        return response;
    }
    catch (error) {
        return Promise.reject(error);
    }
}

export const changeHTMLContentInCacheView = (singleDocumentData, configJson, URLState, searchResultDocumentIdList) => (dispatch) => {
    // If we want to mark the references in the "cacheview" we use this function
    // If we turn on (or turn out) the reference or search term marking in the "cacheview", sent the html content to the prepareHTMLContentForCacheView function
    // which marks the elements according to the flags set.
    // After this process a new array parsedContentForCacheView is created which replaces the old array in the redux store
    // This triggers a re-rendering in all affected components
    let parsedContentForCacheView = prepareHTMLContentForCacheView(singleDocumentData, configJson, URLState);
    let currentIndex = searchResultDocumentIdList?.indexOf(URLState.id)
    dispatch(dispatchSingleDocument(singleDocumentData, URLState.id, parsedContentForCacheView, currentIndex));
}

const runCustomRequestForID = async (id, guiLanguage, configJson) =>   {
    var auth = sessionStorage.getItem("jwtToken");
    var netlifyHost = process.env.REACT_APP_NETLIFYHOST;
    if (netlifyHost === undefined)
        netlifyHost = "";
    const controller = new AbortController();
    var { timeout = 10000 } = configJson.generalOptions;
    const timeOutID = setTimeout(() => controller.abort(),
        timeout);
    const response = await fetch( netlifyHost +"/.netlify/functions/singleDocQueryService/" + id +
        "?guiLanguage=" + guiLanguage +
        "&userID=" + sessionStorage.getItem("userID") +
        "&sessionDuration=" + getSessionDurationSeconds(sessionStorage.getItem("startTime")),
        {
                method: "GET",
                headers:  {"Authorization":"Bearer " + auth},
                signal: controller.signal
            }).then((response) => {
        if (response.status === 401) {
            logoutUser();
            window.location.reload();
        }
        return response.json();
    });
    clearTimeout(timeOutID);
    // clear console in production mode
    if (process.env.NODE_ENV === 'development') {
        return response;
    }
    else {
        if (typeof console._commandLineAPI !== 'undefined') {
            console.API = console._commandLineAPI;
        } else if (typeof console._inspectorCommandLineAPI !== 'undefined') {
            console.API = console._inspectorCommandLineAPI;
        } else if (typeof console.clear !== 'undefined') {
            console.API = console;
        }
        console.API.clear()
        return response;
    }
}


export const handleObservableStateChange = (key, value, observableList, URLState, configJson) => (dispatch) => {
    let newObservableList = [];

    Object.entries(observableList).forEach(([key,value]) => {
        sessionStorage.setItem('turnOnReference' + key, value);
        newObservableList[key] = value
    })
    sessionStorage.setItem('turnOnReference' + key, value);
    newObservableList[key] = value
    // If at least one element is marked, then we turn on the global turnOnReference variable, otherwise no references are inserted into the html
    sessionStorage.setItem('turnOnReference', Object.entries(newObservableList).filter(([key, value]) => value).length>0);

    dispatch(dispatchChangedReferenceObservableList(newObservableList, URLState, configJson));
}


export const changeAllReferenceState = (newValue, observableList, URLState, configJson) => (dispatch) => {
    let newObservableList = [];
    Object.entries(observableList).forEach(([key,value]) => {
        sessionStorage.setItem('turnOnReference' + key, newValue);
            newObservableList[key] = newValue
    })


    sessionStorage.setItem('turnOnReference', newValue)
    dispatch(dispatchChangedReferenceObservableList(newObservableList, URLState, configJson));
}

const dispatchChangedReferenceObservableList =  (newObservableList, URLState, configJson) => {
    return {
        type: REPLACE_REFERENCE_STATE_OBSERVABLE_LIST,
        payload: newObservableList,
        URLState: URLState,
        configJson: configJson
    };
}

export const toggleSidebar = (value) => (dispatch) => {
    dispatch(dispatchToggleSidebar(value));
}

export const sendContentBoxDetails = (value) => (dispatch) => {
    dispatch(dispatchContentBoxDetails(value));
}


export const focusOccurrence = (occurrence) => (dispatch) => {
    dispatch(dispatchFocusOccurrence(occurrence))
}

export const openJumpPortal = (occurrences, labelText, mainLabel) => (dispatch) => {
    dispatch(dispatchOpenJumpPortal(occurrences, labelText, mainLabel))
}
export const closeJumpPortal = () => (dispatch) => {
    dispatch(dispatchCloseJumpPortal())
}


export const goBackToDashboard = () => (dispatch) => {
    dispatch(dispatchBackToDashboard());
}

export const saveSortInformationToRedux = (newLabelSorting) => (dispatch) => {
    Object.entries(newLabelSorting).forEach(([key, value]) => sessionStorage.setItem(key, value))
    dispatch(dispatchSorting(newLabelSorting))
}

export const changeFocusedLabel = (focusedLabel) => (dispatch) => {
    dispatch(dispatchFocusedLabel(focusedLabel));
}

const dispatchFocusedLabel = (focusedLabel) => {
    return {
        type: SET_NEW_FOCUSED_LABEL,
        payload: focusedLabel
    };
}

const dispatchContentBoxDetails = (value) => {
    return {
        type: UPDATE_CONTENT_BOX_DETAILS,
        payload: value
    };
}


export const dispatchLoadingCacheView = () => {
    return {
        type: LOADING_SEARCH_RESULTS_CACHEVIEW
    };
}

export const dispatchLoadedCacheView = () => {
    return {
        type: LOADED_SEARCH_RESULTS_CACHEVIEW
    };
}

const dispatchToggleSidebar = (value) => {
    return {
        type: TOGGLE_SIDEBAR,
        payload: value
    };
}



const dispatchFocusOccurrence = (occurrence) => {
    return {
        type: FOCUS_OCCURRENCE,
        payload:occurrence
    };
}

const dispatchOpenJumpPortal = (occurrences, labelText, mainLabel) => {
    return {
        type: OPEN_JUMP_PORTAL,
        payload:occurrences,
        labelText: labelText,
        mainLabel:mainLabel
    };
}
const dispatchCloseJumpPortal = () => {
    return {
        type: CLOSE_JUMP_PORTAL
    };
}

const dispatchBackToDashboard = () => {
    return {
        type: BACK_TO_DASHBOARD
    };
}

const dispatchSorting =(newLabelSorting) => {
    return {
        type: SAVE_SORTING,
        payload: newLabelSorting
    };
}


const dispatchSingleDocument = (singleDocumentData, leid, parsedContentForCacheView, currentIndex) => {
    return {
        type: SAVE_SINGLE_DOC,
        singleDocumentData: singleDocumentData,
        leid: leid,
        parsedContentForCacheView: parsedContentForCacheView,
        currentIndex: currentIndex
    };
};
