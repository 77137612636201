import React from "react";


import {connect} from "react-redux";
import {
    Accordion,
    Grid, Header,
    Icon, Label, Menu
} from "semantic-ui-react";
import {openJumpPortal} from "../../../actions/CacheViewActions";
import UsedFilter from "../UsedFilter";
import {clearFilters} from "../../../actions/FilterActions";



class UsedFiltersAccordion extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            active:true,
        }
    }

    render() {
        return  <Grid.Row className={"generalGridRowCacheView " + (this.state.active ? "paddingBottomGrindRowActivated" : "paddingBottomGrindRowDeactivated")}>
                    <Accordion.Title className={"accordionTitleCacheView"} active={this.state.active} onClick={() => this.setState({active : !this.state.active})}>
                        <Label className={"accordionLabelSideContentCacheView" }>
                            <Header className={"accordionHeaderSideContentCacheView"} as='h4'>
                                {"Filter".toUpperCase()}
                            </Header>
                            <Icon onClick={() => this.setState({active : !this.state.active})} size={"large"} className={"accordionIconSideContentCacheView"} name={this.state.active ?"angle down" :'angle up'}/>
                        </Label>
                    </Accordion.Title>
                    <Accordion.Content active={this.state.active}>
                        <Grid>
                            <Grid.Column width={1}/>
                            <Grid.Column width={13}>
                                <Menu.Item fitted>
                                    <UsedFilter fromCacheview={true} accordion={true}/>
                                </Menu.Item>
                            </Grid.Column>
                        </Grid>
                    </Accordion.Content>
                </Grid.Row>

    }

}

function mapStateToProps(state) {
    const { ConfigReducer } = state
    const { configJson,  translationsConfig} = ConfigReducer
    return {configJson,  translationsConfig}
}

const mapDispatchToProps = {
    openJumpPortal,
    clearFilters
};

export default connect(mapStateToProps, mapDispatchToProps) (UsedFiltersAccordion);