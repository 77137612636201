import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux'
import './index.css';
import App from './App';
import configStore from "./redux/store";


ReactDOM.render(
    <Provider store={configStore()}>
        <App />
    </Provider>,
    document.getElementById('root')
);

