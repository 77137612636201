import React from "react";
import {Grid, Sidebar} from "semantic-ui-react";
import {connect} from "react-redux";
import OutsideClickHandler from "react-outside-click-handler/esm/OutsideClickHandler";
import {toggleSidebarDashboard} from "../../../../actions/DashboardAction";
import SideContentMobile from "./ContentGridMobileComponents/sideContentMobile/SideContentMobile";
import InfiniteScrollerMobile from "./ContentGridMobileComponents/infiniteScrollerMobile/InfiniteScrollerMobile";

class ContentGridMobile extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        };

    }

    handleOutsideClick = () => {
        if(this.props.activateOutsideClickHandlerSideBarMobile)
            this.props.toggleSidebarDashboard(false);
    }

    render() {
        return  <Sidebar.Pushable >
                    <Sidebar style={{width:"95%",}} animation="overlay" visible={this.props.showSidebarDashboard} direction="left">
                        <OutsideClickHandler disabled={!this.props.showSidebarDashboard} onOutsideClick={this.handleOutsideClick}>
                            <SideContentMobile/>
                        </OutsideClickHandler>
                    </Sidebar>
                    <Sidebar.Pusher >
                    {!this.props.showSidebarDashboard ?
                        <Grid.Column mobile={16} only="mobile">
                            <InfiniteScrollerMobile/>
                        </Grid.Column> : null}
                    </Sidebar.Pusher>
                </Sidebar.Pushable>
    }
}
function mapStateToProps(state) {
    const { LEv4Reducer, DashboardReducer } = state
    const {showSidebarDashboard, activateOutsideClickHandlerSideBarMobile } = DashboardReducer
    const {searchResults} = LEv4Reducer
    return {searchResults, showSidebarDashboard, activateOutsideClickHandlerSideBarMobile}
}

const mapDispatchToProps = {
    toggleSidebarDashboard
};

export default connect(mapStateToProps, mapDispatchToProps)( ContentGridMobile);