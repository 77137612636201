import React from "react";
import {connect} from "react-redux";
import {Button, Dimmer, Message, TransitionablePortal} from "semantic-ui-react";
import {Link} from "react-router-dom";

import {goBackToDashboard} from "../../actions/CacheViewActions";


class TimeoutErrorPortal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        return  <>
                    <Dimmer active inverted/>
                        <TransitionablePortal closeOnDocumentClick={false} open={true} transition={{animation: 'fly down', duration: 1500}}>
                            <Message negative size="large" style={{width: '550px', left: '40%', position: 'fixed', top: '5%', zIndex: 1000}}>
                                <Message.Header>
                                    {this.props.translationsConfig?.timeoutMessage[this.props.guiLanguage]}
                                </Message.Header>
                                <p>
                                    <div>
                                        <Link to={`/dashboard?` + (this.props?.URLString?.replace("?","") ? this.props.URLString?.replace("?","") : "")}>
                                            <Button content={this.props.translationsConfig.backButton[this.props.guiLanguage]} color="red" onClick={() => this.props.goBackToDashboard()}/>
                                        </Link>
                                    <Button onClick={()=> window.location.href="/"} color="red">
                                        {this.props.translationsConfig?.timeoutResetWebsiteButton[this.props.guiLanguage]}
                                    </Button>
                                    <Button onClick={()=> window.location.reload()} color="red">
                                        {this.props.translationsConfig?.timeoutResetButton[this.props.guiLanguage]}
                                    </Button>
                                </div>
                            </p>
                        </Message>
                    </TransitionablePortal>
                </>
    }
}

function mapStateToProps(state) {
    const { URLReducer, ConfigReducer } = state
    const { guiLanguage, URLString } = URLReducer
    const {translationsConfig} = ConfigReducer
    return {translationsConfig, guiLanguage, URLString}
}

const mapDispatchToProps = {
    goBackToDashboard
};

export default connect(mapStateToProps, mapDispatchToProps) (TimeoutErrorPortal);