 import React, {Component} from 'react';
import {connect} from 'react-redux';
import {
  goBackToDashboard
} from '../../actions/CacheViewActions';

import {Grid} from "semantic-ui-react";
import CacheViewComputer from "./CacheViewComputer/CacheViewComputer";
import CacheViewMobile from "./CacheViewMobile/CacheViewMobile";
import TimeoutErrorPortal from "../sharedComponents/TimeoutErrorPortal";

import FilterMessagePortal from "../sharedComponents/FilterMessagePortal";
import {processSearchRequest} from "../../actions/DashboardAction";

class CacheView extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      timeoutCustomRequestForID: false,
    }
  }




  render() {
    return <Grid stackable={false} style={{margin:"0px"}}>
              {!this.props.mobile ?<CacheViewComputer/> : null}
              {this.props.mobile ? <CacheViewMobile/> : null}
              {this.state.timeoutCustomRequestForID ? <TimeoutErrorPortal/> : null}
              {this.props.showDuplicateFilterPortal ? <FilterMessagePortal/> : null}
          </Grid>;
  }

}
const mapDispatchToProps = {
  processSearchRequest,
  goBackToDashboard
};


function mapStateToProps(state) {
  const { LEv4Reducer } = state
  const {showDuplicateFilterPortal, mobile} = LEv4Reducer
  return {showDuplicateFilterPortal, mobile}
}



export default connect(mapStateToProps, mapDispatchToProps)(CacheView);