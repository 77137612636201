import {APPEND_DOCUMENTS_IN_STORE, CHANGE_GUI_LANGUAGE} from "../actions/DashboardAction";
import {
    BACK_TO_DASHBOARD,
    CLOSE_JUMP_PORTAL,
    FOCUS_OCCURRENCE,
    LOADED_SEARCH_RESULTS_CACHEVIEW,
    LOADING_SEARCH_RESULTS_CACHEVIEW,
    OPEN_JUMP_PORTAL,
    REPLACE_REFERENCE_STATE_OBSERVABLE_LIST,
    SAVE_SINGLE_DOC,
    TOGGLE_SIDEBAR, UPDATE_CONTENT_BOX_DETAILS
} from "../actions/CacheViewActions";
import {SET_INITIAL_STATE_FROM_URL} from "../actions/URLActions";
import {CHANGE_FILTER} from "../actions/FilterActions";
import {prepareHTMLContentForCacheView} from "../services/PrepareCacheViewHTMLContentService";
import {SUCCESS_CONFIG} from "../actions/ConfigActions";

const initialState = {
    singleDocumentData: {},
    contentBoxXPosition:0,
    contentBoxWidth: 0,
    observableList: []
};

function CacheViewReducer(state = initialState, action) {
    switch (action.type) {
        case SET_INITIAL_STATE_FROM_URL:
            // When the web application is started the url state is derived from the url.
            // The document language, which is only used in the cache view, is set to the gui language.
            // The document language, which is only used in the cache view, is set to the gui language.
            state.leid = action.payload.URLState.id
            return {
                ...state
            }
        case APPEND_DOCUMENTS_IN_STORE:
            // This action is only relevant if the user directly loads a "cache view" url.
            // In app.js a search request is send to the backend.
            // The response is saved in the dashboard reducer, but we also want to know if the current leid is included in the response.
            // According to the value in currentIndex the navigation buttons are displayed in the "cacheview"
            state.currentIndex = action.payload.searchResultDocumentIdList?.indexOf(state.leid)
            return {
                ...state
            }
        case SAVE_SINGLE_DOC:
            // If we fetch a single document from the backend, the response is saved with this action.
            // This happens, when for example the forward or backward buttons are clicked in the "cacheview".
            state.jumpPortalState =false;
            state.leid = action.leid
            state.currentIndex = action.currentIndex >= 0 ? action.currentIndex :state.currentIndex
            state.numberOfMarkedWords = action.parsedContentForCacheView.numberOfMarkedWords
            state.parsedContentForCacheView = action.parsedContentForCacheView
            state.singleDocumentData = action.singleDocumentData
            return {
                ...state
            }
        case OPEN_JUMP_PORTAL:
            // This action is performed, when the jump portal is opened in the "cacheview"
            // All those variables are mainly used in JumpPortal.js.
            state.jumpPortalState =true;
            state.showSidebar =false;
            state.occurrences = action.payload.sort((firstOccurrence, secondOccurrence) => firstOccurrence.localeCompare(secondOccurrence, undefined, {numeric: true, sensitivity: 'base'}))
            state.labelText = action.labelText
            state.mainLabel = action.mainLabel
            return {
                ...state
            }
        case CLOSE_JUMP_PORTAL:
            // This action closes the jump portal and resets all relevant variables.
            state.jumpPortalState =false;
            state.occurrences = {}
            state.focusedOccurrence = ""
            state.labelText= ""
            return {
                ...state
            }
        case REPLACE_REFERENCE_STATE_OBSERVABLE_LIST:
            // This action is performed, if the state of any show reference button in the "cacheview" is changed.
            // We replace the old observable list by a new one, which provokes a re-rendering.
            let parsedContentForCacheView = prepareHTMLContentForCacheView(state.singleDocumentData, action.configJson, action.URLState);
            state.observableList = action.payload
            state.numberOfMarkedWords = parsedContentForCacheView.numberOfMarkedWords
            state.parsedContentForCacheView = parsedContentForCacheView
            state.jumpPortalState =false;
            return {
                ...state
            }
        case SUCCESS_CONFIG:
            // When the application is initially started, the configs are fetched.
            // The initial observableList is created accordingly to the filterMenu in the gui config.
            action.payload.filterMenu.filter(element => element.sourceType === "refListsMap")
                .forEach(element => state.observableList[element.field.replace(/List$/, '')] = JSON.parse(sessionStorage.getItem('turnOnReference' + element.field.replace(/List$/, ''))))
            return {
                ...state
            }
        case BACK_TO_DASHBOARD:
            // If we push the back button in the "cacheview", the jump portal is closed.
            state.jumpPortalState =false;
            state.occurrences = {}
            state.focusedOccurrence = ""
            state.labelText= ""
            return {
                ...state
            }
        case CHANGE_FILTER:
            // If change the filter somewhere, the jump portal and the sidebar (mobile view) is closed.
            state.showSidebar =false
            state.jumpPortalState =false;
            state.occurrences = {}
            state.focusedOccurrence = ""
            state.labelText= ""
            return {
                ...state
            }
        case TOGGLE_SIDEBAR:
            // This opens and closes the sidebar in the mobile view.
            state.showSidebar = !state.showSidebar;
            return {
                ...state
            }
        case LOADING_SEARCH_RESULTS_CACHEVIEW:
            // This boolean controls the loading screen in the "cacheview"
            state.singleDocLoaded = false
            return {
                ...state
            }
        case LOADED_SEARCH_RESULTS_CACHEVIEW:
            // This boolean controls the loading screen in the "cacheview"
            state.singleDocLoaded = true
            window.scrollTo(0, 0);
            return {
                ...state
            }
        case FOCUS_OCCURRENCE:
            // This variable is used with the jump portal and the "markedHTMLContent" / "MarkedSearchTerm" components.
            // When you navigate through the references/searchTerms with the jump portal, the value of focusedOccurrence is changed and the style is adjusted.
            state.focusedOccurrence = action.payload
            return {
                ...state
            }
        case UPDATE_CONTENT_BOX_DETAILS:
            // Those variables are used to compute scaling and translations of the animations performed by the references in the "ContentSegment" of the cacheview.
            // The goal is, that the references stay within the "ContentSegment" and don't overflow.
            state.contentBoxXPosition = action.payload.xPosition;
            state.contentBoxWidth = action.payload.width
            return {
                ...state
            }
        default:
            return state
    }
}
export default CacheViewReducer;