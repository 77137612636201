import {Accordion, Grid, Header, Icon, Label} from "semantic-ui-react";
import React from "react";


import {connect} from "react-redux";
import LabelArray from "../Label/LabelArray";
import "./weblaw_sidemenuaccordionelement_computer.css";

import {
    handleObservableStateChange
} from "../../../actions/CacheViewActions";
import SingleLabel from "../Label/LabelArrayComponents/SingleLabel";


class AccordionLabelContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            active: this.getInitialAccordionState()
        }
        this.setSessionStorageForCurrentAccordion = this.setSessionStorageForCurrentAccordion.bind(this)
    }



    getInitialAccordionState = () => {
        if(sessionStorage.getItem("activeAccordionCacheView" + this.props.item.field.replace(/List$/, '')))
            return JSON.parse(sessionStorage.getItem("activeAccordionCacheView" + this.props.item.field.replace(/List$/, '')))

        return this.props.item.accordionInitialOpenCacheView
    }

    setSessionStorageForCurrentAccordion = () => {
        sessionStorage.setItem("activeAccordionCacheView" + this.props.item.field.replace(/List$/, ''), !this.state.active)
        this.setState({active: !this.state.active})
    }


    handleElementMarking = () => {
        // If a list is in the "isMarkable" property, then it is not markable, otherwise the "isMarkable" entry is undefined, and then it is markable
        if(this.props.metadataKeywordTextMap?.isMarkable)
            return ""

        return (this.props.observableList[this.props.item.field.replace(/List$/, '')] ? this.props.item.color +"Border" : "")
    }

    render() {
        var fieldName = this.props.item.field.replace(/List$/, '');
        var elementsArray;
        var nestedHasOccurrences = true;
        if (this.props.singleDocumentData.nestedMetadataMap[fieldName]) {
            elementsArray = this.props.singleDocumentData.nestedMetadataMap[fieldName]
            if(this.props.singleDocumentData.nestedMetadataMap[fieldName][0].occurrences.length === 0){
                nestedHasOccurrences = false;
            }
        }
        else if(Array.isArray(this.props.singleDocumentData.metadataKeywordTextMap[fieldName])) {
            elementsArray = this.props.singleDocumentData.metadataKeywordTextMap[fieldName]
        }
        else if(this.props.item.nestedMetadataMap) {
            elementsArray = this.props.singleDocumentData.nestedMetadataMap[fieldName]
        }

        // If checkbox tree facet, only longest path needs to be displayed
        if(this.props.item.showAsDashboardTreeFacet){
            elementsArray = [elementsArray.reduce((a, b) => {return a.length > b.length ? a : b})]
        }

        return  <Grid.Row className={"generalGridRowCacheView " + (this.state.active ? "paddingBottomGrindRowActivated" : "paddingBottomGrindRowDeactivated")}>
                    <Accordion.Title style={{cursor: "default"}} className={"accordionTitleCacheView"} active={this.state.active}>
                        <Label className={"accordionLabelSideContentCacheView " + this.handleElementMarking() }>
                            <Header onClick={()=>this.setSessionStorageForCurrentAccordion()} className={"accordionHeaderSideContentCacheView"} as='h4'>
                                <Grid>
                                    <Grid.Column width={3}>
                                        <Icon style={{fontSize:"0.8em", marginLeft: "20px", marginRight: "21px"}} className={this.props.item.color +"Dot"} size={"mini"} name={"circle"}/>
                                    </Grid.Column>
                                    <Grid.Column width={13}>
                                        {this.props.item.label[this.props.guiLanguage].toUpperCase()}
                                    </Grid.Column>
                                </Grid >
                            </Header>
                            {!this.props.item.nestedReference || this.props.metadataKeywordTextMap?.isMarkable || !nestedHasOccurrences ? <Icon onClick={() => this.setState({active : !this.state.active})}
                                                                      size={"large"}
                                                                      className={"accordionIconSideContentCacheView"}
                                                                      name={this.state.active ?"angle down" :'angle up'}/> :
                                                                <Icon onClick={() => this.props.handleObservableStateChange(this.props.item.field.replace(/List$/, ''),
                                                                                                !this.props.observableList[this.props.item.field.replace(/List$/, '')],
                                                                                                this.props.observableList, this.props.URLReducer,
                                                                                                this.props.configJson)}
                                                                      size={"large"}
                                                                      className={"accordionIconSideContentCacheView"}
                                                                      name={this.props.observableList[this.props.item.field.replace(/List$/, '')] ?"eye" :'eye slash'}/>}
                        </Label>
                    </Accordion.Title>
                    <Accordion.Content active={this.state.active}>
                        <Grid>
                            <Grid.Column width={1}/>
                            <Grid.Column width={13}>
                                {elementsArray ?
                                    <div style={{display: "flex", flexWrap: "wrap", alignItems: "flex-start", gap: "5px"}} >
                                        <LabelArray elementsArray={elementsArray} item={this.props.item} horizontal={true}/>
                                    </div>
                                    :
                                    <div style={{display: "flex", flexWrap: "wrap", alignItems: "flex-start", gap: "5px"}} >
                                        <SingleLabel item={this.props.item} dateValue={this.props.singleDocumentData.metadataDateMap[fieldName]}/>
                                    </div>}
                            </Grid.Column>
                        </Grid>
                    </Accordion.Content>
                </Grid.Row>
    }



}

function mapStateToProps(state) {
    const { URLReducer, CacheViewReducer ,ConfigReducer} = state
    const {singleDocumentData, observableList } = CacheViewReducer
    const metadataKeywordTextMap = singleDocumentData.metadataKeywordTextMap;
    const {configJson} = ConfigReducer
    const {guiLanguage } = URLReducer
    return {singleDocumentData, observableList, URLReducer, configJson, metadataKeywordTextMap, guiLanguage}
}
const mapDispatchToProps = {
    handleObservableStateChange,
};


export default connect(mapStateToProps, mapDispatchToProps)(AccordionLabelContainer);