import URLManagerService from "../services/URLManagerService";
export const SET_INITIAL_STATE_FROM_URL = 'SET_INITIAL_STATE_FROM_URL'


var URLManagerServiceClass = new URLManagerService();

export const setInitialURLState = (configJson) => (dispatch) => {
    var state = URLManagerServiceClass.getStateFromURL(configJson);

    state.URLString = pushStateURLState(state);

    dispatch(createInitialState({URLState: state}));
}

export const pushStateURLState = (state, replaceURL) =>  {
    return URLManagerServiceClass.pushStateToURL(state, replaceURL);
}


const createInitialState = (state) => {
    return {
        type: SET_INITIAL_STATE_FROM_URL,
        payload: state
    };
};