import React, { Component } from 'react';
import { Route, BrowserRouter} from 'react-router-dom';
import {connect} from 'react-redux';

import CacheView from "./views/CacheView/CacheView";
import {Redirect} from "react-router";
import {authenticateUser, logInFromStorage} from "./actions/AuthActions";
import {getConfigFromQueryService, getTranslationConfigFromQueryService} from "./actions/ConfigActions";
import ErrorView from "./views/ErrorPage/ErrorView";
import {setInitialURLState} from "./actions/URLActions";
import Dashboard from "./views/Dashboard/Dashboard";
import Login from "./views/Login/Login";
import ErrorBoundary from "./views/sharedComponents/ErrorBoundary";
import './weblaw_colors.css'
import {changeLocation, processSearchRequest, saveMobileState} from "./actions/DashboardAction";
import {fetchCustomDocPost} from "./actions/CacheViewActions";
import {createBrowserHistory as createHistory} from "history";
import QueryString from "./services/URLHelper/QueryString"
import CacheBuster from './services/CacheBuster';
import CryptoJS from "crypto-js";
const crypto = require('crypto');

//The Function Below To Decrypt Text
const decryptWithAES = (ciphertext) => {
    if(ciphertext){
        const passphrase = localStorage.getItem("sec");
        if (passphrase){
            const bytes = CryptoJS.AES.decrypt(ciphertext, passphrase);
            let originalText;
            try {
                originalText = bytes.toString(CryptoJS.enc.Utf8);
            } catch (error) {
                console.error("WrongPW");
            }
            return originalText;
        }

    }
    return ciphertext;
};

function decryptJwtToken(token) {
    var jwt = decodeURIComponent(token)
    return decryptWithAES(jwt)
}

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
        fetchingInitialRequest: false,
        loaded: false,
        url: window.location.href.replace(/current=n_\d+_n&/,""),
        redirectToError: false,
        isDevelopmentMode: process.env.NODE_ENV === 'development'
    };
  }


    handleWindowResize() {
        const viewportWidth = window.innerWidth;
        if(768 >= viewportWidth)
            this.props.saveMobileState(true, viewportWidth)
        if(768 < viewportWidth)
            this.props.saveMobileState(false, viewportWidth)
  }

  componentDidMount() {

      //we add an event handler to know if the application is viewed in mobile or in computer view, further the size of the viewport is updated
      this.handleWindowResize();
      window.addEventListener("resize", this.handleWindowResize.bind(this));

      //a global user ID is generated, which is then send with the request
      if (!sessionStorage.getItem("userID")) {
          sessionStorage.setItem("userID", '_' + Math.random().toString(36).substring(2, 9));
          sessionStorage.setItem("startTime", new Date().toString())
      }

      //the isLoginIn variable is used in the login component to safe the initial url and to redirect after login to the initial url,
      // at this point the isLoginIn variable is cleared to prevent uncontrolled behaviour
      sessionStorage.removeItem("isLoginIn");
      if (! localStorage.getItem('language')) {
           localStorage.setItem('language', 'de');
      }

      if (! localStorage.getItem('sec')) {
          localStorage.setItem('sec', crypto.randomBytes(256).toString('base64'));
      }

      // If we are not logged in, we are redirected to the login page.
      // However, if the disableAutomaticLogin variable is set, this means the user clicked on the logout button.
      // In this case we don't redirect automatically
      if(!sessionStorage.getItem("isLoggedIn") && !localStorage.getItem("disableAutomaticLogin")) {
          this.props.authenticateUser()
              .then(() => window.location.href = "/")
              .catch((error) => error);
      }

      this.props.getTranslationConfigFromQueryService()
           .then((translationsConfig) =>{
                    // This is for reading the jwt token before the config request to automatically log in
                   this.props.getConfigFromQueryService(translationsConfig, decryptJwtToken(QueryString.parse(createHistory().location.search).jwt))
                       .then((response) => {
                   if(response === 'error') {
                       this.props.setInitialURLState();
                       this.setState({redirectToError: true});
                       return ;
                   }

                   if(response)
                       this.props.setInitialURLState(response.configJson);
                   this.setState({loaded: true});
               })
           })

      //if there is no username but the variable isLoggedIn is set, we must get the auth token and the username from the sessionStorage (this might be the case if a user reloads the page)
      if(sessionStorage.getItem("isLoggedIn") && this.props.LEv4Reducer.username === "")
          this.props.logInFromStorage(sessionStorage.getItem("username"));

      localStorage.removeItem("disableAutomaticLogin");
  }



  componentDidUpdate(prevProps: Readonly<P>, prevState: Readonly<S>, snapshot: SS) {

      //the logo is set from the config
      if(prevProps?.ConfigReducer?.logoFromTranslationsConfig?.icoB64 !== this.props?.ConfigReducer?.logoFromTranslationsConfig?.icoB64) {
          var pageFavIconElement = document.querySelector('link[rel="shortcut icon"]');

          pageFavIconElement.setAttribute('href',
              (this.props?.ConfigReducer?.logoFromTranslationsConfig?.icoB64 && "data:image/x-icon;base64," + this.props.ConfigReducer.logoFromTranslationsConfig.icoB64) || 'favicon.ico');
      }

      // initial request are send to the backend
      // this is done after the url state is created, the request body is generated according to this url state
      // we do this in the app.js to gain a few milliseconds
      if(!this.props?.LEv4Reducer?.initialRequest && !this.state.fetchingInitialRequest) {
          this.setState({fetchingInitialRequest: true});
          this.props.processSearchRequest(this.props.URLReducer, this.props.ConfigReducer.configJson)
          // if the id in the url state is set, it is safe to assume that the user wants to see a document in the cache view, therefor we fetch the proper document
          if(this.props.URLReducer.id)
              this.props.fetchCustomDocPost(this.props.URLReducer.id, this.props.CacheViewReducer.guiLanguage, this.props.ConfigReducer.configJson, this.props.URLReducer)
      }

      // this is used to inform the redux store if we are currently in the cache view or not
      // this information is used in various components , e.g. SingleLabel.js
      let isCacheView = createHistory().location.pathname.includes("cache")
      if(isCacheView !== this.props.LEv4Reducer.isCacheView) {
          this.props.changeLocation( isCacheView)
      }

  }

  componentWillUnmount() {
      window.removeEventListener("resize", this.handleWindowResize.bind(this));
  }

    render() {
        return (
            <CacheBuster>
                {({ loading, isLatestVersion, refreshCacheAndReload }) => {
                    if (loading) return null;
                    if (!loading && !isLatestVersion) {
                        refreshCacheAndReload();
                    }
                    return  <>
                                {this.state.redirectToError ? <ErrorView/> : null}
                                {this.state.loaded ?
                                    <BrowserRouter>
                                        {!this.props.ConfigReducer.hasConfig && !sessionStorage.getItem("isLoggedIn") ? <Redirect to={"/login"}/> : null}
                                        {this.props.ConfigReducer.hasConfig ?
                                            <Route exact={true} path="/" name="Login">
                                                <Redirect to={"/dashboard"}/>
                                            </Route> : null}
                                        {!sessionStorage.getItem("isLoggedIn") ? <Route key={0} exact={true}  path="/login" name="Login" render={(props) => (<Login {...props} url={this.state.url} />)}/> : null}
                                        {this.props.ConfigReducer.hasConfig  && <Route key={1} exact={true} path={"/dashboard"} name="Dashboard" render={(props) => (
                                            this.state.isDevelopmentMode ?
                                                <Dashboard {...props} /> :
                                                <ErrorBoundary>
                                                    <Dashboard {...props} />
                                                </ErrorBoundary> ) }/>}
                                        {this.props.ConfigReducer.hasConfig  && <Route key ={2} exact={true} path="/cache" name="CacheView" render={(props) => (
                                            this.state.isDevelopmentMode ?
                                                <CacheView  {...props}/> :
                                                <ErrorBoundary>
                                                    <CacheView  {...props}/>
                                                </ErrorBoundary> ) }/>}
                                        <Route key={3} exact={true} path={"/error"} name="error">
                                            <ErrorView/>
                                        </Route>
                                    </BrowserRouter>
                                    : <></>}
                            </>

                }}
            </CacheBuster>
        );
    }
}

const mapDispatchToProps = {
    setInitialURLState,
    authenticateUser,
    logInFromStorage,
    processSearchRequest,
    getTranslationConfigFromQueryService,
    getConfigFromQueryService,
    fetchCustomDocPost,
    saveMobileState,
    changeLocation
};


const mapStateToProps = state => ({
    ...state
})

export default connect(mapStateToProps, mapDispatchToProps)(App);
