import React from "react";
import {Grid, Image, Sticky} from "semantic-ui-react";
import {clearFilters} from "../../../../actions/FilterActions";
import {connect} from "react-redux";
import SearchBoxMobile from "./HeaderMenuMobileComponents/searchBoxMobile/SearchBoxMobile";
import LinkButtons from "../../../sharedComponents/auxiliaryPopUp/LinkButtons";
import "./weblaw_headermenu_mobile.css"

class HeaderMenuMobile extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }


    openNewTabWithURL = (url) => {
        window.open(url, '_blank');
    }

    generateHeaderIcon = () => {
        if(this.props?.logoFromTranslationsConfig?.logoConfig?.headerlogo?.logoB64)
            return <Image src={"data:image/svg+xml;base64," + this.props.logoFromTranslationsConfig.logoConfig.headerlogo.logoB64} alt="logo" id={"lev4Image"}
                          style={{marginRight: this.props.logoFromTranslationsConfig.logoConfig.headerlogo.marginRight, marginTop:"10px", marginLeft: "2.5%"}}
                          onClick={() => window.location.href  ="/" + "?guiLanguage=" + this.props.guiLanguage}/>

        return null
    }

    render() {
        return  <>
                    <Grid centered stackable padded={false}>
                        <Grid.Column style={{width:(this.state.width) + "px" , paddingBottom: "0px"}} className={"mainGridColumn"}>
                            <Grid.Row id={"firstMenuItem"}>
                                {this.generateHeaderIcon()}
                                <LinkButtons/>
                            </Grid.Row>
                        </Grid.Column>
                    </Grid>
                    <Grid style={{margin: "0px"}} centered stackable padded={false}>
                        <Grid.Column className={"mainGridColumn"} width={16} >
                            <Sticky context={this.props.contextRef} onStick={()=>this.setState({isSticking: true})} onUnstick={()=>this.setState({isSticking: false})}>
                                <Grid className={ (this.state.isSticking ? "stickySearchRowMobile": "")} style={{margin: "0px"}} centered stackable padded={false}>
                                    <Grid.Column className={"mainGridColumn"}>
                                        <Grid.Row>
                                            <SearchBoxMobile/>
                                        </Grid.Row>
                                    </Grid.Column>
                                </Grid>
                            </Sticky>
                        </Grid.Column>
                    </Grid>
                </>
    }
}

const mapDispatchToProps = {
    clearFilters
};

function mapStateToProps(state) {
    const { URLReducer, ConfigReducer} = state
    const { guiLanguage, filters } = URLReducer
    const { translationsConfig, logoFromTranslationsConfig} = ConfigReducer
    return {translationsConfig, filters, guiLanguage, logoFromTranslationsConfig}
}

export default connect(mapStateToProps, mapDispatchToProps)(HeaderMenuMobile)