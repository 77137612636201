import React from "react";
import {Icon, Popup} from 'semantic-ui-react'
import {connect} from "react-redux";

class OriginalLink extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            originalLink: this.props.originalURL[0],
            linkIcon: this.props.iconString ? this.props.iconString : "file",
            fileTyp: undefined
        }
        this.copyToClipboard = this.copyToClipboard.bind(this);
    }

    componentDidMount() {
        var fileTyp = this.state.originalLink.toString().split(".").pop();
        this.setState({fileTyp: fileTyp})
        this.setFileTyp(fileTyp);
    }

    setFileTyp = (fileTyp) => {
        if(this.props.iconString)
            return ;

        switch (fileTyp) {
            case "html":
                this.setState({ linkIcon: "linkify"});
                break;
            case "htm":
                this.setState({ linkIcon: "linkify"});
                break;
            case "pdf":
                this.setState({ linkIcon: "file pdf"});
                break;
            case "doc":
            case "docx":
                this.setState({ linkIcon: "file word"});
                break;
            case "xls":
            case "xlsx":
                this.setState({ linkIcon: "file excel"});
                break;
            case "pptx":
                this.setState({ linkIcon: "file powerpoint"});
                break;
            default:
                this.setState({ linkIcon: "download"});
                break;
        }
    }

    copyToClipboard = () => {
        if(navigator.clipboard)
            navigator.clipboard.writeText(this.state.originalLink);
        else {
            var input = document.createElement('textarea');
            input.innerHTML = this.state.originalLink;
            document.body.appendChild(input);
            input.select();
            document.execCommand('copy');
            document.body.removeChild(input);
        }
    }

    generatePopUpTrigger = () => {
        return <Icon style={{float: "right", cursor: "pointer"}}
                     id={"idForTutorial"}
                     size={"big"}
                     color={"blue"}
                     name={this.state.linkIcon}
                     onClick={this.copyToClipboard}/>
    }


    handleFileLinks = () => {
        //If the flag enableDirectFileLinks is set, we open all file links in a new tab
        if(this.props.configJson.generalOptions.enableDirectFileLinks && JSON.parse(this.props.configJson.generalOptions.enableDirectFileLinks))
            return this.generateIconForOpeningLinkInNewTab()

        //  Otherwise, we copy the file link into the clipboard
        return <Popup content={this.props.translationsConfig.copyLinkToClipBoard[this.props.guiLanguage]} on='click' pinned trigger={this.generatePopUpTrigger()}/>
    }


    generateIconForOpeningLinkInNewTab = () => {
        return  <a id={"idForTutorial"} style={{"float": "right", cursor: "pointer"}} href={this.state.originalLink} target="_blank" rel="noopener noreferrer">
                    <Icon size={"big"} name={this.state.linkIcon}/>
                </a>
    }



    render() {


        if(this.state.originalLink.startsWith("file:\\\\"))
            return this.handleFileLinks();

        return this.generateIconForOpeningLinkInNewTab()
    }
}

function mapStateToProps(state) {
    const { URLReducer, ConfigReducer } = state
    const { guiLanguage } = URLReducer
    const {configJson, translationsConfig } = ConfigReducer
    return {translationsConfig, guiLanguage, configJson}
}

export default connect(mapStateToProps)  (OriginalLink);