import {SET_NEW_FOCUSED_LABEL} from "./CacheViewActions";

export const CHANGED_OPENED_LABEL = "CHANGED_OPENED_LABEL"


export const changeOpenedLabel = (newOpenedLabel) => (dispatch) => {
    dispatch(dispatchFocusedLabel(newOpenedLabel));
}

const dispatchFocusedLabel = (focusedLabel) => {
    return {
        type: SET_NEW_FOCUSED_LABEL,
        payload: focusedLabel
    };
}