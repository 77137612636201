

export function getTranslationAndScaleValues(visibleElementRef, hiddenElementRef, xPosition, viewportSize) {
    let leftOverflow;
    let rightOverflow;
    let scalingFactor = 1.2;
    let translationWidth = 0;
    let newScalingFactor = 0;
    let marginRightSide = 50;
    let marginLeftSide = 20
    // xPosition is the position of the upper left edge in the browser coordinate system (the origin of this coordinate system is in the upper left corner of the browser window).
    // We need a coordinate system in which we can perform scaling and translation for our block element easily.
    // To obtain such a coordinate system we need to move the middle of the block element to the origin of our coordinate system.
    // xPosition + refToAllwaysVisibleElementInLabel.current?.offsetWidth/2  now we can easily perform the scaling operation:  -refToAllwaysVisibleElementInLabel.current?.offsetWidth*scalingFactor/2
    // We now have the new xPosition
    let xPositionAfterScalingWithoutTranslation = getXPositionAfterTranslationDependantOnScalingFactorAndElementSize(visibleElementRef, xPosition, scalingFactor)

    // Now we can check if we can safely perform a translation without overflow (we want a distance of 20px on the left-hand side) we chose an appropriate translation
    let translationInXPosition = xPositionAfterScalingWithoutTranslation-30>marginLeftSide ? -30 : -(xPositionAfterScalingWithoutTranslation-marginLeftSide)

    // Now we can compute the upper left edge point of our block with the chosen translation
    let xPositionAfterScalingAfterTranslation = translationInXPosition + xPositionAfterScalingWithoutTranslation

    // Now we can compute the upper right edge point of our block.
    let translatedRightXPosition = xPositionAfterScalingAfterTranslation   + ((visibleElementRef.current?.offsetWidth + hiddenElementRef.current?.offsetWidth)*scalingFactor)

    // We check if the something overflows and act accordingly
    leftOverflow = xPositionAfterScalingWithoutTranslation-30<marginLeftSide
    rightOverflow = viewportSize-marginRightSide < translatedRightXPosition

    if((leftOverflow && rightOverflow) || isTheElementToBigForTheViewPort(visibleElementRef, hiddenElementRef, marginLeftSide, marginRightSide, viewportSize, scalingFactor)) {
        let maxLength = viewportSize-marginRightSide-marginLeftSide
        let elementLength = (visibleElementRef.current?.offsetWidth + hiddenElementRef.current?.offsetWidth)
        newScalingFactor = maxLength/elementLength
        let xPositionWithNewScalingFactorToComputeNewTranslationValue = getXPositionAfterTranslationDependantOnScalingFactorAndElementSize(visibleElementRef, xPosition, newScalingFactor)
        translationWidth = -xPositionWithNewScalingFactorToComputeNewTranslationValue+marginLeftSide
        return {translationWidth, newScalingFactor}
    } else {
        if(leftOverflow) {
            translationWidth = -xPositionAfterScalingWithoutTranslation+marginLeftSide
            return {translationWidth, newScalingFactor}
        }
        if(rightOverflow) {
            translationWidth = -(translatedRightXPosition - viewportSize+marginRightSide)
            return {translationWidth, newScalingFactor}
        }
        if(!rightOverflow && !leftOverflow) {
            translationWidth = -30
            return {translationWidth, newScalingFactor}
        }
    }
}

function isTheElementToBigForTheViewPort(visibleElementRef, hiddenElementRef, marginLeftSide, marginRightSide, viewportSize, scalingFactor) {
    let isToBigBeforeScaling = (visibleElementRef.current?.offsetWidth + hiddenElementRef.current?.offsetWidth) >= viewportSize-marginRightSide-marginLeftSide
    let isToBigAfterScaling =  (visibleElementRef.current?.offsetWidth + hiddenElementRef.current?.offsetWidth)*scalingFactor >= viewportSize-marginRightSide-marginLeftSide
    return isToBigBeforeScaling || isToBigAfterScaling
}

function getXPositionAfterTranslationDependantOnScalingFactorAndElementSize(visibleElementRef, xPosition, scalingFactor) {
    return -visibleElementRef.current?.offsetWidth*scalingFactor/2 +xPosition + visibleElementRef.current?.offsetWidth/2
}