import React from "react";


class MarkedSearchTermDashboardComputer extends React.Component {
    render() {
        return  <span style={{textDecoration: "underline 2px #537DBD"}}>
                    {this.props.text}
                </span>
    }
}


export default MarkedSearchTermDashboardComputer;