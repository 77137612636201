import React from "react";
import {connect} from "react-redux";
import ShowMoreOrLessButton from "./LabelArrayComponents/ShowMoreOrLessButton";
import SingleLabel from "./LabelArrayComponents/SingleLabel";

class LabelArray extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showMore :false
        }
    }

    showMore = () => {
        this.setState({showMore: !this.state.showMore})
    }

    sortByOccurrenceCallback = (firstReference,secondReference) => {
        let order = this.props.labelSorting['sortDirection' ] ? this.props.labelSorting['sortDirection' ] : -1
        let language = this.props.guiLanguage
        var diff = (order)*(firstReference.occurrences.length-secondReference.occurrences.length);
        if(diff !== 0)
            return diff
        return firstReference.termMap[language].localeCompare(secondReference.termMap[language], undefined, {numeric: true, sensitivity: 'base'})
    }

    sortByAlphabeticallyCallback = (firstReference,secondReference,order) => {
        let language = this.props.guiLanguage
        return (order)*firstReference.termMap[language].localeCompare(secondReference.termMap[language], undefined, {numeric: true, sensitivity: 'base'})
    }

    sortByAlphabeticallyCallbackNotNestedReferences = (firstReference,secondReference, order) => {
        return (order)*firstReference.localeCompare(secondReference, undefined, {numeric: true, sensitivity: 'base'})
    }


    sortElementsArrayAccordingToChosenOptions = () => {
        if((this.props.isCacheView || this.props.mobile) && this.props.labelSorting['turnOnSorting'] && this.props.labelSorting['alphabeticSorting']) {
            if(this.props.item.nestedReference) {
                this.props.elementsArray.sort((firstReference, secondReference) => this.sortByAlphabeticallyCallback(firstReference, secondReference, this.props.labelSorting['sortDirection' ]));
            } else
                this.props.elementsArray.sort((firstReference, secondReference) => this.sortByAlphabeticallyCallbackNotNestedReferences(firstReference, secondReference, this.props.labelSorting['sortDirection']));
        } else
        if (this.props.item.nestedReference)
            this.props.elementsArray.sort((firstReference, secondReference) => this.sortByOccurrenceCallback(firstReference, secondReference))
    }


    generateLabelAndShowMoreOrLessButton = () => {
        let jsxLabelsAndMoreOrLessButton = this.props.elementsArray
            .slice(0, this.state.showMore ? this.props.elementsArray.length: this.props.configJson.generalOptions.initialLabelsLength)
            .map((reference,index) =>  <SingleLabel reference={reference} item={this.props.item} key={index}/>)

        if(this.props.elementsArray.length > this.props.configJson.generalOptions.initialLabelsLength)
            jsxLabelsAndMoreOrLessButton.push(<ShowMoreOrLessButton key={this.props.elementsArray.length+1} showMoreVariable={this.state.showMore} showMore={this.showMore}/>)

        return jsxLabelsAndMoreOrLessButton
    }

    render() {

        this.sortElementsArrayAccordingToChosenOptions()

        if(this.props.isCacheView)
            return  <div style={{display: "flex", flexWrap: "wrap", alignItems: "flex-start", gap: "5px",flexDirection:((this.props.isCacheView || this.props.mobile) && this.props.labelSorting['turnOnSorting'] ? "column": "")}}>
                        {this.generateLabelAndShowMoreOrLessButton()}
                    </div>

        return this.generateLabelAndShowMoreOrLessButton()

    }
}
function mapStateToProps(state) {
    const {LEv4Reducer, URLReducer, ConfigReducer } = state
    const {guiLanguage } = URLReducer
    const {configJson } = ConfigReducer
    const {labelSorting, mobile, isCacheView} = LEv4Reducer
    return {configJson, labelSorting, guiLanguage, mobile, isCacheView}
}

export default connect(mapStateToProps)  (LabelArray);