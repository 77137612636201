import React, { Component } from "react";
import buildCustomRequest from "../../../../../../../../services/BuildCustomRequest";
import {connect} from "react-redux";
import {addFilter, removeFilter} from "../../../../../../../../actions/FilterActions";
import {logoutUser} from "../../../../../../../../actions/AuthActions";
import {
    Accordion,
    Button,
    Checkbox,
    Grid,
     Icon,
    Input, Label,
    List,
    Loader,
    Menu, Message
} from "semantic-ui-react";

import "./weblaw_multicheckboxfacet_computer.css"
import {changeAggregationsValues} from "../../../../../../../../actions/DashboardAction";
import {LangSplitter} from "../../../../../../../../services/LangSplitter";

const mapDispatchToProps = {
    logoutUser,
    addFilter,
    removeFilter,
    changeAggregationsValues
};

class MultiCheckboxFacetComputer extends Component {
    constructor(props) {
        super(props)
        this.state = {
            showLoader: false,
            showLessButton: false,
            showMore: false,
            inputTerm: "",
            hasMoreResults: true,
            active: this.props.item.accordionInitialState,
            showTimeoutMessage: false,
            currentArrayLength: this.props.item.initialFacetLength
        }

        this.getFiltered = this.getFiltered.bind(this);
        this.renderFromResponse = this.renderFromResponse.bind(this);
    }

    componentDidMount() {
        if(this.props.item.initialFacetLength)
            this.setState({showMore: Object.entries(this.props.aggregations[this.props.item.field]).length >= this.props.item.initialFacetLength});
    }



    handleRequest = async (isFromShowMoreButton) => {
        localStorage.setItem('update', 'true')
        this.setState({ showLoader: true });
        var body = buildCustomRequest(this.props.URLReducer, this.props.configJson, "Dashboard");
        body.wildcardSearchString = this.state.inputTerm;

        var displaySize = (isFromShowMoreButton ? this.state.currentArrayLength + parseInt(this.props.configJson.generalOptions.showMoreFilters)  : this.props.item.initialFacetLength);
        if(displaySize === 0)
            displaySize = 6

        body.size = displaySize + 1;

        try {
            await this.runCustomAggsRequest(body, this.props.item.field).then((response) => {
                this.props.changeAggregationsValues(response.fieldAggregations,this.props.item.field)
                this.setState({currentArrayLength: displaySize});
                this.setState({hasMoreResults: Object.entries(response.fieldAggregations).length >= displaySize+ 1});
                this.setState({showLessButton: isFromShowMoreButton })
                this.setState({showLoader: false});
            });
        } catch (error) {
            this.setState({showTimeoutMessage: true})
        }

    }

    runCustomAggsRequest = async(body, field) => {
        body.auth = sessionStorage.getItem("jwtToken");
        var netlifyHost = process.env.REACT_APP_NETLIFYHOST;
        if (netlifyHost === undefined)
            netlifyHost = "";
        const controller = new AbortController();
        var { timeout = 10000 } = this.props.configJson.generalOptions;
        const id = setTimeout(() => controller.abort(), timeout);
        const response = await fetch(netlifyHost + "/.netlify/functions/searchAggsQueryService?field=" + field, {
            method: "POST",
            body: JSON.stringify(body),
            mode: 'no-cors',
            signal: controller.signal
        });

        clearTimeout(id)
        if(response.status === 401) {
            this.props.logoutUser();
            window.location.reload();
        }
        return response.json();
    }

    getFiltered(localStorageParse) {
        const resultArray = [];
        Object.entries(localStorageParse).forEach(([key, value]) => resultArray.push({ value: key, count: localStorageParse[key], selected: false }))
        return resultArray;
    }

    generateCheckBoxLabel = (key) => {
        return  <label className={"customLabelStyle"}>
                    <div style={{marginLeft : "10.5px"}}>
                        {this.getFilterValueDisplay(key)}
                    </div>
                </label>
    }

    handleCheckBoxChange = (checked, key) => {
        if(checked)
            this.props.removeFilter(this.props.item.field, key, this.props.item.filterType, this.props.filters)
        else
            this.props.addFilter(this.props.item.field, key, this.props.item.filterType, this.props.filters, this.props.configJson.filterMenu, this.props.item.label)
    }

    renderFromResponse(key,value,index) {
        var checked = (this.props.URLReducer?.filters?.find((f) => f.field === this.props.item.field && f.filterType === this.props.item.filterType))?.values?.includes(key);

        return  <Menu.Item className={"menuItemCustomStyle"} fitted key={index}>
                    <Grid.Column width={12} floated="left">
                        <Checkbox className={"checkBoxCustomStyle"} checked={checked} label={this.generateCheckBoxLabel(LangSplitter.getKeyFromLanguage(this.props.guiLanguage, key))} onChange={() => this.handleCheckBoxChange(checked, key)}/>
                    </Grid.Column>
                    <Grid.Column className={"countCustomStyle"} width={4} floated="right" textAlign="right">
                        {value}
                    </Grid.Column>
                </Menu.Item>
    }

     getFilterValueDisplay = (filterValue) => {
        if (filterValue === undefined || filterValue === null) return "";
        if (filterValue.hasOwnProperty("name")) return filterValue.name;
        return String(filterValue);
    }

    debounced = (delay) => {
        let timer;
        return () =>{
            clearTimeout(timer);
            timer = setTimeout(() => this.handleRequest(false),delay);
        }
    }
    debouncedRequest = this.debounced(500);

    handleAccordionStateChange = () => {
        this.props.item.accordionInitialState = !this.state.active
        this.setState({active : !this.state.active})
    }

    handleInputFieldChange = (e) => {
        this.setState({inputTerm: e.target.value});
        this.debouncedRequest();
    }

    handleTimeoutButtonClick = () => {
        this.setState({showTimeoutMessage: false});
        this.handleRequest(true);
    }

    handleYearField = () => {
        if (this.props.item.orderAggregationsByKey
            && Object.keys(this.props.aggregations[this.props.item.field]).length>0) {
            let isAscending = this.props.item.orderAggregationsByKey === "asc" ? 1: -1
            return Object.entries(this.props.aggregations[this.props.item.field])
                .sort(([key, value], [key1, value1]) =>  isAscending*key.localeCompare(key1, undefined, {numeric: true, sensitivity: 'base'}))
                .slice(0, this.state.currentArrayLength)
                .map(([key, value], index) => this.renderFromResponse(key, value, index))
        }


        return  Object.entries(this.props.aggregations[this.props.item.field])
                    .slice(0, this.state.currentArrayLength)
                    .map(([key, value], index) => this.renderFromResponse(key,value,index))
    }


    render() {
        return  <Grid.Row className={"generalGridRow " + (this.state.active ? "paddingBottomGrindRowActivated" : "paddingBottomGrindRowDeactivated")}>
                    <Accordion.Title className={"accordionTitle"} index={this.props.index} active={this.state.active} onClick={() =>this.handleAccordionStateChange()}>
                        <Label className={"accordionLabelSideContent"}>
                            <span className={"accordionHeaderSideContent"} >
                                <Grid>
                                    <Grid.Column width={3}>
                                        <Icon style={{fontSize:"0.8em", marginLeft: "20px", marginRight: "21px"}} className={this.props.item.color +"Dot"} size={"mini"} name={"circle"}/>
                                    </Grid.Column>
                                    <Grid.Column width={13}>
                                        <span style={{textAlign: "center"}}>
                                            {this.props.item.label[this.props.guiLanguage]}
                                        </span>
                                    </Grid.Column>
                                </Grid>
                            </span>
                            <Icon size={"large"} className={"accordionIconSideContent"} name={this.state.active ?"angle down" :'angle up'}/>
                        </Label>
                    </Accordion.Title>
                    <Accordion.Content active={this.state.active}>
                        <div style={{marginLeft: "42px", marginRight: "42px", marginTop : "19px"}}>
                            <List>
                                {this.props.item.facetSearchable ?
                                    <List.Item>
                                        <Input style={{width: "100%", paddingBottom : "14px"}} size='small' icon iconPosition='left' defaultValue={this.state.inputTerm && this.state.inputTerm}
                                               placeholder={this.props.translationsConfig.search[this.props.guiLanguage] || "Search"}
                                               onChange={e => this.handleInputFieldChange(e)}>
                                            <Icon style={{marginLeft : "10px", height: "35px"}} size='small' name='search'/>
                                            <input id={"searchBoxButtonSideContent"}  type="text"/>
                                        </Input>
                                    </List.Item> : null}
                                {this.props.aggregations[this.props.item.field] && Object.entries(this.props.aggregations[this.props.item.field]).length > 0 ?
                                    this.handleYearField()
                                    : null}
                                {!this.state.showLoader && Object.entries(this.props.aggregations[this.props.item.field]).length === 0 ?
                                    <p>
                                        {this.props.translationsConfig.noMatchingOptions[this.props.guiLanguage]}
                                    </p>: null}
                                {this.state.showLoader ? <Loader/> : null}
                                {this.state.showTimeoutMessage ?
                                    <List.Item>
                                        <Message negative size={"large"}>
                                            <Message.Header>
                                                {this.props.translationsConfig.timeoutMessage[this.props.guiLanguage]}
                                            </Message.Header>
                                            <p>
                                                <div id={"timeoutMessageDiv"}>
                                                    <Button onClick={() => this.handleTimeoutButtonClick()} color="red">
                                                        {this.props.translationsConfig.timeoutResetButton[this.props.guiLanguage]}
                                                    </Button>
                                                </div>
                                            </p>
                                        </Message>
                                    </List.Item> : null }
                                {this.state.showMore && !this.state.showTimeoutMessage && !this.state.showLoader  && this.state.showLessButton ?
                                    <List.Item>
                                        <Button className={"lessAndMoreButton"} basic loading={this.state.showLoader} size='tiny' onClick={() => this.handleRequest(false)}>
                                            <div className={"lessAndMoreButtonWrapper"}>
                                                <Icon className={"iconButtonCustom"} size={"large"} name={"minus circle"}/>
                                                <div className={"lessAndMoreButtonText"}>
                                                    {this.props.translationsConfig.showLessMulticheckboxFilters[this.props.guiLanguage]}
                                                </div>
                                            </div>
                                        </Button>
                                    </List.Item>
                                : null}
                                {this.state.showMore && !this.state.showTimeoutMessage  && Object.entries(this.props.aggregations[this.props.item.field]).length !== 0  && this.state.hasMoreResults ?
                                    <List.Item>
                                        <Button className={"lessAndMoreButton"} basic loading={this.state.showLoader} size='tiny' onClick={() => this.handleRequest(true)}>
                                            {this.state.showLoader ? this.props.translationsConfig.loading[this.props.guiLanguage] :
                                            <div className={"lessAndMoreButtonWrapper"}>
                                                <Icon className={"iconButtonCustom"} size={"large"} name={"plus circle"}/>
                                                <div className={"lessAndMoreButtonText"}>
                                                    {this.props.translationsConfig.showMoreMulticheckboxFilters[this.props.guiLanguage]}
                                                </div>
                                            </div>}
                                        </Button>
                                    </List.Item>
                                : null}
                            </List>
                        </div>
                    </Accordion.Content>
                </Grid.Row>

    }
};

function mapStateToProps(state) {
    const {URLReducer, DashboardReducer,ConfigReducer} = state
    const {translationsConfig, configJson} = ConfigReducer;
    const {fetchedDocumentsFromDashboard } = DashboardReducer
    const aggregations = fetchedDocumentsFromDashboard.aggregations
    const {filters, guiLanguage} = URLReducer
    return {translationsConfig, configJson, URLReducer, guiLanguage, fetchedDocumentsFromDashboard, aggregations,filters}
}


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(MultiCheckboxFacetComputer);