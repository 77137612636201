import React from "react";
import {connect} from "react-redux";
import {Input, Button, Icon} from "semantic-ui-react";
import {toggleSidebarDashboard} from "../../../../../../actions/DashboardAction";
import {changeSearchTerm, debounceSearchTerm} from "../../../../../../actions/FilterActions";
import "./weblaw_searchbox_mobile.css";
import UsedFilter from "../../../../../sharedComponents/UsedFilter";

function andOr() {
    const labels = document.querySelectorAll(".ui.label[class*='OR']");
    let nextcn;
    let currentcn;
    let nextField;
    let currentField;
    setTimeout(() => {
        labels.forEach(item => {
            if(item.nextSibling) {
                nextcn = item.nextSibling.getAttribute('class');
                currentcn = item.getAttribute('class');
                nextField = nextcn.split(" ")[3].split("-")[0];
                currentField = currentcn.split(" ")[3].split("-")[0];
                if(!nextField.includes('AND')) {
                    if(currentField === nextField){
                        item.classList.add('or');
                    }else {
                        item.classList.remove('or');
                    }
                } else {
                    item.classList.remove('or');
                }
            } else {
                item.classList.remove('or');
            }
        });
    }, 100);
}


class SearchBoxMobile extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            searchTerm: this.props.searchTerm,
            toggle: false
        };
        this.slider = React.createRef();
        this.ref = React.createRef();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        // If the searchTerm changes and a new search is performed, we want to synchronise the search term from the redux store with the local search term
        // We need this behaviour for example when the application starts or when we move from cacheview to dashboard
        if(this.props.newSearch && this.state.searchTerm !== this.props.searchTerm)
            this.setState({searchTerm: this.props.searchTerm})
        andOr()
    }

    forceRerender = () => {
        this.setState({toggle: !this.state.toggle})
    }

    searchForCurrentSearchTerm = () => {
        this.props.changeSearchTerm(this.state.searchTerm);
    }

    handleEnterClick = (e) => {
        if (e.key === 'Enter') {
            this.searchForCurrentSearchTerm();
        }
    }


    getAppropriateInputFieldPaddingStyle = () => {
        var paddingBottom = 10;
        if(this.props.filters?.length>0) {
            paddingBottom = paddingBottom+40+Math.floor(this.ref?.current?.clientHeight/35)*30;
        }
        const padding = {
            paddingBottom : paddingBottom
        }
        if(isNaN(padding.paddingBottom)){
            padding.paddingBottom = 10
        }
        return padding
    }

    debounced = (delay) => {
        let timer;
        return () =>{
            clearTimeout(timer);
            timer = setTimeout(() => this.props.debounceSearchTerm(this.state.searchTerm),delay);
        }
    }
    debouncedRequest = this.debounced(200);


    generateFilterLabels = () => {
        if(this.props.filters?.length>0)
            return  <span ref={this.ref} style={{position: "absolute", marginTop: "61px" , marginLeft: "25px"}}>
                        <UsedFilter forceRerender={this.forceRerender}/>
                    </span>
        return null
    }


    render() {

        return  <Input style={{height: (this.props.filters?.length > 0 ? (61 + this.ref?.current?.clientHeight) + 10 + "px": "61px")}}
                       className={"searchBoxInputSemanticUIMobile"}
                       fluid
                       icon
                       type='text'
                       size={"big"}
                       onChange={e => {this.setState({searchTerm: e.target.value});
                           this.debouncedRequest();}}
                       value={this.state.searchTerm}
                       onKeyDown={this.handleEnterClick}
                       iconPosition='left' placeholder={this.props.translationsConfig.search[this.props.guiLanguage] + '...'}>
                            {this.generateFilterLabels()}
                            <Icon id={"dummyClass"} style={{marginLeft : "10px"}} name='search'/>
                            <input id={"searchBoxInputFieldPosition"} className={"searchBoxInput"} style={this.getAppropriateInputFieldPaddingStyle()} type="text"/>
                            <Button className={"searchBoxMobileButton"} icon={this.props.showSidebarDashboard ?"close" :'filter' }
                                disabled={this.props.showSidebarDashboard} onClick={() => this.props.toggleSidebarDashboard(true)}/>
                </Input>
    }
}
function mapStateToProps(state) {
    const {URLReducer, DashboardReducer, ConfigReducer } = state
    const {showSidebarDashboard } = DashboardReducer
    const {guiLanguage, searchTerm, filters} = URLReducer
    const {translationsConfig } = ConfigReducer
    return {searchTerm, showSidebarDashboard,filters, guiLanguage, translationsConfig}
}

const mapDispatchToProps = {
    debounceSearchTerm,
    toggleSidebarDashboard,
    changeSearchTerm
};

export default connect(mapStateToProps,mapDispatchToProps) (SearchBoxMobile);