import React from "react";
import {connect} from "react-redux";
import {Input, Button, Icon} from "semantic-ui-react";
import {changeSearchTerm, debounceSearchTerm} from "../../../../../../../actions/FilterActions";

import './weblaw_searchbox_computer.css'
import UsedFilter from "../../../../../../sharedComponents/UsedFilter";

function andOr() {
    const labels = document.querySelectorAll(".ui.label[class*='OR']");
    let nextcn;
    let currentcn;
    let nextField;
    let currentField;
    setTimeout(() => {
        labels.forEach(item => {
            if(item.nextSibling) {
                nextcn = item.nextSibling.getAttribute('class');
                currentcn = item.getAttribute('class');
                nextField = nextcn.split(" ")[3].split("-")[0];
                currentField = currentcn.split(" ")[3].split("-")[0];
                if(!nextField.includes('AND')) {
                    if(currentField === nextField){
                        item.classList.add('or');
                    }else {
                        item.classList.remove('or');
                    }
                } else {
                    item.classList.remove('or');
                }
            } else {
                item.classList.remove('or');
            }
        });
    }, 100);
}

class SearchBox extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            searchTerm: this.props.searchTerm ? this.props.searchTerm : "",
            toggle: false
        };
        this.slider = React.createRef();
        this.ref = React.createRef();
    }

    forceRerender = () => {
        this.setState({toggle: !this.state.toggle})
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        // If the searchTerm changes and a new search is performed, we want to synchronise the search term from the redux store with the local search term
        // We need this behaviour for example when the application starts or when we move from cacheview to dashboard
        if(this.props.newSearch && this.state.searchTerm !== this.props.searchTerm)
            this.setState({searchTerm: this.props.searchTerm})
        andOr()
    }


    searchForCurrentSearchTerm = () => {
        this.props.changeSearchTerm(this.state.searchTerm);
    }

    handleEnterClick = (e) => {
        if (e.key === 'Enter') {
            this.searchForCurrentSearchTerm();
        }
    }

    getAppropriateInputFieldPadding = () => {
        if(this.props.filters?.length>0) {
            if(0 < this.ref?.current?.clientHeight  && this.ref?.current?.clientHeight < 35)
                return "searchBoxInput1row"
            if(35 < this.ref?.current?.clientHeight && this.ref?.current?.clientHeight  < 65)
                return "searchBoxInput2row"
            if(65 < this.ref?.current?.clientHeight && this.ref?.current?.clientHeight  < 95)
                return "searchBoxInput3row"
        }

        return "searchBoxInputFieldPosition"
    }

    debounced = (delay) => {
        let timer;
        return () =>{
            clearTimeout(timer);
            timer = setTimeout(() => this.props.debounceSearchTerm(this.state.searchTerm),delay);
        }
    }
    debouncedRequest = this.debounced(200);

    generateFilterLabels = () => {
        if(this.props.filters?.length>0)
            return  <span ref={this.ref} style={{position: "absolute", marginTop: "61px" , marginLeft: "25px"}}>
                        <UsedFilter forceRerender={this.forceRerender}/>
                    </span>
        return null
    }


    render() {
        return <Input style={{height: (this.props.filters?.length>0 ? (61 + this.ref?.current?.clientHeight) + 10 + "px": "61px")}}
                      className={"searchBoxInputSemanticUI"}
                      fluid
                      icon
                      type='text'
                      size={"big"}
                      onChange={e => {this.setState({searchTerm: e.target.value});
                          this.debouncedRequest();}}
                      value={this.state.searchTerm}
                      onKeyDown={this.handleEnterClick}
                      iconPosition='left'
                      placeholder={this.props.search + '...'}>
                            {this.generateFilterLabels()}
                            <Icon id={"dummyClass"} style={{marginLeft : "10px"}} name='search' />
                            <input id={this.getAppropriateInputFieldPadding()} className={"searchBoxInput"} type="text"/>
                            <Button id={"searchBoxButton"} primary onClick={this.searchForCurrentSearchTerm}>
                                {this.props.translationsConfig.search[this.props.guiLanguage]}
                            </Button>
                </Input>
            }
}




function mapStateToProps(state) {
    const { LEv4Reducer, URLReducer, DashboardReducer, ConfigReducer } = state
    const {translationsConfig, configJson} = ConfigReducer;
    const {showSidebarDashboard } = DashboardReducer
    const {searchTerm, filters, guiLanguage} = URLReducer
    const {newSearch} = LEv4Reducer
    return {searchTerm, showSidebarDashboard, newSearch, filters, translationsConfig, configJson, guiLanguage}
}

const mapDispatchToProps = {
    changeSearchTerm,
    debounceSearchTerm
};

export default connect(mapStateToProps,mapDispatchToProps) (SearchBox);