import React from "react";

import {connect} from "react-redux";
import {addFilter} from "../../../actions/FilterActions";
import "./weblaw_markedhtmlcontent.css"

import {Icon} from "semantic-ui-react";
import {getTranslationAndScaleValues} from "../../../services/LabelScalingAndTranslationService";
import {changeOpenedLabel} from "../../../actions/MarkedHTMLActions";
import {getReferenceFromOccurrence} from "../../../services/ReferenceService";
import {LangSplitter} from "../../../services/LangSplitter";
import {getLinkStringForLanguage, getLinkStringMap} from "../../../services/Helpers/LinkStringHelper";


class MarkedHTMLContent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            label: "",
            color: "",
            filterType: "",
            meta: "",
            field: "",
            showDetails: false,
            translationWidth: -10,
            scalingFactor: 1.2
        }
        this.refToAllwaysVisibleElementInLabel = React.createRef();
        this.refToHiddenElementInLabel = React.createRef();
    }


    componentDidMount() {
        if(this.props.singleDocumentData?.nestedMetadataMap) {
            var resolvedReference = getReferenceFromOccurrence(this.props.configJson.filterMenu,this.props.singleDocumentData.nestedMetadataMap, this.props.occurrence)
            if(resolvedReference) {
                this.setState({
                    label: resolvedReference.filterOption.label,
                    termMap: resolvedReference.reference.termMap,
                    linkStringMap: getLinkStringMap(resolvedReference.reference),
                    link: getLinkStringForLanguage(resolvedReference.reference, this.props.guiLanguage),
                    term: resolvedReference.reference.termMap[this.props.guiLanguage],
                    color: resolvedReference.filterOption.color,
                    filterType: resolvedReference.filterOption.filterType,
                    meta: resolvedReference.reference.meta,
                    field: resolvedReference.filterOption.field
                })
            }
        }

    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        // If the focusedLabel label changes and is now not equal to occurrence, we need stop focusing the label and we need to re-render
        if(this.state.showDetails && this.props.focusedLabel !== this.props.occurrence && prevProps.focusedLabel !== this.props.focusedLabel) {
            this.setState({showDetails: false})
        }

        // we compute, if a special translation or a different scaling factor is necessary to keep the labels inside the visible viewport
        if(this.state.showDetails && (this.state.showDetails !== prevState.showDetails)) {
            const {translationWidth, newScalingFactor} = getTranslationAndScaleValues(this.refToAllwaysVisibleElementInLabel, this.refToHiddenElementInLabel, this.computeXPosition(), this.computeViewportSize())
            if(translationWidth !== 0)
                this.setState({
                    translationWidth:  translationWidth
                })
            if(newScalingFactor !== 0)
                this.setState({
                    scalingFactor:  newScalingFactor
                })
        }
    }

    computeXPosition = () => {
        if(this.props.mobile)
            return this.refToAllwaysVisibleElementInLabel.current?.getBoundingClientRect().x

        return this.refToAllwaysVisibleElementInLabel.current?.getBoundingClientRect().x - this.props.contentBoxXPosition+50
    }


    computeViewportSize = () => {
        if(this.props.mobile)
            return window?.innerWidth

        return this.props.contentBoxWidth+100
    }

    isFocused = () => {
        return this.props.focusedOccurrence === this.props.occurrence
    }


    runRequest = async () => {

        // If the REACT_APP_NETLIFYHOST env is set, we are in production otherwise in dev
        const netlifyHost = process.env.REACT_APP_NETLIFYHOST;

        // The linkingString contains the field param values which the linking service needs to perform the forwading
        const linkString = this.state.linkStringMap[this.props.guiLanguage]

        if(linkString.startsWith("http")){
            window.open(linkString,"_blank")
        }else {
            // If we are in dev we need the base url to point to our local netlify server
            const baseURL = netlifyHost ? netlifyHost : "http://localhost:9000"

            // We can set a different base url then netlify server in the gui config
            // If this value is not set, the previously generated baseURL is used
            const finalURL = this.props?.configJson?.generalOptions?.linkingBaseURL ? this.props.configJson.generalOptions.linkingBaseURL : baseURL + "/.netlify/functions/link/?field="

            let auth = sessionStorage.getItem("jwtToken");

            // We close the focused label
            this.setState({showDetails: false, showPopUp: false});
            // This is added because of a caching issue
            let random = "&r=" + Math.random().toString(36).substring(2, 9);
            // We open a new tab with the concatenated url
            window.open(finalURL + linkString + (auth ? "&auth=" + auth : "") + random, "_blank")
        }
    }

    applyFilter = () => {
        var filterCombined = LangSplitter.combineAllLanguages([this.state.termMap.de, this.state.termMap.fr, this.state.termMap.it, this.state.termMap.en]);
        this.props.addFilter(this.state.field, filterCombined, this.state.filterType, this.props.URLReducer.filters, this.props.configJson.filterMenu, this.state.termMap);
    }

    debounced = (delay) => {
        return () =>{
            clearTimeout(this.state.timer);
            this.setState({timer : setTimeout(() => this.setState({showDetails:false}),delay)});
        }
    }
    debouncedRequest = this.debounced(500);

    focusLabelAfterMouseEntry = () => {
        this.props.changeOpenedLabel(this.props.occurrence)
        this.setState({showDetails: true})
        clearTimeout(this.state.timer);
    }

    render() {
        return  <span ref={this.refToAllwaysVisibleElementInLabel}
                      onMouseEnter={()=> this.focusLabelAfterMouseEntry()}
                      onMouseLeave={() => (this.debouncedRequest())}
                      style={{whiteSpace: "nowrap", paddingLeft: "10px", paddingRight: "5px",
                          transform: (this.state.showDetails ? "translateX(" +this.state.translationWidth +"px) scale("+ this.state.scalingFactor+")" : "")}}
                      name={this.props.occurrence}
                      className={"markedHtmlContentWrapper " +
                          (this.state.showDetails ? " label-wrapper-active" : "") +
                          " markedOccurrence_" +this.state.color + (this.isFocused() ? " markedOccurrence_focused" : "")}>
                    {this.props.content + "  "}
                    <span ref={this.refToHiddenElementInLabel} className={this.state.color + " " + (this.state.showDetails ? " displayMarkedHtmlContentDetails" : "")}>
                        <i style={{fontFamily: "Poppins"}}>
                            {this.state.label[this.props.guiLanguage]}
                        </i>
                        <Icon style={{height:"unset", marginTop: "1.3px", marginLeft: "12px"}} className={"iconCursorPointerCustom"} name={"plus square"} onClick={() => this.applyFilter()}/>
                        {this.state.linkStringMap  ? <Icon style={{height: "unset", marginLeft: "5px", marginTop: "1.2px"}}
                                                              className={"iconCursorPointerCustom"}  name={"external"}
                                                              onClick={() => this.runRequest()}/> : null}
                    </span>
                </span>

    }
}
const mapDispatchToProps = {
    changeOpenedLabel,
    addFilter
};

function mapStateToProps(state) {
    const { LEv4Reducer, URLReducer, CacheViewReducer, ConfigReducer } = state
    const { guiLanguage, URLString } = URLReducer
    const { singleDocumentData, focusedOccurrence, contentBoxXPosition, contentBoxWidth} = CacheViewReducer
    const {focusedLabel, mobile} = LEv4Reducer
    const {translationsConfig, configJson} = ConfigReducer
    return {translationsConfig, URLReducer, configJson, URLString, singleDocumentData, guiLanguage, focusedOccurrence, contentBoxXPosition, contentBoxWidth, focusedLabel, mobile}
}

export default connect(mapStateToProps, mapDispatchToProps) (MarkedHTMLContent);