import React from "react";
import {Button, Icon} from "semantic-ui-react";
import {
    fetchCustomDocPost,
} from "../../../actions/CacheViewActions";
import {processSearchRequest} from "../../../actions/DashboardAction";
import {connect} from "react-redux";
import {Link} from "react-router-dom";

class NavigationsButtons extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }


    fetchNextSingleDocument = () => {
        if (this.props.fetchedDocumentsFromDashboard.hasMoreResults){
            if((this.props.fetchedDocumentsFromDashboard.documents.length / 2) - 1 < this.props.currentIndex && this.props.resultsLoaded) {
                this.props.processSearchRequest(this.props.URLReducer, this.props.configJson, this.props.requestBody, this.props.fetchedDocumentsFromDashboard.documents)
            }
        }
        this.props.fetchCustomDocPost(this.props.fetchedDocumentsFromDashboard.searchResultDocumentIdList[this.props.currentIndex + 1],
            this.props.guiLanguage,
            this.props.configJson,
            this.props.URLReducer,
            this.props.fetchedDocumentsFromDashboard.searchResultDocumentIdList)
    }

    fetchPreviousSingleDocument = () => {
        this.props.fetchCustomDocPost(this.props.fetchedDocumentsFromDashboard.searchResultDocumentIdList[this.props.currentIndex - 1],
            this.props.guiLanguage,
            this.props.configJson,
            this.props.URLReducer,
            this.props.fetchedDocumentsFromDashboard.searchResultDocumentIdList)
    }

    reachedLastDocument = () => {
        if(this.props.currentIndex+1 === this.props.fetchedDocumentsFromDashboard.totalNumberOfDocuments){
            return true;
        }else if(!this.props.fetchedDocumentsFromDashboard.hasMoreResults && (this.props.currentIndex+1 === this.props.fetchedDocumentsFromDashboard.documents.length)){
            return true;
        }
        return false;
    }

    foundDocumentInDocumentList = () => {
        return (this.props.fetchedDocumentsFromDashboard.totalNumberOfDocuments > this.props.currentIndex) && this.props.currentIndex >= 0
    }

    generatePositionString = () => {
        return (this.props.currentIndex + 1) + ' / ' + this.props.fetchedDocumentsFromDashboard.totalNumberOfDocuments
    }

    render() {
        return  <div id={"sideMenuCacheViewAccordionFirstSegmentComputer"}>
                    <Link to={`/dashboard?` + (this.props?.URLString?.replace("?", "") ? this.props.URLString?.replace("?", "") : "")}>
                        <Button id={"backButton"} size={"small"} basic onClick={this.setBackToDashboardBoolean}>
                            <Icon size={"small"} name={"chevron left"}/>
                            {this.props.translationsConfig.backButton[this.props.guiLanguage]}
                        </Button>
                    </Link>
                {this.foundDocumentInDocumentList() ?
                    <span>
                        <span id={"currentScrollPositionString"}>
                            {this.generatePositionString()}
                        </span>
                        <Button className={"navigationButtons"} size={"small"} icon={"chevron left"} disabled={this.props.currentIndex === 0}
                                style={this.props.currentIndex === 0 ? {background: 'lightgrey', color: "rgb(51, 51, 51)"} : {color: "rgb(51, 51, 51)"}}
                                onClick={() => this.fetchPreviousSingleDocument()}/>
                        <Button size={"small"} icon={"chevron right"} disabled={this.reachedLastDocument()}
                                style={this.reachedLastDocument() ? {background: 'lightgrey', color: "rgb(51, 51, 51)"} : {color: "rgb(51, 51, 51)"}}
                                className={"navigationButtons"}
                                onClick={() => this.fetchNextSingleDocument()}/>
                        </span> : null}
                </div>

    }

}

function mapStateToProps(state) {
    const { ConfigReducer, URLReducer, CacheViewReducer, DashboardReducer} = state;
    const {fetchedDocumentsFromDashboard, requestBody, resultsLoaded } = DashboardReducer
    const {URLString, guiLanguage } = URLReducer
    const { currentIndex } = CacheViewReducer
    const { configJson, translationsConfig} = ConfigReducer;
    return { URLReducer, configJson, translationsConfig,fetchedDocumentsFromDashboard, URLString, currentIndex, requestBody,resultsLoaded, guiLanguage};

}

const mapDispatchToProps = {
    fetchCustomDocPost,
    processSearchRequest,
};

export default connect(mapStateToProps, mapDispatchToProps)(NavigationsButtons);