import React from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import {connect} from "react-redux";

import {Button, Item, Message} from "semantic-ui-react";

import ResultItemMobile from "./InfiniteScrollerMobileComponents/resultItemMobile/ResultItemMobile";
import ItemGroupHeaderMobile from "./InfiniteScrollerMobileComponents/itemGroupHeaderMobile/ItemGroupHeaderMobile";
import {handleDashboardClick, processSearchRequest} from "../../../../../../actions/DashboardAction";


class InfiniteScrollerMobile extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isFetching: false,
            showEndMessage: false
        };
    }

    componentDidMount() {
        window.scrollTo(0, this.props.pageYOffset);
        this.props.handleDashboardClick(0, "")
    }

    fetchMoreData = (kickstartSearch) => {
        if(!this.state.isFetching || kickstartSearch) {
            this.setState({isFetching : true});
            this.props.processSearchRequest(this.props.URLReducer, this.props.configJson, this.props.requestBody, this.props.documents)
                .then((newState => {
                    this.setState({
                        isFetching: false,
                        showEndMessage: newState.totalNumberOfDocuments > this.props.length
                    });
                }))
        }
    };

    generateLoader = () => {
        return  <div style={{"float": "right", "width": "50%"}}>
                    <h4>
                        {this.props.translationsConfig.loading[this.props.guiLanguage]}...
                    </h4>
                    <div className="loader"/>
                </div>
    }

    generateEndMessage = () => {
        if(this.props.totalNumberOfDocuments > this.props.length) {
            if(!this.props.additionalRequestTimeout)
                return  <p style={{ textAlign: 'center' }}>
                            <b>
                                {this.props.translationsConfig.endScrollMessage ? this.props.translationsConfig.endScrollMessage[this.props.guiLanguage] : "no text"}
                            </b>
                        </p>
            return  <Message negative size={"large"}>
                        <Message.Header>
                            {this.props.translationsConfig.timeoutMessage[this.props.guiLanguage]}
                        </Message.Header>
                        <p>
                            <Button onClick={()=> this.fetchMoreData(true)} color="red">
                                {this.props.translationsConfig.timeoutResendRequestButton[this.props.guiLanguage]}
                            </Button>
                        </p>
                    </Message>
        }

        return null
    }


    render() {
        return  <InfiniteScroll scrollThreshold={0.5} style={{"overflow": "visible"}} dataLength={this.props.length} next={this.fetchMoreData} hasMore={this.props.hasMoreResults}
                                loader={this.generateLoader()}
                                endMessage={this.generateEndMessage()}>
                    <Item.Group divided>
                        <ItemGroupHeaderMobile/>
                        {this.props.documents.map((result,index) => <ResultItemMobile result={result} key={index} index={index}/>)}
                    </Item.Group>
                </InfiniteScroll>

    }
}

function mapStateToProps(state) {
    const { LEv4Reducer, URLReducer, DashboardReducer, ConfigReducer } = state
    const { newSearch, comesFromCacheView} = LEv4Reducer
    const {configJson, translationsConfig } = ConfigReducer
    const {fetchedDocumentsFromDashboard, requestBody, additionalRequestTimeout, pageYOffset } = DashboardReducer
    const documents = fetchedDocumentsFromDashboard.documents
    const length = fetchedDocumentsFromDashboard.documents.length
    const {guiLanguage} =URLReducer
    const hasMoreResults = fetchedDocumentsFromDashboard.hasMoreResults
    const totalNumberOfDocuments = fetchedDocumentsFromDashboard.totalNumberOfDocuments
    return { translationsConfig, newSearch, configJson, requestBody, additionalRequestTimeout, documents, hasMoreResults, length, URLReducer, totalNumberOfDocuments, comesFromCacheView, guiLanguage, pageYOffset}
}

const mapDispatchToProps = {
    handleDashboardClick,
    processSearchRequest
};

export default connect(mapStateToProps, mapDispatchToProps) (InfiniteScrollerMobile);