import React from "react";

import {goBackToDashboard} from "../../../actions/CacheViewActions";
import {connect} from "react-redux";
import {Grid} from "semantic-ui-react";

import ContentSegmentComputer from "./CacheViewComputerComponents/ContentSegmentComputer";
import LinkButtons from "../../sharedComponents/auxiliaryPopUp/LinkButtons";


class CacheViewComputer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }

    computeHeaderWidthToBeAlignedWithContent = () => {
        const constantValue = 500;
        const constantSizeOfSideContent = 450
        const isBiggerThanThreshold = (this.props.viewportWidth - constantSizeOfSideContent)*0.90 >1150
        const resolutionSubtractedByWidthOfSideContentScaledByConstant = (this.props.viewportWidth - constantSizeOfSideContent)*0.90

        return constantValue + (isBiggerThanThreshold ? 1150 : resolutionSubtractedByWidthOfSideContentScaledByConstant)
    }


    render() {
        return<>
            <Grid.Column style={{width: this.computeHeaderWidthToBeAlignedWithContent() + "px" }} className={"mainGridColumn"}>
                <Grid.Row only='tablet computer' >
                    <LinkButtons />
                </Grid.Row>
            </Grid.Column>
            <Grid.Row only={"computer tablet"}>
                <ContentSegmentComputer/>
            </Grid.Row>
        </>

    }

}

function mapStateToProps(state) {
    const { LEv4Reducer } = state
    const {viewportWidth} = LEv4Reducer
    return {viewportWidth}
}

const mapDispatchToProps = {
    goBackToDashboard
};

export default connect(mapStateToProps, mapDispatchToProps) (CacheViewComputer);