import React from "react";
import {Grid} from "semantic-ui-react";
import {connect} from "react-redux";
import SideContentComputer from "./ContentGridMenuComputerComponents/sideContentComputer/SideContentComputer";
import InfiniteScrollerComputer from "./ContentGridMenuComputerComponents/infiniteScrollerComputer/InfiniteScrollerComputer";


class ContentGridComputer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    render() {
        return <Grid columns={2} centered stackable style={{marginTop : "0px"}}>
                <Grid.Column style={{width:"450px"}}>
                    <SideContentComputer/>
                </Grid.Column>
                <Grid.Column style={{width: ((this.props.viewportWidth-450)*0.90) + "px", maxWidth: "1150px"}} only='tablet computer'>
                    <InfiniteScrollerComputer/>
                </Grid.Column>
            </Grid>
    }
}
function mapStateToProps(state) {
    const { LEv4Reducer, URLReducer } = state
    const { guiLanguage } = URLReducer
    const { viewportWidth} = LEv4Reducer
    return {guiLanguage, viewportWidth}
}

export default connect(mapStateToProps)( ContentGridComputer);