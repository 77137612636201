import React from "react";
import {connect} from "react-redux";
import 'semantic-ui-less/semantic.less'
import {changeGuiLanguage, processSearchRequest} from "../../actions/DashboardAction";
import DashboardComputer from "./DashboardComputer/DashboardComputer";
import DashboardMobile from "./DashboardMobile/DashboardMobile";

import TimeoutErrorPortal from "../sharedComponents/TimeoutErrorPortal";
import FilterMessagePortal from "../sharedComponents/FilterMessagePortal";


const mapDispatchToProps = {
    processSearchRequest,
    changeGuiLanguage
};


class Dashboard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }

    componentDidMount() {
        if(document.title !== this.props.logoFromTranslationsConfig?.pageTitle)
            document.title= this.props.logoFromTranslationsConfig?.pageTitle
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        // If the newSearch flag is set to true, a new search request is performed in this component
        // The resultsLoaded flag is set to false if we are fetching a new search request
        // The resultsLoaded is set to true as soon as the initial search request is performed by the app.js component
        if(this.props.newSearch && this.props.initialRequest && this.props.resultsLoaded) {
            this.props.processSearchRequest(this.props.URLReducer, this.props.configJson)
        }
    }

    render() {
        return  <>
                    {this.props.mobile ? <DashboardMobile/> : null }
                    {!this.props.mobile ? <DashboardComputer/> : null }
                    {this.props.searchRequestTimeout ? <TimeoutErrorPortal/>: null}
                    {this.props.showDuplicateFilterPortal ? <FilterMessagePortal/>: null}
                </>



    }
}

function mapStateToProps(state) {
    const { LEv4Reducer , URLReducer, DashboardReducer, ConfigReducer} = state
    const {fetchedDocumentsFromDashboard, searchRequestTimeout, resultsLoaded } = DashboardReducer
    const {configJson, translationsConfig,logoFromTranslationsConfig } = ConfigReducer
    const {newSearch, comesFromCacheView,
        showDuplicateFilterPortal, mobile} = LEv4Reducer
    const initialRequest = LEv4Reducer.initialRequest
    return {configJson, translationsConfig, URLReducer, newSearch,
        LEv4Reducer, comesFromCacheView,
        searchRequestTimeout, showDuplicateFilterPortal,
        logoFromTranslationsConfig, fetchedDocumentsFromDashboard, initialRequest,
        resultsLoaded, mobile}
}

export default connect(mapStateToProps, mapDispatchToProps) (Dashboard);