import {
    FAILURE_CONFIG,
    FAILURE_TRANSLATION_CONFIG,
    SUCCESS_CONFIG,
    SUCCESS_TRANSLATION_CONFIG
} from "../actions/ConfigActions";

const initialState = {
    configJson: {},
    hasConfig: false,
    translationsConfig: {},
    logoFromTranslationsConfig: {},
};

function ConfigReducer(state = initialState, action) {
    switch (action.type) {
        case FAILURE_CONFIG:
            return {
                ...state,
                configJson: action.payload,
                hasConfig: false,
                error: true
            }
        case SUCCESS_CONFIG:
            return {
                ...state,
                configJson: action.payload,
                hasConfig: true,
                error: false
            }
        case FAILURE_TRANSLATION_CONFIG:
        case SUCCESS_TRANSLATION_CONFIG:
            return {
                ...state,
                translationsConfig: action.payload.translations,
                logoFromTranslationsConfig: action.payload
            }
        default:
            return state
    }
}
export default ConfigReducer;