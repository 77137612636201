import React from "react";
import {connect} from "react-redux";
import {removeFilter} from "../../actions/FilterActions";
import {Label, Icon} from "semantic-ui-react";
import {format} from "date-fns";
import { LangSplitter } from "../../services/LangSplitter";

const mapDispatchToProps = {
    removeFilter
};


class UsedFilter extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        }
        this.getValueOrDate = this.getValueOrDate.bind(this);
    }

    getValueOrDate = (value) => {
        var jsx;

        if (typeof (value) === 'object') {
            if (JSON.stringify(value.from) !== JSON.stringify(value.to)) {
                format(new Date(value.to), this.props.configJson.generalOptions.dateFormatLabels)
                jsx = format(new Date(value.from), this.props.configJson.generalOptions.dateFormatLabels) + ' - ' + format(new Date(value.to), this.props.configJson.generalOptions.dateFormatLabels)
            }
            else {
                jsx = format(new Date(value.from), this.props.configJson.generalOptions.dateFormatLabels)
            }
        } else {
            jsx = LangSplitter.getKeyFromLanguage(this.props.guiLanguage, value)
        }

        // For negative filters
        if(jsx && jsx.substring(0, 2) === '!!'){
            jsx = jsx.substring(2);
        }
        return jsx
    }

    generateLabels = (value,indexNested,item, index) => {
        if(this.props.forceRerender)
            this.props.forceRerender()
        if(this.props.accordion)
            return  <Label style={{marginBottom:"5px", fontWeight:"normal"}} className={`${item.color} ${item.field +  "-"+indexNested +"-"+item.filterType}`}  key={index +  " "+ indexNested}>
                        {this.getValueOrDate(value)}
                        <Icon name='delete' onClick={() => this.props.removeFilter(item.field, value, item.filterType, this.props.filters)}/>
                    </Label>

        // Is negative Filter
        if(typeof(value) === 'string' && value.substring(0, 2) === '!!'){
            return  <Label style={{marginBottom:"5px", fontWeight:"normal", textDecoration:"line-through"}} className={`${item.color} ${item.field + '_negative' +  "-"+indexNested+'-AND'}`}   key={index +  " "+ indexNested}>
                {this.getValueOrDate(value)}
                <Icon name='delete' onClick={() => this.props.removeFilter(item.field, value, item.filterType, this.props.filters)}/>
            </Label>
        }

        const valIndex = item.values.indexOf(value);
        if(item.values[valIndex + 1] && item.values[valIndex + 1].substring(0, 2) === '!!'){
            return  <Label style={{marginBottom:"5px", fontWeight:"normal"}} className={`${item.color} ${item.field +  "-"+indexNested +"-AND" }`}   key={index +  " "+ indexNested}>
                {this.getValueOrDate(value)}
                <Icon name='delete' onClick={() => this.props.removeFilter(item.field, value, item.filterType, this.props.filters)}/>
            </Label>
        }
        else {
            return  <Label style={{marginBottom:"5px", fontWeight:"normal"}} className={`${item.color} ${item.field +  "-"+indexNested +"-"+item.filterType}`}   key={index +  " "+ indexNested}>
                {this.getValueOrDate(value)}
                <Icon name='delete' onClick={() => this.props.removeFilter(item.field, value, item.filterType, this.props.filters)}/>
            </Label>
        }
    }

    render () {
        return this.props.filters?.map((item, index) => (item.values.map((value,indexNested) => this.generateLabels(value,indexNested, item, index))))
    }

}

function mapStateToProps(state) {
    const { LEv4Reducer, URLReducer, ConfigReducer} = state
    const {configJson } = ConfigReducer;
    const {isCacheView} = LEv4Reducer;
    const {guiLanguage, URLString, filters } = URLReducer
    return {filters, configJson, URLString, isCacheView, guiLanguage}
}



export default connect(mapStateToProps, mapDispatchToProps)(UsedFilter);