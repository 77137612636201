import React from "react";
import "./ErrorView.css";

class ErrorView extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

        };
    }

    render() {
        return  <div className="content-wrapper">
                    <div className="content">
                        <div className="inner-wrapper">
                            <div className="image">
                                <span className="helper"/>
                                <img alt={""} src="two_socks_franz.png"/>
                            </div>
                            <div className="text-wrapper">
                                <div className="text">
                                    <h2 className={"h-error"}>
                                        Oops!
                                    </h2>
                                    <h3 className={"h-error"}>
                                        Something went very wrong.
                                    </h3>
                                    <p>
                                        The page you are looking for doesn't exist or another error occurred.
                                    </p>
                                    <a href="/dashboard" target="_self" className="button">
                                        Go back to dashboard
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
    }
}

export default  (ErrorView);