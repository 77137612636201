import React from "react";
import {connect} from "react-redux";
import {Button, Grid, Header, Icon, Loader, Ref, Segment} from "semantic-ui-react";
import ContentGridComputer from "./DashboardComputerComponents/ContentGridComputer";
import HeaderMenuComputer from "./DashboardComputerComponents/headerMenuComputer/HeaderMenuComputer";
import {clearFilters} from "../../../actions/FilterActions";

class DashboardComputer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        };
        this.contextRef = React.createRef();
    }


    render() {
        return  <>
                    <Grid columns={1} >
                        <Ref innerRef={this.contextRef}>
                                <Grid.Column only='computer tablet' >
                                <HeaderMenuComputer contextRef={this.contextRef}/>
                                {!this.props.resultsLoaded ?
                                    <Grid container centered stretched columns={1} style={{height: '75vh'}}>
                                        <Grid.Column verticalAlign={"middle"}>
                                            <Loader inline='centered' active size="large">{this.props.translationsConfig.loading[this.props.guiLanguage]}</Loader>
                                        </Grid.Column>
                                    </Grid> : null}
                                {this.props.resultsLoaded ?
                                    <>
                                        {(this.props.fetchedDocumentsFromDashboard.documents?.length !==0) ?
                                            <ContentGridComputer/>
                                        :   <Segment placeholder basic>
                                                <Header icon>
                                                    <Icon name='search' />
                                                    {this.props.translationsConfig.noSearchResults[this.props.guiLanguage]}
                                                </Header>
                                                <Segment.Inline>
                                                    <Button onClick={() => window.location.href ="/"} primary>{this.props.translationsConfig.clearQuery[this.props.guiLanguage]}</Button>
                                                </Segment.Inline>
                                            </Segment>}
                                    </>: null}
                                </Grid.Column>
                        </Ref>
                    </Grid>
        </>

    }
}

const mapDispatchToProps = {
    clearFilters
};
function mapStateToProps(state) {
    const { URLReducer, DashboardReducer, ConfigReducer } = state
    const {fetchedDocumentsFromDashboard, resultsLoaded } = DashboardReducer
    const { guiLanguage, filters } = URLReducer
    const {configJson, translationsConfig} = ConfigReducer
    return {configJson, translationsConfig, filters, guiLanguage, fetchedDocumentsFromDashboard, resultsLoaded}
}

export default connect(mapStateToProps, mapDispatchToProps)(DashboardComputer);