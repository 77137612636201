import parse from 'html-react-parser'
import sanitizeHtml from "sanitize-html";

export function createReactJSXFromString(htmlString, callback) {
    var parsedJSX = parse(htmlString ,callback ? {
        replace: callback
    }: undefined)

    return parsedJSX
}

export function sanitizeHTMLStringAndReturnCleanString(htmlString, allowedTagsList, allowedAttributesArray) {
    allowedTagsList = allowedTagsList ? allowedTagsList : [ 'b', 'i', 'em', 'strong', 'a' , 'p', 'hr' ];
    allowedAttributesArray = allowedAttributesArray ? allowedAttributesArray : {'a': [ 'href' ]};

    var cleanHTMLString = sanitizeHtml(htmlString, {
        allowedTags: allowedTagsList,
        allowedAttributes: allowedAttributesArray
    });
    return cleanHTMLString
}