import React from 'react';
import {connect} from 'react-redux';
import {changeFocusedLabel, openJumpPortal} from "../../../../actions/CacheViewActions";
import {addFilter} from "../../../../actions/FilterActions";
import {Grid, Label} from "semantic-ui-react";



class ShowMoreOrLessButton extends React.Component  {
    constructor(props) {
        super(props);
        this.state = {
        }
    }

    generateMoreOrLessButton = () => {
        let currentLanguage = this.props.guiLanguage

        return  <Label onClick={this.props.showMore} className={"infiniteScrollerMoreOrLessButton"}>
                    {this.props.showMoreVariable ?
                                this.props.translationsConfig.showLess[currentLanguage] :
                                this.props.translationsConfig.showMore[currentLanguage]}
                </Label>
    }


    render() {
        if(this.props.isCacheView || this.props.mobile)
            return  <Grid.Row key={20000} width={16} floated="left" style={{cursor: "pointer"}}>
                        {this.generateMoreOrLessButton()}
                    </Grid.Row>


        return this.generateMoreOrLessButton()
    }
}

function mapStateToProps(state) {
    const {LEv4Reducer, URLReducer, ConfigReducer } = state
    const { guiLanguage } = URLReducer
    const { translationsConfig } = ConfigReducer
    const {mobile, isCacheView} = LEv4Reducer
    return {translationsConfig,  guiLanguage, mobile, isCacheView}
}

const mapDispatchToProps = {
    openJumpPortal,
    addFilter,
    changeFocusedLabel
};

export default connect(mapStateToProps,mapDispatchToProps)(ShowMoreOrLessButton);