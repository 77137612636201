import axios from "axios";
import {success} from "./AuthActions";
import jwt from 'jwt-decode'
export const SUCCESS_TRANSLATION_CONFIG = "SUCCESS_TRANSLATION_CONFIG";
export const FAILURE_TRANSLATION_CONFIG = "FAILURE_TRANSLATION_CONFIG";
export const SUCCESS_CONFIG = "SUCCESS_CONFIG";
export const FAILURE_CONFIG = "FAILURE_CONFIG";


const TRANSLATION_URL = "/.netlify/functions/translations";
const CONFIG_URL = "/.netlify/functions/configuration";


export const getTranslationConfigFromQueryService = () => async (dispatch) => {
    try {
        var netlifyHost = process.env.REACT_APP_NETLIFYHOST;

        if (netlifyHost === undefined)
            netlifyHost = "";

        const response = await axios.get(netlifyHost + TRANSLATION_URL);

        mapLanguageOptionsFromTranslationConfigToNewStructure(response.data)

        getLanguageFromBrowser(response);
        ///////////////////////////

        dispatch(successfullyFetchedTranslation(response.data));
        return Promise.resolve(response.data);
    } catch (error) {
        dispatch(failureTranslation());
        return Promise.reject(error);
    }
};


export const getConfigFromQueryService = (translationsConfig, jwttoken) => async (dispatch) => {
    var netlifyHost = process.env.REACT_APP_NETLIFYHOST;
    if (netlifyHost === undefined)
        netlifyHost = "";

    return Promise.resolve(await axios.get(netlifyHost + CONFIG_URL, {
        headers: {
            'Authorization': `Bearer ` + (jwttoken ? jwttoken : sessionStorage.getItem("jwtToken"))
        }
    }).then((response) => {
        var configJson = response.data

        if(jwttoken) {
            const decodedJwtToken = jwt(jwttoken)
            const username = decodedJwtToken.sub
            sessionStorage.setItem("jwtToken", jwttoken)
            sessionStorage.setItem("username", username);
            sessionStorage.setItem("isLoggedIn", "true");
            dispatch(success({ username: username}));
        }

        configJson.sorting = configJson.sortings.map(function(item){
            return {default: item.isDefault, direction: item.direction, value: item.value, name: {[item.name]: translationsConfig.translations[item.name]}};
        })
        dispatch(successfullyFetchedConfig(configJson));
        return {success: true, configJson: configJson};
    }).catch((err) => {
        if((err.response && err.response.status !== 401) || !err.response)
            return 'error';
        dispatch(failureConfig());
        return false;
    }));

};

function getLanguageFromBrowser(response) {
    // The following is used to get the Browser Language and if it exists in the map from config,
    // override previously set language (should happen only once) This needs to be tested
    if (!sessionStorage.getItem('languageFromBrowser')) {
        var lang = navigator.language || navigator.userLanguage;
        var langu = lang.substring(0, 2);
        var langOptions = response.data.languageOptions;
        Object.keys(langOptions).forEach(languageElement => {
            if (languageElement === langu) {
                localStorage.setItem('language', langu);
                console.log("Language set from Browser: " + langu)
            }
        })
        sessionStorage.setItem('languageFromBrowser', langu);
    }
}

const mapLanguageOptionsFromTranslationConfigToNewStructure = (config) => {
    var tempLanguageOptions =[]

    Object.keys(config.languageOptions).forEach(languageElement=> {
        tempLanguageOptions.push({
            "key": languageElement,
            "text": languageElement.toUpperCase(),
            "value": languageElement,
            "flag": languageElement === "en" ? "gb uk": languageElement
        })
    })
    config.translations.languageOptions = tempLanguageOptions

}


const successfullyFetchedConfig = (config) => {
    return {
        type: SUCCESS_CONFIG,
        payload: config
    };
};

const failureConfig = () => {
    return {
        type: FAILURE_CONFIG,
        payload: {}
    };
};

const successfullyFetchedTranslation = (data) => {
    return {
        type: SUCCESS_TRANSLATION_CONFIG,
        payload: data
    };
};

const failureTranslation = () => {
    return {
        type: FAILURE_TRANSLATION_CONFIG,
        payload: {}
    };
};