export function returnCompleteFilterEntry (newField, newLabel, newColor, newValues, newType, newSourceType, newRequestedObjectFieldType, translations) {

 return {
     field: newField,
     label: newLabel,
     color: newColor,
     values: newValues,
     filterType: newType,
     sourceType: newSourceType,
     requestObjectFieldType: newRequestedObjectFieldType,
     translations: translations,
 }
}