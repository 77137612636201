export class LangSplitter {
    static splitter = ';;';

    static guiLanguageToNr(lang) {
        switch (lang) {
            case "de":
                return 0;
            case "fr":
                return 1;
            case "it":
                return 2;
            case "en":
                return 3;
            default:
                return 0;
        }
    }

    static getKeyFromLanguage(lang, key) {
        if (typeof key === 'string' || key instanceof String){
            if(key.split(this.splitter).length===4) {
                let i = 0;
                if (lang === "de") {
                    i = 0;
                } else if (lang === "fr") {
                    i = 1;
                } else if (lang === "it") {
                    i = 2;
                } else if (lang === "en") {
                    i = 3;
                }
                return key.split(this.splitter)[i];
            }else{
                return key;
            }
        }else{
            // This should not be the case any more
            // Somehow react elements are thrown in here instead of strings.
            // Also list of elements one react one string are sent in here...
            if(key.props.text !== undefined){
                return key.props.text;
            }else{
                return "Error"
            }
            //return key;
        }
    }

    static combineAllLanguages(langArr) {
        return langArr[0] + this.splitter + langArr[1] + this.splitter + langArr[2] + this.splitter + langArr[3];
    }

    static isMultiLanguageString(value) {
        return !(value instanceof Object);
    }
}
