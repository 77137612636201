import React from "react";

import {connect} from "react-redux";
import { openJumpPortal} from "../../../actions/CacheViewActions";

class MarkedSearchTerm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }

    generateNameString = () => {
        return "selectedSearchTermWord" +this.props.number
    }

    isFocused = () => {
        return this.props.focusedOccurrence === this.generateNameString()
    }


    render() {
        return  <span data-markjs="true" className="selectedSearchTermWord">
                    <span data-markjs="true" name={this.generateNameString()}>
                        <span  name={this.props.occurrence}
                               color={this.props.configJson.generalOptions.searchTermHighlightedColor}
                               basic="true"
                               className={"markedOccurrence_wlclight8" + (this.isFocused() ? " markedOccurrence_focused" : "")}>
                                    {this.props.text}
                                </span>
                    </span>
                </span>

    }
}
const mapDispatchToProps = {

    openJumpPortal
};
function mapStateToProps(state) {
    const { ConfigReducer, CacheViewReducer } = state
    const {focusedOccurrence } = CacheViewReducer
    const {configJson, translationsConfig} = ConfigReducer;
    return {configJson, translationsConfig, focusedOccurrence}
}


export default connect(mapStateToProps, mapDispatchToProps) (MarkedSearchTerm);