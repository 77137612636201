import Mark from "mark.js";


export function markSearchTerms(node, URLState, configJson) {
    if (!URLState.searchTerm)
        return;

    if(!sessionStorage.getItem('showSearchTerm')) {
        sessionStorage.setItem('showSearchTerm', (configJson.generalOptions.showSearchTerm ? JSON.parse(configJson.generalOptions.showSearchTerm) : true))
    }

    var markInstance = new Mark(node);

    if (!JSON.parse(sessionStorage.getItem('showSearchTerm'))) {
        markInstance.unmark();
        return ;
    }

    var searchTerm = URLState.searchTerm
    searchTerm = searchTerm && searchTerm.toString().replace(/(~[0-9]+)|~|\+|\*|\(|\)|\||AND|OR|NOT/g, ' ').replace(/  +/g, ' ').trim();

    const regex = /["']([^"']+)["']|[^\s"]+/gm;

    var options = {};
    options["element"] = "span";
    options["className"] = "selectedSearchTermWord";
    options["exclude"] =[".frameMarkedReferencesNotLinks", ".labelDropdownBorder", ".groupName", "wbr"];
    options["separateWordSearch"] = false;
    options["acrossElements"] = true;
    options["ignoreJoiners"] = true;
    var searchTermMatch;
    markInstance.unmark({
        done: function(){
            while ((searchTermMatch = regex.exec(searchTerm)) !== null) {
                if (searchTermMatch.index === regex.lastIndex) {
                    regex.lastIndex++;
                }

                searchTermMatch.forEach((match, groupIndex) => {
                    if(match !== undefined) {
                        markInstance.mark(match, options);
                    }
                });
            }
        }
    });
}


function generateRelevantLabelGroupsForDocument(filterMenuOptions, data) {
    var labelGroupsToShow = []
    filterMenuOptions.forEach(
        (element) => {
            if (element.showInCacheView && (element.field.replace(/List$/, '') in data.nestedMetadataMap || element.field in data.metadataKeywordTextMap)
                && JSON.parse(sessionStorage.getItem('turnOnReference' + element.field.replace(/List$/, '')))) {
                labelGroupsToShow.push(element.label[localStorage.getItem('language')]);
            }
        });
    return labelGroupsToShow;
}

export function insertReferencesInto(html, data, filterMenuOptions) {
    if (data?.nestedMetadataMap !== undefined) {
        var labelGroupsToShow = generateRelevantLabelGroupsForDocument(filterMenuOptions, data);

        var allOccurrences = collectAndSortAllOccurrences(data, filterMenuOptions, labelGroupsToShow);

        if (allOccurrences.length > 0 && labelGroupsToShow.length > 0)
            return insertAllOccurencesInto(html, allOccurrences, labelGroupsToShow);
        else
            return html
    }
}

function collectAndSortAllOccurrences(documentData, filterMenuOptions, labelGroupsToShow) {
    var allOccurrences = [];
    if (documentData !== undefined && documentData !== undefined && documentData.nestedMetadataMap !== undefined) {
        for (const [key] of Object.entries(documentData.nestedMetadataMap)) {
            if(labelGroupsToShow.includes(filterMenuOptions.find((element) => element.field.replace(/List$/, '') === key)?.label[localStorage.getItem('language')])) {
                documentData.nestedMetadataMap[key].forEach((element) => {
                    element.occurrences.forEach((occ) => {
                        const occurrenceSplit = occ.split(",");
                        allOccurrences.push({
                            occStart: occurrenceSplit[0],
                            occEnd: occurrenceSplit[1]
                        });
                    })
                })
            }
        }
    }
    allOccurrences.sort(function (a, b) {
        return a.occStart - b.occStart
    });
    return allOccurrences;
}

function insertAllOccurencesInto(html, allOccurrences) {
    var insertedHtml = "";
    var lastIndex = 0;

    allOccurrences.forEach(function (occ) {
        if (occ.occStart > lastIndex ) {
            const occStart = occ.occStart;
            const occEnd = occ.occEnd;

            insertedHtml += html.substring(lastIndex, occStart);
            if (insertedHtml.substring(insertedHtml.length - 80).match(/[\s\S]*href=["'][/a-z0-9:.]*$/))
                insertedHtml += html.substring(occStart, occEnd)
            else {
                insertedHtml += "<span " +
                    "class=\"replace\" " +
                    "occurrence=\"" + occStart +"," +occEnd + "\" " +
                    "content=\"" + html.substring(occStart, occEnd) +   "\"" +
                    " ></span>";
            }
            lastIndex = +occEnd;
        }
    });
    insertedHtml += html.substring(lastIndex, html.length - 1);
    insertedHtml = insertedHtml.replace(/<a([^>]*)><\/a>/g, '<a$1>&#128279;</a>');

    return insertedHtml;
}
