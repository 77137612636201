function isTypeNumber(value) {
    return value !== undefined && value !== null && typeof value === "number";
}

function isTypeBoolean(value) {
    return value && typeof value === "boolean";
}

function toBoolean(value) {
    if (value === "true") return true;
    if (value === "false") return false;
    throw "Invalid type parsed as Boolean value";
}
/* Encoder for qs library which preserve number types on the URL. Numbers
are padded with "n_{number}_n", and booleans with "b_{boolean}_b"*/

export default {
    encode: function encode(value, _encode) {
        if (isTypeNumber(value)) {
            return "n_".concat(value, "_n");
        }

        if (isTypeBoolean(value)) {
            return "b_".concat(value, "_b");
        }

        return _encode(value);
    },
    decode: function decode(value, _decode) {
        //eslint-disable-next-line
        if (/n_-?[\d\.]*_n/.test(value)) {
            var numericValueString = value.substring(2, value.length - 2);
            return Number(numericValueString);
        }

        if (/^b_(true|false)*_b$/.test(value)) {
            var booleanValueString = value.substring(2, value.length - 2);
            return toBoolean(booleanValueString);
        }

        return _decode(value);
    }
};