import React from "react";
import {connect} from "react-redux";

import "./weblaw_intemgroupheader_computer.css"


class ItemGroupHeaderComputer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            changeBodyHeader: this.props.changeBodyHeader
        };

    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        // Due to the fact that fetchedDocumentsFromDashboard is not replaced but stays the same, we need a state variable to provoke a re-rendering
        // We use changeBodyHeader for this
        if (prevProps.changeBodyHeader !== this.props.changeBodyHeader)
            this.setState({changeBodyHeader : this.props.changeBodyHeader})
    }

    render() {
        return  this.props.fetchedDocumentsFromDashboard?.documents && this.props.fetchedDocumentsFromDashboard?.documents ?
            <div id={"itemGroupHeaderWrapper"} >
                <span className={"itemGroupHeaderItalic"} >
                    {this.props.translationsConfig.resultsFrom[this.props.guiLanguage] + " "}
                </span>
                <span className={"itemGroupHeaderNormal"} >
                    {((this.props.fetchedDocumentsFromDashboard.documents.length > 0) ? 1 : 0) + " - " + this.props.fetchedDocumentsFromDashboard.documents.length + " " }
                </span>
                <span className={"itemGroupHeaderItalic"} >
                    {this.props.translationsConfig.to[this.props.guiLanguage] + " " }
                </span>
                <span className={"itemGroupHeaderNormal"} >
                    {this.props.fetchedDocumentsFromDashboard.totalNumberOfDocuments}
                </span>
            </div> : null



    }
}

function mapStateToProps(state) {
    const { URLReducer, DashboardReducer, ConfigReducer } = state
    const { guiLanguage } = URLReducer
    const {fetchedDocumentsFromDashboard, changeBodyHeader } = DashboardReducer
    const {translationsConfig} = ConfigReducer
    return {translationsConfig, fetchedDocumentsFromDashboard, changeBodyHeader, guiLanguage}
}

export default connect(mapStateToProps) (ItemGroupHeaderComputer);