import React from "react";
import {
    closeJumpPortal,
    fetchCustomDocPost,
    goBackToDashboard,
    toggleSidebar
} from "../../../../actions/CacheViewActions";
import {connect} from "react-redux";
import {Button, Icon} from "semantic-ui-react";
import {Link} from "react-router-dom";
import "./weblaw_headermenu_mobile.css"
import {processSearchRequest} from "../../../../actions/DashboardAction";

class HeaderMenuMobile extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }

    setBackToDashboardBoolean = () => {
        this.props.closeJumpPortal();
        this.props.goBackToDashboard();
    }

    reachedLastDocument = () => {
        return this.props.currentIndex+1 === this.props.fetchedDocumentsFromDashboard.totalNumberOfDocuments
    }

    foundDocumentInDocumentList = () => {
        return (this.props.fetchedDocumentsFromDashboard.totalNumberOfDocuments > this.props.currentIndex) && this.props.currentIndex >= 0
    }

    generatePositionString = () => {
        return (this.props.currentIndex + 1) + ' / ' + this.props.fetchedDocumentsFromDashboard.totalNumberOfDocuments
    }

    fetchNextSingleDocument = () => {
        if ((this.props.fetchedDocumentsFromDashboard.documents.length / 2) - 1 < this.props.currentIndex && this.props.resultsLoaded) {
            this.props.processSearchRequest(this.props.URLReducer, this.props.configJson, this.props.requestBody, this.props.fetchedDocumentsFromDashboard.documents)
        }
        this.props.fetchCustomDocPost(this.props.fetchedDocumentsFromDashboard.searchResultDocumentIdList[this.props.currentIndex + 1],
            this.props.guiLanguage,
            this.props.configJson,
            this.props.URLReducer,
            this.props.fetchedDocumentsFromDashboard.searchResultDocumentIdList)
    }

    fetchPreviousSingleDocument = () => {
        this.props.fetchCustomDocPost(this.props.fetchedDocumentsFromDashboard.searchResultDocumentIdList[this.props.currentIndex - 1],
            this.props.guiLanguage,
            this.props.configJson,
            this.props.URLReducer,
            this.props.fetchedDocumentsFromDashboard.searchResultDocumentIdList)
    }

    render() {
        return  <div style={{textAlign: "left"}}  id={"sideMenuCacheViewAccordionFirstSegmentMobile"}>
                    <Link to={`/dashboard?` + (this.props?.URLString?.replace("?","") ? this.props.URLString?.replace("?","") : "")}>
                        <Button id={"backButton"}  size={"small"} basic onClick={this.setBackToDashboardBoolean}>
                            <Icon size={"small"} name={"chevron left"}/>
                                {this.props.translationsConfig.backButton[this.props.guiLanguage]}
                        </Button>
                    </Link>
                    <Button className={"sideMenuToggleMobile"} icon={this.props.showSidebar ?"close" :'filter' } disabled={this.props.showSidebar} onClick={() => this.props.toggleSidebar(true)}/>
                    {this.foundDocumentInDocumentList() ?
                        <span>
                            <span id={"currentScrollPositionStringMobile"}>
                                {this.generatePositionString()}
                            </span>
                                <Button size={"small"} icon='chevron left' disabled={this.props.currentIndex === 0}
                                        style={this.props.currentIndex === 0 ? {background:'lightgrey', color: "rgb(51, 51, 51)"} : {color: "rgb(51, 51, 51)"}}
                                        onClick={() => this.fetchPreviousSingleDocument()}/>
                                <Button size={"small"} icon='chevron right'
                                        disabled={this.reachedLastDocument()}
                                        style={this.reachedLastDocument() ? {background:'lightgrey', color: "rgb(51, 51, 51)"} : {color: "rgb(51, 51, 51)"}}
                                        className={"navigationButtons"}
                                        onClick={() => this.fetchNextSingleDocument()}/>
                        </span> : null}
                </div>

    }
}
function mapStateToProps(state) {
    const {ConfigReducer, URLReducer, CacheViewReducer, DashboardReducer } = state
    const {URLString, guiLanguage } = URLReducer
    const {fetchedDocumentsFromDashboard, requestBody, resultsLoaded} = DashboardReducer
    const { singleDocumentData, currentIndex, showSidebar } = CacheViewReducer
    const { configJson, translationsConfig} = ConfigReducer
    return {configJson, URLString, translationsConfig, singleDocumentData, fetchedDocumentsFromDashboard, showSidebar, currentIndex, guiLanguage, URLReducer, requestBody, resultsLoaded}
}

const mapDispatchToProps = {
    goBackToDashboard,
    closeJumpPortal,
    toggleSidebar,
    fetchCustomDocPost,
    processSearchRequest
};

export default connect(mapStateToProps, mapDispatchToProps) (HeaderMenuMobile);