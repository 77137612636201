import {doFilterValuesMatch, removeSingleFilterValue} from "../services/URLHelper/Helpers";
import _toConsumableArray from "@babel/runtime/helpers/toConsumableArray";
 import {returnCompleteFilterEntry} from "../services/FilterService";
export const CHANGE_FILTER = "CHANGE_FILTER";
export const CLEARED_FILTER = "CLEARED_FILTER";
export const CHANGE_SEARCH_TERM = "CHANGE_SEARCH_TERM";
export const SHOW_DUPLICATE_FILTER_PORTAL = "SHOW_DUPLICATE_FILTER_PORTAL";
export const DEBOUNCE_SEARCH_TERM =  "DEBOUNCE_SEARCH_TERM"


export const closeDuplicateFilterPortal = () => (dispatch) => {
    dispatch(showDuplicateFilterPortal(false));
}


const compareTwoDates = (firstDate, secondDate)  => {
    return firstDate.getFullYear() === secondDate.getFullYear() && firstDate.getDate() === secondDate.getDate() && firstDate.getDay() === secondDate.getDay()
}

export const addFilter = (name, value, type, oldFilterArray, filterMenu, translations) =>  (dispatch) => {
    // We used this function, to add a filter to the filter array.
    // We first extract the information from the filterMenu.
    var currentLabelInfos = filterMenu.find(function (f) {
        return f.field === name && f.filterType === type;
    });

    // If type is undefined, we create a default value
    type = type  ? type : "all";
    var newFilterArray;
    var filterDuplicate = false;
    // If oldFilterArray is defined, there are already some filter
    if (oldFilterArray) {
        // We check, if we already have some filter with the same field / type
        var existingFilter = oldFilterArray.find(function (f) {
            return f.field === name && f.filterType === type;
        }) || {};

        // If we found a filter entry with the same field / type, we then check if values contain exact the same filter value
        filterDuplicate = existingFilter.values && existingFilter.values.find((function(f){
            if(f.from && f.to && value.from && value.to) {
                const existingDateFrom = new Date(f.from);
                const existingDateTo = new Date(f.to);
                const newDateFrom = new Date(value.from);
                const newDateTo = new Date(value.to);

                return compareTwoDates(existingDateFrom, newDateFrom) && compareTwoDates(existingDateTo, newDateTo)
            }
            if(f.from && f.to && value.from && value.to && (f.from  === value.from && f.to  === value.to) )
                return true
            return f === value
        } ))

        // If we couldn't find a filterDuplicate we proceed in the first part of the if statement
        if(!filterDuplicate) {
            // We generate a new filter array without the entry with the matching field / type
            var allOtherFilters = oldFilterArray.filter(function (f) {
                return f.field !== name || f.filterType !== type;
            }) || [];

            // We create a new values entry and fill it with all existing values.
            // We than concat the new value with the existing values
            var existingFilterValues = existingFilter.values || [];
            var newFilterValues = existingFilterValues.find(function (existing) {
                return doFilterValuesMatch(existing, value);
            }) ? existingFilterValues : existingFilterValues.concat(value);

            // Here the new entry is added into the existing array
            newFilterArray = [].concat(_toConsumableArray(allOtherFilters), [
                returnCompleteFilterEntry(name,
                    currentLabelInfos.label[localStorage.getItem('language')],
                    currentLabelInfos.color,
                    newFilterValues,
                    type,
                    currentLabelInfos.sourceType,
                    currentLabelInfos.requestObjectFieldType,
                    translations)
            ])
        }
    } else {
        //if the filter array is empty we create a new array and add the entry
        newFilterArray = [returnCompleteFilterEntry(name,
            currentLabelInfos.label[localStorage.getItem('language')],
            currentLabelInfos.color,
            [value],
            type,
            currentLabelInfos.sourceType,
            currentLabelInfos.requestObjectFieldType,
            translations)
        ]
    }

    if(filterDuplicate)
        dispatch(showDuplicateFilterPortal(true));
    else
        dispatch(successfullyChangedFilter({filters : newFilterArray}));

};

export const setFilter = (name, value, type, oldFilterArray, filterMenu) => (dispatch) => {
    // this function replaces the field and filterType entry
    // it doesn't append the value in the existing values array, it replaces the values array
    var currentLabelInfos = filterMenu.find(function (f) {
        return f.field === name && f.filterType === type;
    });
    var newFilterArray;


    // If oldFilterArray is defined, there are already some filter
    if (oldFilterArray) {
        // We generate a new filter array without the entry with the matching field / type
        var filters = oldFilterArray.filter(function (filter) {
            return filter.field !== name || filter.filterType !== type;
        }) || {};

        // We replace concat the new entry with the existing filter array
        newFilterArray = [].concat(_toConsumableArray(filters), [
            returnCompleteFilterEntry(name,
            currentLabelInfos.label[localStorage.getItem('language')],
            currentLabelInfos.color,
                [value],
            type,
            currentLabelInfos.sourceType,
            currentLabelInfos.requestObjectFieldType,
                undefined)
        ])
    } else {
        //if the filter array is empty we create a new array and add the entry
        newFilterArray = [ returnCompleteFilterEntry(name,
            currentLabelInfos.label[localStorage.getItem('language')],
            currentLabelInfos.color,
            [value],
            type,
            currentLabelInfos.sourceType,
            currentLabelInfos.requestObjectFieldType,
            undefined)]
    }

    dispatch(successfullyChangedFilter({filters : newFilterArray}));


}


export const removeFilter = (name, value, filterType, oldFilterArray) =>  (dispatch) =>  {
    // This function removes a specific filter value
    var filters = oldFilterArray;
    var updatedFilters = oldFilterArray;

    // if we input a type without a value, the entry with matching type / field is removed from the filter list
    if (!value && filterType) {
        updatedFilters = filters.filter(function (filter) {
            return !(filter.field === name && filter.filterType === filterType);
        });
    } else if (value) {
        // If the value is defined, we remove the matching value from the values array list
        updatedFilters = removeSingleFilterValue(filters, name, value, filterType);
    } else {
        // The default case removes all filter with matching field name
        updatedFilters = filters.filter(function (filter) {
            return filter.field !== name;
        });
    }
    dispatch(successfullyChangedFilter({filters : updatedFilters}));

}
export const changeSearchTerm = (searchTerm) => (dispatch) => {
    dispatch(successfullyChangeSearchTerm(searchTerm));
}

 export const debounceSearchTerm = (searchTerm) => (dispatch) => {
     dispatch(successfullyDebounceSearchTerm(searchTerm));
 }


export const clearFilters = (oldFilterArray, except) => (dispatch) => {
    // This function removes all filters
    if(!oldFilterArray)
        return
    except = !except && [];
    var filters = oldFilterArray;
    var updatedFilters = filters.filter(function (filter) {
        var filterField = filter.field;
        return except.includes(filterField);
    });

    dispatch(clearedFilter({filters : updatedFilters}));
}


const clearedFilter = (filters) => {
    return {
        type: CLEARED_FILTER,
        payload: filters
    };
};

 const successfullyDebounceSearchTerm = (searchTerm) => {
     return {
         type: DEBOUNCE_SEARCH_TERM,
         payload: searchTerm
     };
 };

const successfullyChangeSearchTerm = (searchTerm) => {
    return {
        type: CHANGE_SEARCH_TERM,
        payload: searchTerm
    };
};


const successfullyChangedFilter = (filters) => {
    filters.filters = filters.filters.sort(function (a, b) {
        return a.color.localeCompare(b.color);
    });

    // If negative Filter, positive Filter > Negative Filter
    filters.filters.map(filter => {
        // It's a path in tree view if it has / or !
        if(filter.values[0][0] === '/' || filter.values[0][0] === '!'){
            filter.values.sort((a, b) => {
                return b.localeCompare(a);
            });
            const indexFirstNegative = filter.values.findIndex(a => a[0] === '!')
            if(indexFirstNegative !== -1){
                const negatives = filter.values.splice(indexFirstNegative);
                negatives.reverse();
                filter.values.reverse();
                filter.values = filter.values.concat(negatives);
            } else {
                filter.values.reverse();
            }
        }
    });

    return {
        type: CHANGE_FILTER,
        payload: filters
    };
};

const showDuplicateFilterPortal = (value) => {
    return {
        type: SHOW_DUPLICATE_FILTER_PORTAL,
        payload: value
    };
};


