import React, {Component} from 'react';
import {connect} from 'react-redux';
import {changeFocusedLabel, openJumpPortal} from "../../../../actions/CacheViewActions";
import {addFilter} from "../../../../actions/FilterActions";
import {getTranslationAndScaleValues} from "../../../../services/LabelScalingAndTranslationService";
import {Icon, Popup} from "semantic-ui-react";
import "./weblaw_singlelabel_computer.css"
import {format} from "date-fns";
import { LangSplitter } from "../../../../services/LangSplitter";
import {getLinkStringForLanguage, hasLinkStringForLanguage} from "../../../../services/Helpers/LinkStringHelper";

class SingleLabel extends Component {
    constructor(props) {
        super(props);
        this.state = {
            linksWeblaw : '/api/link/',
            showDetails: false,
            timer: "",
            translationWidth: -30,
            scalingFactor: 1.2,
            showPopUp: false,
        }
        this.refToAllwaysVisibleElementInLabel = React.createRef();
        this.refToHiddenElementInLabel = React.createRef();
        this.cacheViewComponentRefToMoveThePopUpInPosition = React.createRef();
    }



    componentDidUpdate(prevProps, prevState, snapshot) {
        // If the label is focused and the global focusedLabel value changes and the value is unequal to the label identifier, we need to stop focusing the label
        if(this.state.showDetails && this.props.focusedLabel !== this.setButtonText(false) && prevProps.focusedLabel !== this.props.focusedLabel) {
            this.setState({showDetails: false, showPopUp: false})
        }

        // In the "cacheview" we use pop-ups to display the labels
        // To prevent overlay effects, we need to close the pop-up as soon the user starts to scroll
        // To achieve this we use the handleScroll function with the scroll event listener from javascript
        if(this.state.showPopUp && !prevState.showPopUp && this.props.isCacheView)
            window.addEventListener('scroll', this.handleScroll);


        // After we close the pop-up we need to remove the event listener
        if(!this.state.showPopUp && prevState.showPopUp && this.props.isCacheView)
            window.removeEventListener('scroll', this.handleScroll);


        // we compute, if a special translation or a different scaling factor is necessary to keep the labels inside the visible viewport
        if(this.state.showDetails && (this.state.showDetails !== prevState.showDetails)) {
            let xPosition = this.props.isCacheView && !this.props.mobile ? this.cacheViewComponentRefToMoveThePopUpInPosition.current?.getBoundingClientRect().x : this.refToAllwaysVisibleElementInLabel.current?.getBoundingClientRect().x
            const {translationWidth, newScalingFactor} = getTranslationAndScaleValues(this.refToAllwaysVisibleElementInLabel, this.refToHiddenElementInLabel, xPosition, window.innerWidth)
            if(translationWidth !== 0)
                this.setState({
                    translationWidth:  translationWidth
                })
            if(newScalingFactor !== 0)
                this.setState({
                    scalingFactor:  newScalingFactor
                })
        }
    }



    handleScroll = () => {
        this.setState({showPopUp: false, showDetails: false})
    }

    generateOccurrencesString = () => {
        if(this.props.item.nestedReference && JSON.parse(this.props.configJson?.generalOptions?.showOccurrenceQuantity)){
            if(this.props.reference.occurrences.length >0)
                return "(" + this.props.reference.occurrences.length + ")"
        }
        return null
    }


    openJumpPortal = () => {
        this.props.openJumpPortal(this.props.reference.occurrences,this.setButtonText(false))
    }

    setButtonText = (withPrefix, weApplyFilter) => {
        var prefix;
        var value;
        // If we apply the field as filter, we want to choose the guiLanguage as language for the filter
        // If we are in the CacheView we want the guiLanguage to determine the label text
        let language = weApplyFilter ? this.props.guiLanguage : (this.props.guiLanguage)

        if(this.props.item.prefixForResultView && !this.props.isCacheView) {
            prefix = this.props.item?.prefixForResultView[language]
        }

        if(this.props.dateValue) {
            value = format(new Date(this.props.dateValue), this.props.configJson.generalOptions.dateFormatLabels)
            return (prefix && withPrefix) ? prefix + value : value;
        }

        if(this.props.reference === null) {
            return "null"
        }

        if(this.props.reference?.term) {
            value = this.props.reference.term[language]
            return (prefix && withPrefix) ? prefix + value :value
        }

        // Label that are derived from metadataKeywordTextMap like "SR Kategorien" are up to this point "23.08.2022" supplied without translation
        // which means, without a termMap. The label string value is in this.props.reference.
        // In the future, somebody will change the backend, to supply a termMap in this.props.reference instead of a string and the translation should work out of the box.
        // If it doesn't, I suggest to be idiotic and use a console.log(this.props.reference) to see the datastructures in the developer tools console and to change the code accordingly
        // but remember, this.props.reference.termMap is used to display the nestedRef label text, and you need to ensure that this still works. If it doesnt....
        value = (this.props.reference.termMap ? (this.props.reference.termMap[language]) : this.props.reference)

        value = LangSplitter.getKeyFromLanguage(language,value)

        return (prefix && withPrefix) ? prefix + value : value
    }

    isPath(){
        return this.props.reference && this.props.reference[0] && this.props.reference[0] === '/';
    }

    setButtonTextAndShorten = () => {
            const splitArr = this.props.reference.split('/');
            if(splitArr.length >= 5){
                return '/' + splitArr[1] + '/.../' + splitArr[splitArr.length - 2] + '/' + splitArr[splitArr.length - 1];
            }
            return this.props.reference;
    }


    applyFilter = () => {
        var tempValue = this.props.dateValue ? {'from': this.props.dateValue, 'to': this.props.dateValue} : this.setButtonText(false, true);
        if(this.props.item.nestedReference){
            tempValue = this.props.reference?.termMap.de + LangSplitter.splitter + this.props.reference?.termMap.fr + LangSplitter.splitter + this.props.reference?.termMap.it + LangSplitter.splitter + this.props.reference?.termMap.en;
        }
        if (LangSplitter.isMultiLanguageString(this.props.reference)) {
            if (this.props.reference?.split(LangSplitter.splitter).length === 4) {
                tempValue = this.props.reference;
            }
        }

        //tempValue = this.props.reference?.termMap ? tempValue.toUpperCase() : tempValue;
        this.props.addFilter(this.props.item.field, tempValue, this.props.item.filterType, this.props.URLReducer.filters, this.props.configJson.filterMenu,
            !this.props.dateValue ? this.props.reference.term: undefined);
    }

    debounced = (delay) => {
        return () =>{
            clearTimeout(this.state.timer);
            this.setState({timer : setTimeout(() => this.setState({showDetails:false, showPopUp:false}),delay)});
        }
    }
    debouncedRequest = this.debounced(500);


    runRequest = async () => {

        // If the REACT_APP_NETLIFYHOST env is set, we are in production otherwise in dev
        const netlifyHost = process.env.REACT_APP_NETLIFYHOST;

        // The linkString contains the field param values which the linking service needs to perform the forwarding
        const linkString = getLinkStringForLanguage(this.props.reference, this.props.guiLanguage)

        if(linkString.startsWith("http")){
            window.open(linkString,"_blank")
        }else {
            // If we are in dev we need the base url to point to our local netlify server
            const baseURL = netlifyHost ? netlifyHost : "http://localhost:9000"

            // We can set a different base url then netlify server in the gui config
            // If this value is not set, the previously generated baseURL is used
            const finalURL =  this.props?.configJson?.generalOptions?.linkingBaseURL ? this.props.configJson.generalOptions.linkingBaseURL : baseURL + "/.netlify/functions/link/?field="

            // We close the focused label
            this.setState({showDetails:false, showPopUp:false})

            let auth = sessionStorage.getItem("jwtToken");
            // This is added because of a caching issue
            let random = "&r="+Math.random().toString(36).substring(2, 9);

            // We open a new tab with the concatenated url
            window.open(finalURL + linkString + (auth ? "&auth=" + auth : "") +random,"_blank")
        }
    }

    focusLabelAfterMouseEntry = () => {
        this.props.changeFocusedLabel(this.setButtonText(false))
        this.setState({showDetails: true})
        clearTimeout(this.state.timer);
    }



    generateSingleLabel = () => {
        return  <span ref={this.refToAllwaysVisibleElementInLabel}
                      onMouseEnter={()=> this.focusLabelAfterMouseEntry()}
                      onMouseDown={()=> this.focusLabelAfterMouseEntry()} // This is a fix for not being able to click label in mobileview after another label is added
                      onMouseLeave={() => this.debouncedRequest()}
                      style={{display: "inline-block", transform: (this.state.showDetails ? "translateX(" +this.state.translationWidth +"px) scale("+ this.state.scalingFactor+")" : "")}}
                      className={"label-wrapper " + this.props.item.color + (this.state.showDetails ? " label-wrapper-active" : "")}>
                            { this.isPath() ? this.setButtonTextAndShorten() : this.setButtonText(true)} {this.generateOccurrencesString()}
                            <span ref={this.refToHiddenElementInLabel} className={this.props.item.color + " " + (this.state.showDetails ? " display-element" : "")}>
                                <i style={{fontFamily: "Poppins"}}>
                                    {this.props.isCacheView ? "" : this.props.item.label[this.props.guiLanguage]}
                                </i>
                                {!this.props.item.removeAddAsFilterButton && <Icon style={{height:"unset", marginTop: "1.3px", marginLeft: "12px"}}
                                                                            className={"iconCursorPointerCustom"}
                                                                            name={"plus square"}
                                                                            onClick={() => this.applyFilter()}/>}
                                {this.props.reference?.meta && !this.props.item.removeLinkButton && this.props.reference?.meta?.linkString ? <Icon style={{height: "unset", marginLeft: "0px", marginTop: "1.2px"}}
                                                                                className={"iconCursorPointerCustom"}
                                                                                name={"external"}
                                                                                onClick={() => this.runRequest()}/> : null}
                                {this.props.item.nestedReference && !this.props.item.removeLinkButton && hasLinkStringForLanguage(this.props.reference, this.props.guiLanguage)  ?
                                    <Icon style={{height: "unset", marginLeft: "0px", paddingLeft: (this.props.isCacheView ? "0px" : "5px"), marginTop: "1.2px"}}
                                          className={"iconCursorPointerCustom"}
                                          name={"external"}
                                          onClick={() => this.runRequest()}/> : null}
                                {this.props.isCacheView && this.props.observableList[this.props.item.field.replace(/List$/, '')] && this.props.reference?.occurrences?.length>0 && !this.props.jumpPortal
                                    ? <Icon onClick={() => this.openJumpPortal()}
                                                                                                               style={{height:"unset", marginTop: "1.3px", marginLeft: "0px"}}
                                                                                                               className={"iconCursorPointerCustom"}
                                                                                                               name={"step forward"}/> : null}
                            </span>
                </span>
    }


    generatePopUpTrigger = () => {
        if(this.props.item.showAsDashboardTreeFacet){
            let str = '';
            this.props.reference.split('/').forEach(string => {
                if(string){
                    str = str + '/' + string + '<wbr>';
                }
            });
            return  <span onMouseEnter={()=>this.setState({showPopUp: true, showDetails: true})} ref={this.cacheViewComponentRefToMoveThePopUpInPosition}
                          style={{opacity: (this.state.showPopUp ? "0.0" : "1.0")}}
                          className={"label-wrapper " + this.props.item.color}
                          dangerouslySetInnerHTML={{ __html: str}}>
                        {this.generateOccurrencesString()}
                </span>
        } else {
            return  <span onMouseEnter={()=>this.setState({showPopUp: true, showDetails: true})} ref={this.cacheViewComponentRefToMoveThePopUpInPosition}
                          style={{opacity: (this.state.showPopUp ? "0.0" : "1.0")}}
                          className={"label-wrapper " + this.props.item.color}>
                        {this.setButtonText(true)} {this.generateOccurrencesString()}
                </span>
        }
    }



    generateSingleLabelWithPopUp = () => {
        return <Popup style={{padding:"0px",
                              border: "0px",top:(this.cacheViewComponentRefToMoveThePopUpInPosition?.current?.getBoundingClientRect()?.y+window.scrollY),
                              left:  this.cacheViewComponentRefToMoveThePopUpInPosition?.current?.getBoundingClientRect()?.x +"px ",
                              transform: "",
                              borderRadius: "4px" }}
                      basic open={this.state.showPopUp} trigger={this.generatePopUpTrigger()}>
                    <Popup.Content>
                        {this.generateSingleLabel()}
                    </Popup.Content>
                </Popup>
    }



    render() {
        if(this.props.isCacheView && !this.props.mobile)
            return this.generateSingleLabelWithPopUp()

        return this.generateSingleLabel()

    }
}

function mapStateToProps(state) {
    const { LEv4Reducer, URLReducer, CacheViewReducer, ConfigReducer } = state
    const {guiLanguage, URLString} = URLReducer
    const {singleDocumentData, observableList } = CacheViewReducer
    const {labelSorting, focusedLabel, mobile, isCacheView} = LEv4Reducer
    const {configJson, translationsConfig } = ConfigReducer
    return { configJson, translationsConfig, URLReducer, URLString, guiLanguage, singleDocumentData, labelSorting, observableList, focusedLabel, mobile, isCacheView}
}
const mapDispatchToProps = {
    openJumpPortal,
    addFilter,
    changeFocusedLabel
};

export default connect(mapStateToProps,mapDispatchToProps)(SingleLabel);