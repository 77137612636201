import React from "react";
import {connect} from "react-redux";
import { Dimmer, Message, TransitionablePortal} from "semantic-ui-react";
import {closeDuplicateFilterPortal} from "../../actions/FilterActions";


class FilterMessagePortal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    handleOpenPortal = () => {
        var nIntervId =setInterval(()=>{
            this.props.closeDuplicateFilterPortal()
            clearInterval(nIntervId)
        },2500);
    }


    render() {
        return  <>
                    <Dimmer active inverted/>
                    <TransitionablePortal open={true} onOpen={()=>this.handleOpenPortal()} transition={{animation: 'fly down', duration: 1500, open: false}}>
                        <Message color='yellow' size="large" style={{width: '200px', left: '40%', position: 'fixed', top: '5%', zIndex: 1000}}>
                            <Message.Header>
                                {this.props.translationsConfig.filterDuplicateMessage[this.props.guiLanguage]}
                            </Message.Header>
                        </Message>
                    </TransitionablePortal>
                </>
    }
}

function mapStateToProps(state) {
    const { URLReducer, ConfigReducer } = state
    const { guiLanguage } = URLReducer
    const {  configJson, translationsConfig} = ConfigReducer
    return {configJson, translationsConfig, guiLanguage}
}

const mapDispatchToProps = {
    closeDuplicateFilterPortal
};

export default connect(mapStateToProps, mapDispatchToProps) (FilterMessagePortal);