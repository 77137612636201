import React from "react";
import {connect} from "react-redux";
import {Button, Grid, Header, Icon, Loader, Ref, Segment} from "semantic-ui-react";

import HeaderMenuMobile from "./DashboardMobileComponents/HeaderMenuMobile";
import ContentGridMobile from "./DashboardMobileComponents/ContentGridMobile";
import {clearFilters} from "../../../actions/FilterActions";

class DashboardMobile extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        };
        this.contextRef = React.createRef();
    }


    renderLoader = () => {
        if(!this.props.resultsLoaded)
            return <Grid container centered stretched columns={1} style={{height: '75vh'}}>
                <Grid.Column verticalAlign={"middle"}>
                    <Loader inline='centered' active size="large">
                        {this.props.translationsConfig.loading[this.props.guiLanguage]}
                    </Loader>
                </Grid.Column>
            </Grid>

        return null
    }

    renderMainElements = () => {
        if(this.props.resultsLoaded) {
            if(this.props.fetchedDocumentsFromDashboard?.documents.length !==0) {
                return <div style={{marginLeft: "2.5%"}}>
                            <ContentGridMobile />
                       </div>
            }
            return <Segment placeholder basic>
                        <Header icon>
                            <Icon name='search'/>
                            {this.props.translationsConfig.noSearchResults[this.props.guiLanguage]}
                        </Header>
                        <Segment.Inline>
                            <Button onClick={() => window.location.href ="/"} primary>
                                {this.props.translationsConfig.clearQuery[this.props.guiLanguage]}
                            </Button>
                        </Segment.Inline>
                    </Segment>
        }
        return null
    }


    render() {
        return  <>
                    <Ref innerRef={this.contextRef}>
                        <Grid style={{marginTop: "0px"}} columns={1} >
                            <Grid.Column only='mobile' >
                                <HeaderMenuMobile contextRef={this.contextRef}/>
                                {this.renderLoader()}
                                {this.renderMainElements()}
                            </Grid.Column>
                        </Grid>
                    </Ref>
                </>
    }
}

const mapDispatchToProps = {
    clearFilters
};
function mapStateToProps(state) {
    const { URLReducer, DashboardReducer, ConfigReducer } = state
    const {filters, guiLanguage }= URLReducer
    const {fetchedDocumentsFromDashboard, resultsLoaded } = DashboardReducer
    const {configJson, translationsConfig} = ConfigReducer
    return {configJson, translationsConfig, filters, guiLanguage, fetchedDocumentsFromDashboard, resultsLoaded}
}

export default connect(mapStateToProps, mapDispatchToProps)(DashboardMobile);