import {Button, Icon} from "semantic-ui-react";
import React from "react";
import {connect} from "react-redux";
import "../../CacheView/CacheViewComputer/CacheViewComputerComponents/ContentSegmentComputerComponents/weblaw_sidemenu_computer.css"
import {
    changeAllReferenceState,
    openJumpPortal
} from "../../../actions/CacheViewActions";



class ReferenceButtons extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            allOccurrences: []
        }
    }

    openJumpPortal = () => {
        this.props.openJumpPortal(this.state.allOccurrences,undefined, undefined)
    }

    hasActiveReferences = () => {
        return Object.entries(this.props.observableList).filter(([key, value]) => value && key in this.props.nestedMetadataMap).length > 0
    }

    render() {
        this.state.allOccurrences.length = 0
        if(this.props.nestedMetadataMap) {
            Object.entries(this.props.nestedMetadataMap).forEach(([key, value]) => {
                if (JSON.parse(sessionStorage.getItem('turnOnReference' + key))) {
                    value.forEach((element) => element.occurrences.forEach((occ) => this.state.allOccurrences.push(occ)))
                }
            })
            this.state.allOccurrences.sort(function (a, b) {
                return parseInt(a.split(",")[0]) - parseInt(b.split(",")[0])
            });
        }



        return  <div style={{marginBottom: "15px"}}>
                    <div id={"switchShowMarkingContainer"}>
                        <Button id={!this.hasActiveReferences() ? "activeMarkingButton" : "notActiveMarkingButton"}
                                basic
                                size={"huge"}
                                onClick={() => this.props.changeAllReferenceState(false, this.props.observableList, this.props.URLReducer, this.props.configJson)}>
                            <Icon name={"eye slash"}/>
                        </Button>
                        <Button id={this.hasActiveReferences() ? "activeMarkingButton" : "notActiveMarkingButton"}
                                size={"huge"}
                                basic
                                onClick={() => this.props.changeAllReferenceState(true, this.props.observableList, this.props.URLReducer, this.props.configJson)}>
                            <Icon name={"eye"}/>
                        </Button>
                    </div>
                    <div id={"stepForwardIconContainer"}>
                        <Button disabled={!this.hasActiveReferences()}
                                size={"huge"} id={"stepForwardIconButton"}
                                onClick={() => this.openJumpPortal()}>
                            <Icon.Group style={{marginRight: "-4px", opacity: "1"}} size={"large"}>
                                <Icon name={"file alternate outline"}/>
                                <Icon corner name={"search"}/>
                            </Icon.Group>
                        </Button>
                    </div>
                </div>
    }
}

function mapStateToProps(state) {
    const { ConfigReducer, URLReducer, CacheViewReducer } = state;
    const { configJson} = ConfigReducer;
    const { singleDocumentData, observableList }= CacheViewReducer
    const nestedMetadataMap = singleDocumentData.nestedMetadataMap;
    return {observableList, nestedMetadataMap, URLReducer, configJson};

}

const mapDispatchToProps = {
    changeAllReferenceState,
    openJumpPortal
};

export default connect(mapStateToProps, mapDispatchToProps)(ReferenceButtons);