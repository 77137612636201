export function getReferenceFromOccurrence(filterMenu, nestedMetadataMap, occurrence) {
    let filterOption;
    let reference;

    for (const [key] of Object.entries(nestedMetadataMap)) {
        //this lines takes the occurrences and finds the nestedRef
        reference = nestedMetadataMap[key].find((nestedRef) => nestedRef.occurrences.some((occurrenceForNestedRef) => occurrenceForNestedRef.includes(occurrence)));
        if(reference) {
            // if a nestedRef is found, we search get the filterOption for the filterMenu json
            filterOption = filterMenu.find((element) => element.field.replace(/List$/, '') === key);
            return {reference, filterOption}
        }
    }

}