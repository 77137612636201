import React from "react";
import {Link} from "react-router-dom";
import {
    Accordion, Grid,
    Header,
    Icon,
    Item, Label
} from 'semantic-ui-react'
import {connect} from "react-redux";
import MarkedSearchTermDashboardComputer from "./ResultItemComputerComponents/MarkedSearchTermDashboardComputer";
import {
    createReactJSXFromString,
    sanitizeHTMLStringAndReturnCleanString
} from "../../../../../../../services/ReactAndHTMLParserService";
import LabelArray from "../../../../../../sharedComponents/Label/LabelArray";
import OriginalLink from "../../../../../../sharedComponents/OriginalLink";
import {handleDashboardClick} from "../../../../../../../actions/DashboardAction";
import "./weblaw_resultitem_computer.css"

import {fetchCustomDocPost} from "../../../../../../../actions/CacheViewActions";
import SingleLabel from "../../../../../../sharedComponents/Label/LabelArrayComponents/SingleLabel";
const CryptoJS = require("crypto-js");

//The Function Below To Decrypt Text
const decryptWithAES = (ciphertext) => {
    if(ciphertext){
        const passphrase = localStorage.getItem("sec");
        if (passphrase){
            const bytes = CryptoJS.AES.decrypt(ciphertext, passphrase);
            let originalText;
            try {
                originalText = bytes.toString(CryptoJS.enc.Utf8);
            } catch (error) {
                console.error("WrongPW");
            }
            return originalText;
        }

    }
    return ciphertext;
};

//The Function Below To Encrypt Text
const encryptWithAES = (text) => {
    const passphrase = localStorage.getItem("sec");
    return encodeURIComponent(CryptoJS.AES.encrypt(text, passphrase).toString()).toString();
};



class ResultItemComputer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            link: "/cache?id=" + this.props.result.leid + "&",
            active: this.showMoreLabelsButtonIsSet() ? false: true,
        };
    }



    createMetadataLabels = (item,index) => {
        if (item.showAsLabelInResultComponent ) {
            var fieldName = item.field.replace(/List$/, '');
            var elementsArray;

            if (this.props.result.nestedMetadataMap[fieldName]) {
                item.nestedReference =true
                elementsArray = this.props.result.nestedMetadataMap[fieldName]
            }
            else if(Array.isArray(this.props.result.metadataKeywordTextMap[fieldName])) {
                item.metadataKeywordTextMap =true
                elementsArray = this.props.result.metadataKeywordTextMap[fieldName]
            }
            else if(fieldName in this.props.result.nestedMetadataMap) {
                item.nestedMetadataMap =true
                elementsArray = this.props.result.nestedMetadataMap[fieldName]
            }

            // If array of paths, reduce to the longest path E.g. in treeView
            if(item.showAsDashboardTreeFacet){
                elementsArray = [ elementsArray.reduce((a, b) => { return a.length > b.length ? a : b }) ];
            }

            if(elementsArray) {
                return <LabelArray key={index} elementsArray={elementsArray} item={item}/>
            } else {
                return this.props.result.metadataDateMap[fieldName]  ? <SingleLabel key={index} item={item} dateValue={this.props.result.metadataDateMap[fieldName]}/>: null
            }
        }

        return null;
    }

    showMoreLabelsButtonIsSet = () => {
        return this.props.configJson.generalOptions.showMoreLabelsButton && JSON.parse(this.props.configJson.generalOptions.showMoreLabelsButton)
    }

    handleLinkClick = () => {
        this.props.fetchCustomDocPost(this.props.result.leid, this.props.guiLanguage, this.props.configJson, this.props.URLReducer, this.props.fetchedDocumentsFromDashboard.searchResultDocumentIdList)
        this.props.handleDashboardClick(window.scrollY)
    }

    hasUpperLabels = (upperLabelList) => {
        return this.props.configJson.filterMenu
            .filter((element) => upperLabelList.includes(element.field)).length>0
    }

    stickDownloadIconToTitle = () => {
        return this.props.configJson.generalOptions.stickDownloadButtonsToTitle && JSON.parse(this.props.configJson.generalOptions.stickDownloadButtonsToTitle)
    }


    generateDownloadIcons = () => {
         return  this.props.configJson.documentLinkButtons
                    .filter(element => this.props.result.metadataKeywordTextMap[element.field])
                    .map((element, index)  => <OriginalLink key={index} originalURL={this.props.result.metadataKeywordTextMap[element.field]} iconString={element.icon} /> )
    }

    generateNewTabIcon = () => {
        let auth = (sessionStorage.jwtToken?"&jwt="+encryptWithAES(sessionStorage.jwtToken):"")
        return this.props.configJson.generalOptions.showNewTabIcon && <OriginalLink originalURL={[this.state.link + (this.props.URLString ? this.props.URLString.replace("?", '') : "") + auth]} iconString={"external square alternate"} />
    }

    render() {
        var headerHTML;
        if(this.props.result.metadataKeywordTextMap.title)
            headerHTML = this.props.result.metadataKeywordTextMap.title
        else if(this.props.result.metadataKeywordTextMap.fileName)
            headerHTML = this.props.result.metadataKeywordTextMap.fileName
        else
            headerHTML = this.props.result.metadataKeywordTextMap.leid ? this.props.result.metadataKeywordTextMap.leid : "no leid"

        var upperLabelList;
        var belowTitleLabelList;

        if(this.props.configJson?.filterMenu)
            upperLabelList = this.props.configJson.filterMenu.filter(element => element.showBeforeTitleInResultComponent).map(element => element.field)

        if(this.props.configJson?.filterMenu)
            belowTitleLabelList = this.props.configJson.filterMenu.filter(element => element.showBelowTitleInResultComponent).map(element => element.field)

        var cleanContentHTMLString = sanitizeHTMLStringAndReturnCleanString(this.props.result.content.toString());

        var cleanHeaderHTMLString = sanitizeHTMLStringAndReturnCleanString(headerHTML.toString());

        var replaceEmHTMLByReactComponentCallback = (domNode) => {
            if (domNode && domNode.name === 'em') {
                var textChild = domNode.children.find((value) => value.type === "text")
                return <MarkedSearchTermDashboardComputer text={textChild.data}/>;
            }
        }
        function getKeyFromLanguage(lang, key) {
            if(String(key).split(";;").length===4) {
                let i = 0;
                if (lang === "de") {
                    i = 0;
                } else if (lang === "fr") {
                    i = 1;
                } else if (lang === "it") {
                    i = 2;
                } else if (lang === "en") {
                    i = 3;
                }
                return String(key).split(";;")[i];
            }else{
                return key;
            }
        }
        var parsedJSXContent = createReactJSXFromString(getKeyFromLanguage(this.props.guiLanguage,cleanContentHTMLString.replace(/_/g, '_<wbr/>')), replaceEmHTMLByReactComponentCallback);

        var parsedJSXHeader = createReactJSXFromString(getKeyFromLanguage(this.props.guiLanguage, cleanHeaderHTMLString).replace(/_/g, '_<wbr/>').replace(/\|/g, '|<wbr/>'), replaceEmHTMLByReactComponentCallback);
        this.generateDownloadIcons()
        return  <Item id={"scrollerItem"} className={"infiniteScrollerItem"} key={this.props.index}>
                    <Item.Content verticalAlign='middle'>
                        <Item.Description >
                            <Grid>
                                <Grid.Column width={14}>
                                    <div style={{display: "flex", flexWrap: "wrap", alignItems: "flex-start", gap: "5px"}}>
                                {this.props.configJson.filterMenu
                                    .filter((element) => upperLabelList.includes(element.field))
                                    .map((item,index) => this.createMetadataLabels(item, index))}
                                        {this.stickDownloadIconToTitle() ? this.generateDownloadIcons() : null}
                                        <Link onClick={() =>this.handleLinkClick()} to={this.state.link + (this.props.URLString ? this.props.URLString.replace("?", '') : "")}>
                                            <Header style={{"marginLeft": (this.hasUpperLabels(upperLabelList)? "18px": "")}}
                                                    className={"infiniteScrollerItemHeader " + (this.props.markedLeids?.includes(this.props.result.leid) ? "markedLink" :"")}
                                                    as={"h2"}>
                                                {parsedJSXHeader}
                                            </Header>
                                        </Link>
                                    </div>
                                </Grid.Column>
                                <Grid.Column width={2}>
                                    {this.generateNewTabIcon()}
                                    {!this.stickDownloadIconToTitle() ? this.generateDownloadIcons() : null}
                                </Grid.Column>
                                {belowTitleLabelList.length > 0 && <Grid.Column width={16} style={{paddingTop: "0rem"}}>
                                    <div style={{display: "flex", flexWrap: "wrap", alignItems: "flex-start", gap: "5px"}}>
                                        {this.props.configJson.filterMenu
                                            .filter((element) => belowTitleLabelList.includes(element.field))
                                            .map((item,index) => this.createMetadataLabels(item, index))}
                                    </div>
                                </Grid.Column>}
                            </Grid>
                        </Item.Description>
                        <Item.Description className={"infiniteScrollerItemDescription"}>
                            {parsedJSXContent}
                        </Item.Description>
                        <Accordion style={{borderTop : "1px solid #F1F1F1", marginTop: "9.5px", marginBottom: "9.5px"}} as={Item.Extra} fluid exclusive={false}>
                            <Accordion.Title style={{cursor: "auto", display: this.showMoreLabelsButtonIsSet() ? "": "none"}} active={this.state.active} index={0}>
                                <Label className={"infiniteScrollerItemLabel"} onClick={() => this.setState({active : !this.state.active})}>
                                    <Header className={"infiniteScrollerItemSubHeader"}>
                                        {this.props.translationsConfig.moreLabels ? this.props.translationsConfig?.moreLabels[this.props.guiLanguage] : ""}
                                    </Header>
                                    <Icon size={"small"} name={this.state.active ? "minus" : 'plus'}/>
                                </Label>
                            </Accordion.Title>
                            <Accordion.Content active={this.state.active}>
                                <div style={{display: "flex", flexWrap: "wrap", alignItems: "flex-start", gap: "5px"}}>
                                    {this.props.configJson.filterMenu
                                        .filter((element) => !upperLabelList.includes(element.field))
                                        .filter((element) => !belowTitleLabelList.includes(element.field))
                                        .map((item,index) => this.createMetadataLabels(item, index))}

                                </div>
                            </Accordion.Content>
                        </Accordion>
                    </Item.Content>
                </Item>
    }
}

function mapStateToProps(state) {
    const { URLReducer, DashboardReducer, ConfigReducer } = state
    const {fetchedDocumentsFromDashboard,markedLeids } = DashboardReducer
    const {guiLanguage, URLString} = URLReducer
    const {configJson, translationsConfig } = ConfigReducer
    return { URLString, configJson, markedLeids, translationsConfig, guiLanguage, URLReducer,fetchedDocumentsFromDashboard}
}

const mapDispatchToProps = {
    handleDashboardClick,
    fetchCustomDocPost
};


export default connect(mapStateToProps, mapDispatchToProps)(ResultItemComputer);