import React from "react";
import {Button, Label, Portal, Segment} from "semantic-ui-react";
import {closeJumpPortal, focusOccurrence} from "../../../actions/CacheViewActions";
import {connect} from "react-redux";
import * as Scroll from 'react-scroll';
import {getReferenceFromOccurrence} from "../../../services/ReferenceService";
import SingleLabel from "../../sharedComponents/Label/LabelArrayComponents/SingleLabel";


class JumpPortal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            scroller: Scroll.scroller,
            currentOccurrence: 0,
            resolvedReference: "",
            init: false
        }
        this.jumpToNextOccurrence = this.jumpToNextOccurrence.bind(this)
    }

    componentDidMount() {
        this.scrollToWrapper(this.state.currentOccurrence);
        if(this.props.mainLabel )
            this.setState({resolvedReference:this.props.occurrences});
        else
            this.setState({resolvedReference: getReferenceFromOccurrence(this.props.configJson.filterMenu,this.props.singleDocumentData.nestedMetadataMap, this.props.occurrences[0])});

        window.addEventListener('keyup', this.handleKeyUp);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        // If the occurrences array changes (comes from the cacheview reducer) it means, that a reference was activated or deactivated
        // If this happens we reset the jump portal with the new values
        if(this.props.occurrences !== prevProps.occurrences) {
            this.setState({currentOccurrence: 0})
            this.scrollToWrapper(0);
            // Either we are using the jump portal to focus nested references
            if (!this.props.mainLabel && this.props.labelText !== "")
                this.setState({resolvedReference: getReferenceFromOccurrence(this.props.configJson.filterMenu,this.props.singleDocumentData.nestedMetadataMap, this.props.occurrences[this.state.currentOccurrence])});
            // Or to focus the marked search term
            if(this.props.mainLabel )
                this.setState({resolvedReference: this.props.occurrences});
        }
    }

    componentWillUnmount() {
        window.removeEventListener('keyup', this.handleKeyUp)
    }

    handleKeyUp = (event) => {
        switch (event.key) {
            case "ArrowUp": this.closeJumpPortal();
                break;
            case "ArrowLeft": this.jumpToPreviousOccurrence();
                break;
            case "ArrowRight": this.jumpToNextOccurrence();
                break;
            default:
                return;
        }
    }


    scrollToWrapper = (elementNumber) => {
        this.state.scroller.scrollTo(this.props.occurrences[elementNumber], {
            duration: 500,
            delay: 100,
            smooth: true,
            offset: -100
        })
        this.props.focusOccurrence(this.props.occurrences[elementNumber])
    }

    closeJumpPortal =() => {
        Scroll.animateScroll.scrollToTop();
        this.props.closeJumpPortal()

    }

    jumpToNextOccurrence = () => {
        var nextOccurrence = (this.state.currentOccurrence +1) % this.props.occurrences.length
        this.scrollToWrapper(nextOccurrence);
        this.setState({resolvedReference: getReferenceFromOccurrence(this.props.configJson.filterMenu,this.props.singleDocumentData.nestedMetadataMap, this.props.occurrences[((this.state.currentOccurrence +1) % this.props.occurrences.length)])});
        this.setState({currentOccurrence : ((this.state.currentOccurrence +1) % this.props.occurrences.length)})
    }

    jumpToPreviousOccurrence = () => {
        var previousOccurrence = this.state.currentOccurrence>0 ? this.state.currentOccurrence - 1 : this.props.occurrences.length-1
        this.scrollToWrapper(previousOccurrence);
        this.setState({resolvedReference: getReferenceFromOccurrence(this.props.configJson.filterMenu,this.props.singleDocumentData.nestedMetadataMap, this.props.occurrences[((this.state.currentOccurrence +1) % this.props.occurrences.length)])});
        this.setState({currentOccurrence: (this.state.currentOccurrence>0 ? this.state.currentOccurrence - 1 : this.props.occurrences.length-1)})
    }

    render() {
        return  <Portal open>
                    <Segment style={{left: '40%', position: 'fixed', top: '40%', zIndex: 1000}}>
                        <Button icon='arrow left' onClick={this.jumpToPreviousOccurrence}/>
                        <Button icon='arrow right' onClick={this.jumpToNextOccurrence}/>
                        <Button icon='arrow up' onClick={this.closeJumpPortal}/>
                        <Button icon="close" onClick={() => this.props.closeJumpPortal()}/>
                        {this.state.resolvedReference &&  !this.props.mainLabel  ?
                            <SingleLabel reference={this.state.resolvedReference.reference}
                                         item={this.state.resolvedReference.filterOption}
                                         jumpPortal={true}/> : null}
                        {!this.state.resolvedReference && !this.props.mainLabel ?
                            <Label className={"wlclight8"}>
                                {this.props.URLReducer.searchTerm}
                                <Label.Detail>
                                    ({this.props.numberOfMarkedWords})
                                </Label.Detail>
                            </Label> : null}
                        {this.state.resolvedReference && this.props.mainLabel ?
                            <Label color={this.props.configJson.generalOptions.searchTermHighlightedColor}>
                                {this.state.resolvedReference.reference.label}
                                <Label.Detail>
                                    ({this.state.resolvedReference.length})
                                </Label.Detail>
                            </Label> : null}
                    </Segment>
                </Portal>
    }

}
const mapDispatchToProps = {
    closeJumpPortal,
    focusOccurrence
};

function mapStateToProps(state) {
    const { ConfigReducer, URLReducer, CacheViewReducer } = state
    const { configJson, translationsConfig, } = ConfigReducer
    const { singleDocumentData, occurrences, labelText, mainLabel, numberOfMarkedWords }= CacheViewReducer
    return { configJson, translationsConfig, labelText, occurrences, singleDocumentData, numberOfMarkedWords, URLReducer, mainLabel}
}

export default connect(mapStateToProps, mapDispatchToProps)  (JumpPortal);