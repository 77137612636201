import { combineReducers } from 'redux';
import LEv4Reducer from './LEv4Reducer';
import CacheViewReducer from "./CacheViewReducer";
import URLReducer from "./URLReducer";
import DashboardReducer from "./DashboardReducer";
import ConfigReducer from "./ConfigReducer";



export default combineReducers({
   LEv4Reducer,
   CacheViewReducer,
   URLReducer,
   DashboardReducer,
   ConfigReducer
});
