import React, { Component } from "react";
import buildCustomRequest from "../../../../../../../../services/BuildCustomRequest";
import {connect} from "react-redux";
import {addFilter, removeFilter} from "../../../../../../../../actions/FilterActions";
import {logoutUser} from "../../../../../../../../actions/AuthActions";
import {
    Accordion,
    Checkbox,
    Grid,
    Icon, Input,
    Label, List, Loader,
    Menu,
} from "semantic-ui-react";
import CheckboxTree from 'react-checkbox-tree';


import "./weblaw_multicheckboxtreefacet_computer.css"
import 'react-checkbox-tree/lib/react-checkbox-tree.css';

import {changeAggregationsValues} from "../../../../../../../../actions/DashboardAction";
import {LangSplitter} from "../../../../../../../../services/LangSplitter";

const nodes = [];

const mapDispatchToProps = {
    logoutUser,
    addFilter,
    removeFilter,
    changeAggregationsValues
};

class MultiCheckboxTreeFacetComputer extends Component {
    constructor(props) {
        super(props)
        this.state = {
            checked: [],
            expanded: [],
            filterText: '',
            filterNoResults: false,
            nodesFiltered: nodes,
            nodes,
            showLoader: false,
            showLessButton: false,
            showMore: false,
            inputTerm: "",
            hasMoreResults: true,
            active: this.props.item.accordionInitialState,
            showTimeoutMessage: false,
            currentArrayLength: this.props.item.initialFacetLength
        }

        this.getFiltered = this.getFiltered.bind(this);
        this.renderFromResponse = this.renderFromResponse.bind(this);
        this.onCheck = this.onCheck.bind(this);
        this.onExpand = this.onExpand.bind(this);
        this.onFilterChange = this.onFilterChange.bind(this);
        this.filterTree = this.filterTree.bind(this);
        this.filterNodes = this.filterNodes.bind(this);

        this.handleRequest(false).then(_ => {
            this.accordionOnReload();
        });

    }

    onCheck(checked) {
        this.setState({ checked });
    }

    onExpand(expanded) {
        this.setState({ expanded });
    }

    onFilterChange(e) {
        this.setState({ filterText: e.target.value }, this.filterTree);
    }

    handleRequest = async (isFromShowMoreButton, expandedPath?, wildCardString?) => {
        localStorage.setItem('update', 'true');
        var body = buildCustomRequest(this.props.URLReducer, this.props.configJson, "Dashboard");

        body.chosenPaths = [];
        const queryParams = this.props.URLReducer?.filters?.find((f) => f.field === this.props.item.field && f.filterType === this.props.item.filterType);
        if(queryParams && queryParams.values){
            body.chosenPaths = body.chosenPaths.concat(queryParams.values);

            // This foreach gives all parent elements
            // Necessary for getting all subfolders of chosen paths
            const arr = [];
            this.getAllPathsOfLeafNodes(body.chosenPaths, arr);

            body.chosenPaths = [...new Set(arr)];
        }
        if(expandedPath){
            body.chosenPaths = body.chosenPaths.concat(this.state.expanded);
            body.chosenPaths.push(expandedPath);
            body.chosenPaths = [...new Set(body.chosenPaths)];
        }
        if(wildCardString){
            body.wildCardSearchString = wildCardString;
        }

        var displaySize = (isFromShowMoreButton ? this.state.currentArrayLength + parseInt(this.props.configJson.generalOptions.showMoreFilters)  : this.props.item.initialFacetLength);
        if(displaySize === 0)
            displaySize = 6

        body.size = displaySize + 1;

        try {
            await this.runCustomTreeRequest(body, this.props.item.field).then((response) => {
                this.state.nodes = response.nodes;
                this.state.nodesFiltered = response.nodes;

                this.props.changeAggregationsValues(response.fieldAggregations,this.props.item.field);
                this.setState({currentArrayLength: displaySize});
                this.setState({hasMoreResults: Object.entries(response.fieldAggregations).length >= displaySize+ 1});
                this.setState({showLessButton: isFromShowMoreButton });
                this.setState({showLoader: false});
            });
        } catch (error) {
            this.setState({showTimeoutMessage: true})
        }
    }

    runCustomTreeRequest = async(body, field) => {
        body.auth = sessionStorage.getItem("jwtToken");
        var netlifyHost = process.env.REACT_APP_NETLIFYHOST;
        if (netlifyHost === undefined)
            netlifyHost = "";
        const controller = new AbortController();
        var { timeout = 10000 } = this.props.configJson.generalOptions;
        const id = setTimeout(() => controller.abort(), timeout);
        const response = await fetch(netlifyHost + "/.netlify/functions/searchTreeService?field=" + field, {
            method: "POST",
            body: JSON.stringify(body),
            mode: 'no-cors',
            signal: controller.signal
        });

        clearTimeout(id)
        if(response.status === 401) {
            this.props.logoutUser();
            window.location.reload();
        }
        return response.json();
    }

    getSubFoldersOfNode(expandedNode) {
        if(expandedNode.children.length === 0){
            this.handleRequest(false, expandedNode.value).then(_ => {
                const itemsInSearch = this.props.URLReducer?.filters?.find((f) => f.field === this.props.item.field && f.filterType === this.props.item.filterType);
                if(itemsInSearch){
                    this.checkOnReload();
                }
            });
        }
    }

    timer = null;
    filterTree() {
        // Reset nodes back to unfiltered state
        if (!this.state.filterText) {
            this.setState({ expanded: [] });
            this.setState({filterNoResults: false });
            return;
        }

        clearTimeout(this.timer);
        this.timer = setTimeout(_ => this.handleFilterRequest(), 500);
    }

    // Handles request of search bar above tree
    handleFilterRequest(){
        this.setState({showLoader: true});
        this.setState({expanded: []});
        this.handleRequest(false, '', this.state.filterText).then(_ => {
            const leafNodes = this.getAllLeafNodes(this.state.nodes).map(leaf => {
                return leaf.value;
            });

            // Checks if filter even returns value
            this.setStateIfFilterHasNoResults();

            // Expands all leaf nodes and its parents
            let leafNodesWithParents = [];
            this.getAllPathsOfLeafNodes(leafNodes, leafNodesWithParents);
            const noDuplicates = [...new Set(leafNodesWithParents)];
            this.setState({expanded: noDuplicates});

            this.setState({showLoader: false});

            this.scrollTreeToTheLeft();
        });
    }

    // Checks if filter even returns value
    setStateIfFilterHasNoResults() {
        if(this.state.nodes && this.state.filterText){
            this.setState({filterNoResults: true});
            this.state.nodes.forEach(node => {
                if(node.children.length > 0)
                {
                    this.setState({filterNoResults: false});
                }
            });
        } else {
            this.setState({filterNoResults: false});
        }
    }

    scrollTreeToTheLeft() {
        const HTMLTree = document.getElementsByClassName('react-checkbox-tree');
        if(HTMLTree[0]){
            HTMLTree[0].scrollTo(-1000, 0);
        }
    }

    // Returns all parent paths of leaf node array E.g. ([/foo/bar/1, test/1/2] => [/foo/bar, /foo, /test/1, /test])
    // Empty return array needs to be provided since the function calls itself
    getAllPathsOfLeafNodes(leafNodes, returnArray) {
        leafNodes.forEach(element => {
            const split = element.split('/');

            returnArray.push(element);
            split.forEach((element, index) => {
                const slicedStr = split.slice(0, -index).join('/');
                if (slicedStr && this.state.expanded.indexOf(slicedStr) <= 0) {
                    returnArray.push(slicedStr);
                }
            });
        });
    }

    filterNodes(filtered, node) {
        const { filterText } = this.state;
        const children = (node.children || []).reduce(this.filterNodes, []);

        if (
            // Node's label matches the search string
            node.label.toLocaleLowerCase().indexOf(filterText.toLocaleLowerCase()) > -1 ||
            // Or a children has a matching node
            children.length
        ) {
            filtered.push({ ...node, children });
        }

        return filtered;
    }

    getFiltered(localStorageParse) {
        const resultArray = [];
        Object.entries(localStorageParse).forEach(([key, value]) => resultArray.push({ value: key, count: localStorageParse[key], selected: false }))
        return resultArray;
    }

    generateCheckBoxLabel = (key) => {
        return  <label className={"customLabelStyle"}>
                    <div style={{marginLeft : "10.5px"}}>
                        {this.getFilterValueDisplay(key)}
                    </div>
                </label>
    }

    handleCheckBoxChange = (checked, key) => {
        if (this.props.URLReducer?.filters?.find((f) => f.field === this.props.item.field && f.filterType === this.props.item.filterType)){
            const shortestPaths = this.getShortestSearchBarItems(key);
            const filters = this.props.filters;
            const index = filters.findIndex(element => {return element.field === this.props.item.field;});
            shortestPaths.splice(shortestPaths.indexOf(key), 1);
            filters[index].values = shortestPaths;
        }

        if(checked){
            this.props.removeFilter(this.props.item.field, key, this.props.item.filterType, this.props.filters);
        }
        else {
            this.props.addFilter(this.props.item.field, key, this.props.item.filterType, this.props.filters, this.props.configJson.filterMenu, this.props.item.label)
        }
    }

    initCheckBoxChange(checkbox){
        // Check if already in filter
        let isInSearchBar = false;
        let isNegativeAndInSearch = false;
        if (this.props.URLReducer?.filters?.find((f) => f.field === this.props.item.field && f.filterType === this.props.item.filterType)){
            const searchedItems = this.props.URLReducer?.filters?.find((f) => f.field === this.props.item.field && f.filterType === this.props.item.filterType).values
            isInSearchBar = searchedItems.includes(checkbox.value) || searchedItems.includes('!!' + checkbox.value);
            isNegativeAndInSearch = searchedItems.includes('!!' + checkbox.value);
        }

        if((!checkbox.checked && !isInSearchBar) || isNegativeAndInSearch){
            this.handleCheckBoxChange(isInSearchBar, '!!' + checkbox.value);
        } else {
            this.handleCheckBoxChange(isInSearchBar, checkbox.value);
        }
    }

    renderFromResponse(key,value,index) {
        var checked = (this.props.URLReducer?.filters?.find((f) => f.field === this.props.item.field && f.filterType === this.props.item.filterType))?.values?.includes(key);

        return  <Menu.Item className={"menuItemCustomStyle"} fitted key={index}>
                    <Grid.Column width={12} floated="left">
                        <Checkbox className={"checkBoxCustomStyle"} checked={checked} label={this.generateCheckBoxLabel(LangSplitter.getKeyFromLanguage(this.props.guiLanguage, key))} onChange={() => this.handleCheckBoxChange(checked, key)}/>
                    </Grid.Column>
                    <Grid.Column className={"countCustomStyle"} width={4} floated="right" textAlign="right">
                        {value}
                    </Grid.Column>
                </Menu.Item>
    }

     getFilterValueDisplay = (filterValue) => {
        if (filterValue === undefined || filterValue === null) return "";
        if (filterValue.hasOwnProperty("name")) return filterValue.name;
        return String(filterValue);
    }

    handleAccordionStateChange = () => {
        this.props.item.accordionInitialState = !this.state.active
        this.setState({active : !this.state.active})
    }

    // Checks Tree, expands Tree, the shortest Paths in search bar
    accordionOnReload() {
        // Ignore this function if no filters are set
        if (this.props.URLReducer?.filters?.find((f) => f.field === this.props.item.field && f.filterType === this.props.item.filterType)){
            this.expandOnReload();
            this.checkOnReload();
        }
    }

    // Filters out unnecessary filters in search bar
    getShortestSearchBarItems(addFilter?){
        const setFilters = this.props.URLReducer?.filters?.find((f) => f.field === this.props.item.field && f.filterType === this.props.item.filterType).values;
        if(addFilter){
            setFilters.push(addFilter);
        }
        const sortedFilters = setFilters.sort();
        let lastMatchedIndex = 0;
        return sortedFilters.filter((element, index, array) => {
            if(array[index - 1]){
                if (!element.includes(array[index - 1]) && !element.includes(array[lastMatchedIndex])){
                    lastMatchedIndex = index;
                    return true
                }
            } else {
                return true
            }
            return false
        });
    }

    expandOnReload(){
        this.state.expanded = this.props.URLReducer?.filters?.find((f) => f.field === this.props.item.field && f.filterType === this.props.item.filterType).values;

        const arr = [];
        this.getAllPathsOfLeafNodes(this.state.expanded, arr);

        this.setState({expanded: arr});
    }

    checkOnReload() {
        const itemsInSearch = this.props.URLReducer?.filters?.find((f) => f.field === this.props.item.field && f.filterType === this.props.item.filterType).values;

        // Iterates through all root nodes and through all items in search bar
        this.state.nodes.forEach(element => {
            for(let i = 0; i < itemsInSearch.length; i++){
                // Given is a string. Returns node where string is node.value
                const parentNode = this.findNode(itemsInSearch[i], element);
                if(parentNode){
                    this.setLeafNodesAsChecked(parentNode);
                }
            }
        });
    }

    findNode(value, currentNode) {
        var i,
            currentChild,
            result;

        if (value === currentNode.value) {
            return currentNode;
        } else {
            if(currentNode.children && currentNode.children.length > 0){
                for (i = 0; i < currentNode.children.length; i += 1) {
                    currentChild = currentNode.children[i];

                    // Search in the current child
                    result = this.findNode(value, currentChild);

                    // Return the result if the node has been found
                    if (result !== false) {
                        return result;
                    }
                }
            }
            // The node has not been found and we have no more options
            return false;
        }
    }

    leafNodes = [];
    setLeafNodesAsChecked(node) {
        if(node.children && node.children.length > 0){
            for (var i = 0; i < node.children.length; i++) {
                const child = node.children[i];

                const checkedItems = this.props.URLReducer?.filters?.find((f) => f.field === this.props.item.field && f.filterType === this.props.item.filterType).values;
                if(!checkedItems.includes('!!' + node.children[i].value)){
                    this.setLeafNodesAsChecked(child);
                }
            }
        } else {
            this.leafNodes.push(node.value);
            this.setState({checked: this.leafNodes});
        }
    }

    getAllLeafNodes(nodes, result = []) {
        for(var i = 0, length = nodes.length; i < length; i++){
            if(!nodes[i].children || nodes[i].children.length === 0){
                result.push(nodes[i]);
            }else{
                result = this.getAllLeafNodes(nodes[i].children, result);
            }
        }
        return result;
    }

    render() {
        const {
            checked,
            expanded,
            nodesFiltered,
        } = this.state;

        return  <Grid.Row className={"generalGridRow " + (this.state.active ? "paddingBottomGrindRowActivated" : "paddingBottomGrindRowDeactivated")}>
                    <Accordion.Title className={"accordionTitle"} index={this.props.index} active={this.state.active} onClick={() =>this.handleAccordionStateChange()}>
                        <Label className={"accordionLabelSideContent"}>
                            <span className={"accordionHeaderSideContent"} >
                                <Grid>
                                    <Grid.Column width={3}>
                                        <Icon style={{fontSize:"0.8em", marginLeft: "20px", marginRight: "21px"}} className={this.props.item.color +"Dot"} size={"mini"} name={"circle"}/>
                                    </Grid.Column>
                                    <Grid.Column width={13}>
                                        <span style={{textAlign: "center"}}>
                                            {this.props.item.label[this.props.guiLanguage]}
                                        </span>
                                    </Grid.Column>
                                </Grid>
                            </span>
                            <Icon size={"large"} className={"accordionIconSideContent"} name={this.state.active ?"angle down" :'angle up'}/>
                        </Label>
                    </Accordion.Title>
                    <Accordion.Content active={this.state.active}>
                        <div style={{marginLeft: "42px", marginRight: "42px", marginTop : "19px"}}>
                                <div className="filter-container">
                                    {this.props.item.facetSearchable ?
                                        <List.Item>
                                            <Input style={{width: "100%", paddingBottom : "14px"}} size='small' icon iconPosition='left'
                                                   defaultValue={this.state.inputTerm && this.state.inputTerm}
                                                   placeholder={this.props.translationsConfig.search[this.props.guiLanguage] || "Search"}
                                                   onChange={this.onFilterChange}>
                                                <Icon style={{marginLeft : "10px", height: "35px"}} size='small' name='search' />
                                                <input id={"searchBoxButtonSideContent"} type="text" />
                                            </Input>
                                        </List.Item> : null}
                                    { !this.state.showLoader && !this.state.filterNoResults ? <CheckboxTree
                                        icons={{
                                            check: <Icon className="check square outline" />,
                                            uncheck: <Icon className="square outline" />,
                                            halfCheck: <Icon className="square disabled" />,
                                            expandClose: <Icon className="caret right" />,
                                            expandOpen: <Icon className="caret down" />,
                                            expandAll: <Icon className="angle double down" />,
                                            collapseAll: <Icon className="angle double up" />,
                                            parentClose: <Icon className="folder outline" />,
                                            parentOpen: <Icon className="folder open outline" />,
                                            leaf: <Icon className="folder outline" />
                                        }}
                                        checkModel='all'
                                        checked={checked}
                                        expanded={expanded}
                                        nodes={nodesFiltered}
                                        onCheck={(checked, node) => {
                                            this.initCheckBoxChange(node);
                                            this.onCheck(checked);
                                        }}
                                        onExpand={(expand, onExpandNode) => {
                                            this.getSubFoldersOfNode(onExpandNode);
                                            this.onExpand(expand)
                                        }}
                                    /> : null}
                                </div>

                            {!this.state.showLoader && this.state.filterNoResults ?
                                <p>
                                    {this.props.translationsConfig.noMatchingOptions[this.props.guiLanguage]}
                                </p>: null}
                        </div>

                        {this.state.showLoader ?
                            <Grid container centered stretched columns={1} style={{height: '75vh'}}>
                                <Grid.Column verticalAlign={"middle"}>
                                    <Loader inline='centered' active size="large">{this.props.translationsConfig.loading[this.props.guiLanguage]}</Loader>
                                </Grid.Column>
                            </Grid> : null}
                    </Accordion.Content>
        </Grid.Row>
    }
}

function mapStateToProps(state) {
    const {URLReducer, DashboardReducer,ConfigReducer} = state
    const {translationsConfig, configJson} = ConfigReducer;
    const {fetchedDocumentsFromDashboard } = DashboardReducer
    const aggregations = fetchedDocumentsFromDashboard.aggregations
    const {filters, guiLanguage} = URLReducer
    return {translationsConfig, configJson, URLReducer, guiLanguage, fetchedDocumentsFromDashboard, aggregations,filters}
}


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(MultiCheckboxTreeFacetComputer);