import {
    Label,
    Grid,
    Header,
    Icon,
    Accordion,
} from "semantic-ui-react";
import React from "react";
import {connect} from "react-redux";
import {
    changeHTMLContentInCacheView,
    openJumpPortal
} from "../../../actions/CacheViewActions";

class SearchTermLabel extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            active:false,
            turnOnReference : sessionStorage.getItem('showSearchTerm') ? JSON.parse(sessionStorage.getItem('showSearchTerm')) :
                (this.props.configJson.generalOptions.showSearchTerm ? JSON.parse(this.props.configJson.generalOptions.showSearchTerm) : true),
            showDetails: false,
            translationWidth: 20
        }
    }


    handleOnChangeRadio = (value) => {
        sessionStorage.setItem('showSearchTerm', (value).toString() )
        this.setState({
            turnOnReference: value
        });
        this.props.changeHTMLContentInCacheView(this.props.singleDocumentData, this.props.configJson, this.props.URLReducer, this.props.fetchedDocumentsFromDashboard.searchResultDocumentIdList)
    }

    openPortal = () => {
        var markedWordList = []
        for(var i =0 ; i < this.props.numberOfMarkedWords; i++)
            markedWordList[i] = "selectedSearchTermWord" + i
        this.props.openJumpPortal(markedWordList, "")
    }

    generateNumberOfWordsString = () => {
        if(this.props.numberOfMarkedWords)
            return  <Label.Detail>
                        ({this.props.numberOfMarkedWords})
                    </Label.Detail>
        return null
    }

    render() {
        if(! this.props.URLReducer.searchTerm )
            return null

        return  <Grid.Row className={"generalGridRowCacheView " + (this.state.active ? "paddingBottomGrindRowActivated" : "paddingBottomGrindRowDeactivated")}>
                    <Accordion.Title className={"accordionTitleCacheView"} index={this.props.index} active={this.state.active}>
                        <Label className={"accordionLabelSideContentCacheView " + (this.state.turnOnReference ? "wlclight8Border" : "")}>
                            <Header onClick={() => this.setState({active : !this.state.active})} className={"accordionHeaderSideContentCacheView"} as='h4'>
                                <Grid>
                                    <Grid.Column width={3}>
                                        <Icon style={{fontSize:"0.8em", marginLeft: "20px", marginRight: "21px"}} className={"wlclight8Dot"} size={"mini"} name={"circle"}/>
                                    </Grid.Column>
                                    <Grid.Column width={13}>
                                        {this.props.translationsConfig.showSearchTerm[this.props.guiLanguage].toUpperCase()}
                                    </Grid.Column>
                                </Grid >
                            </Header>
                            <Icon onClick={() => this.handleOnChangeRadio(!this.state.turnOnReference)} size={"large"} className={"accordionIconSideContentCacheView"}
                                  name={this.state.turnOnReference ?"eye" :'eye slash'}/>
                        </Label>
                    </Accordion.Title>
                    <Accordion.Content active={this.state.active}>
                        <Grid style={{"marginRight":"0px"}}>
                            <Grid.Column width={1}/>
                            <Grid.Column width={12} floated={"left"}>
                                <Grid.Row onMouseLeave={()=> this.setState({showDetails: false})} onMouseEnter={()=>this.setState({showDetails: true})}>
                                    <Label className={"wlclight8"} style={{cursor: "pointer",
                                                                           display: "inline-block",
                                                                           transform: (this.state.showDetails ? "translateX(" +this.state.translationWidth +"px) scale(1.2)" : ""),
                                                                           transition: "all 0.15s ease-out"}}>
                                        {this.props.URLReducer.searchTerm} {this.generateNumberOfWordsString()}
                                        {this.state.turnOnReference && this.state.showDetails  ?
                                            <Icon onClick={() => this.openPortal()} style={{height:"unset", marginTop: "1.3px", marginLeft: "13px"}} className={"iconCursorPointerCustom"}
                                                  name={"step forward"}/>
                                        : null}
                                    </Label>
                                </Grid.Row>
                            </Grid.Column>
                        </Grid>
                    </Accordion.Content>
                </Grid.Row>
    }

}

const mapDispatchToProps = {
    changeHTMLContentInCacheView,
    openJumpPortal
};
function mapStateToProps(state) {
    const { LEv4Reducer, URLReducer, CacheViewReducer, DashboardReducer, ConfigReducer } = state
    const {fetchedDocumentsFromDashboard } = DashboardReducer
    const { guiLanguage } = URLReducer
    const {numberOfMarkedWords, singleDocumentData } = CacheViewReducer
    const {configJson, translationsConfig } = ConfigReducer
    const {id} = LEv4Reducer;
    return {configJson, URLReducer, translationsConfig, guiLanguage, numberOfMarkedWords, id, singleDocumentData, fetchedDocumentsFromDashboard}
}

export default connect(mapStateToProps, mapDispatchToProps) (SearchTermLabel);