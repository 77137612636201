import BuildCustomRequest from "../services/BuildCustomRequest";
import {logoutUser } from "./AuthActions";
export const TOGGLE_SIDEBAR_DASHBOARD = "TOGGLE_SIDEBAR_DASHBOARD"
export const APPEND_DOCUMENTS_IN_STORE = 'APPEND_DOCUMENTS_IN_STORE'
export const REMOVE_TO_DASHBOARD = "REMOVE_TO_DASHBOARD"
export const CHANGE_GUI_LANGUAGE ="CHANGE_GUI_LANGUAGE"
export const SEARCHING_FINISHED = "SEARCHING_FINISHED"
export const CHANGE_SORTING = "CHANGE_SORTING"
export const TIMEOUT_SEARCH_REQUEST_FROM_DASHBOARD = "TIMEOUT_SEARCH_REQUEST_FROM_DASHBOARD"
export const TIMEOUT_ADDITIONAL_SEARCH_REQUEST_FROM_DASHBOARD = "TIMEOUT_ADDITIONAL_SEARCH_REQUEST_FROM_DASHBOARD"
export const SAVE_Y_SCROLL_POSITION_TO_STORE = "SAVE_Y_SCROLL_POSITION_TO_STORE"
export const LOADING_SEARCH_RESULTS_DASHBOARD = "LOADING_SEARCH_RESULTS_DASHBOARD"
export const LOADED_SEARCH_RESULTS_DASHBOARD = "LOADED_SEARCH_RESULTS_DASHBOARD"
export const SAVE_MOBILE_STATE = "SAVE_MOBILE_STATE"
export const CHANGE_LOCATION = "CHANGE_LOCATION"
export const CHANGE_AGGREGATIONS = "CHANGE_AGGREGATIONS"
export const CHANGE_SIDEBAR_OUTSIDE_CLICK_HANDLER_FOR_CALENDAR = "CHANGE_SIDEBAR_OUTSIDE_CLICK_HANDLER_FOR_CALENDAR"

export const changeAggregationsValues = (newValue, field) => (dispatch) => {
	dispatch(dispatchAggregations(newValue, field));
}

export const toggleSideBarOutsideClickHandlerForCalendar = (newValue) => (dispatch) => {
	dispatch(dispatchToggleSideBarOutsideClickHandlerForCalendar(newValue));
}

export const dispatchToggleSideBarOutsideClickHandlerForCalendar = (newValue) => {
	return {
		type: CHANGE_SIDEBAR_OUTSIDE_CLICK_HANDLER_FOR_CALENDAR,
		newValue: newValue,
	};
}

export const dispatchAggregations = (newValue, field) => {
	return {
		type: CHANGE_AGGREGATIONS,
		newValue: newValue,
		field: field
	};
}

export const changeLocation = (isCacheView) => (dispatch) => {
	dispatch(dispatchLocation(isCacheView));
}

export const dispatchLocation = (isCacheView) => {
	return {
		type: CHANGE_LOCATION,
		payload: isCacheView
	};
}

export const toggleSidebarDashboard = (value) => (dispatch) => {
	dispatch(dispatchToggleSidebarDashboardSidebar(value));
}


export const changeSorting = (field, direction) => (dispatch) => {
	dispatch(dispatchChangeSorting(field, direction))
}


export const changeGuiLanguage = (newLanguage) =>  (dispatch) => {
	dispatch(dispatchGUILanguage(newLanguage))
}

export const handleDashboardClick = (pageYOffset) => (dispatch) => {
	dispatch(dispatchLinkClick(pageYOffset));
}


function getSearchResultDocumentIdList(fetchedDocumentsFromDashboard) {
	if(fetchedDocumentsFromDashboard === undefined)
		return null;
	return fetchedDocumentsFromDashboard.map(document => (
		document.leid
	));
}

export const processSearchRequest = (state, configJson, body, currentDocumentList) => async (dispatch) => {
	try {
		// This function is used for search request
		// If currentDocumentList is empty then we assume that a new search request is generated
		// If currentDocumentList is not empty, we will append the search response to the currentDocumentList
		if(currentDocumentList) {
			body.from = currentDocumentList.length;
		}else
			dispatch(dispatchLoadingDashboard())

		// If we have a new url state we need to generate a new request body, otherwise we just use the body from the function params and change the from value
		const customRequestBody = body ? body: BuildCustomRequest(state, configJson);

		// We run the async request
		const responseCustomApiJson = await runCustomApiRequest(customRequestBody, configJson);

		// We append or replace the response results
		currentDocumentList = currentDocumentList ? currentDocumentList.concat(responseCustomApiJson.documents) : responseCustomApiJson.documents
		// We create a new leid list from the currentDocumentList
		var searchResultDocumentIdList = getSearchResultDocumentIdList(currentDocumentList);

		dispatch(
			saveDocumentsInStore(currentDocumentList,
				responseCustomApiJson.totalNumberOfDocuments,
				responseCustomApiJson.hasMoreResults,
				responseCustomApiJson.aggregations,
				searchResultDocumentIdList,
				customRequestBody)
		);

		dispatch(dispatchLoadedDashboard())
		if(configJson)
			return Promise.resolve(responseCustomApiJson);
	} catch (error) {
		if(currentDocumentList)
			dispatch(timeoutAdditionalSearchRequestFromDashboard())
		else
			dispatch(timeoutSearchRequestFromDashboard())
		return Promise.reject(error);

	}
};

 const runCustomApiRequest= async (body, configJson) => {
	 var netlifyHost = process.env.REACT_APP_NETLIFYHOST;
	 if (netlifyHost === undefined)
		 netlifyHost = "";

	const controller = new AbortController();
	var timeout= configJson?.generalOptions?.timeout ? configJson.generalOptions.timeout : 10000;
	const id = setTimeout(() => controller.abort(), timeout);
	const response = await fetch( netlifyHost + "/.netlify/functions/searchQueryService", {
		method: "POST",
		body: JSON.stringify(body),
		credentials: 'include',
		signal: controller.signal
	});
	clearTimeout(id);


	if(response.status === 401) {
		logoutUser();
		window.location.reload();
	}

	if (process.env.NODE_ENV === 'development') {
		return response.json()
	}
	else {
		if (typeof console._commandLineAPI !== 'undefined') {
			console.API = console._commandLineAPI;
		} else if (typeof console._inspectorCommandLineAPI !== 'undefined') {
			console.API = console._inspectorCommandLineAPI;
		} else if (typeof console.clear !== 'undefined') {
			console.API = console;
		}
		console.API.clear()
		return response.json()
	}
}

export const dispatchLoadingDashboard = () => {
	return {
		type: LOADING_SEARCH_RESULTS_DASHBOARD
	};
}

export const dispatchLoadedDashboard = () => {
	return {
		type: LOADED_SEARCH_RESULTS_DASHBOARD
	};
}


 export const dispatchChangeSorting = (field, direction) => {
	 return {
		 type: CHANGE_SORTING,
		 payload: {field: field, direction:direction}
	 };
 }

export const dispatchLinkClick= (pageYOffset) => {
	return {
		type: SAVE_Y_SCROLL_POSITION_TO_STORE,
		payload: {pageYOffset}
	};
}

export const saveMobileState = (mobileState, viewportWidth) => (dispatch) => {
	dispatch(dispatchMobileState(mobileState, viewportWidth))
}

export const dispatchMobileState = (mobileState, viewportWidth) => {
	return {
		type: SAVE_MOBILE_STATE,
		payload: {mobileState: mobileState, viewportWidth: viewportWidth}
	};
}

export const saveDocumentsInStore = (documents, totalNumberOfDocuments, hasMoreResults, aggregations, searchResultDocumentIdList, body) => {
	return (dispatch) => {
		dispatch(appendDocumentsToStore(
			{documents: documents,
				totalNumberOfDocuments: totalNumberOfDocuments,
				hasMoreResults:hasMoreResults,
				aggregations: aggregations,
				searchResultDocumentIdList: searchResultDocumentIdList,
				body: body}
		));
	};
};

export const dispatchToggleSidebarDashboardSidebar = (value) => {
	return {
		type: TOGGLE_SIDEBAR_DASHBOARD,
		payload: value
	};
}




const dispatchGUILanguage = (newLanguage) => {
	return {
		type: CHANGE_GUI_LANGUAGE,
		payload: newLanguage
	};
}

const appendDocumentsToStore = (documentInfo) => {
	return {
		type: APPEND_DOCUMENTS_IN_STORE,
		payload: documentInfo
	};
};

const timeoutSearchRequestFromDashboard  = () => {
	return {
		type: TIMEOUT_SEARCH_REQUEST_FROM_DASHBOARD
	};
};

const timeoutAdditionalSearchRequestFromDashboard  = () => {
	return {
		type: TIMEOUT_ADDITIONAL_SEARCH_REQUEST_FROM_DASHBOARD
	};
};

