import {Accordion, Divider, Grid, Sticky} from "semantic-ui-react";
import React from "react";
import {connect} from "react-redux";

import SearchTermLabel from "../../../../sharedComponents/CacheViewSideMenuComponents/SearchTermLabel";
import UsedFiltersAccordion from "../../../../sharedComponents/CacheViewSideMenuComponents/UsedFiltersAccordion";
import "./weblaw_sidemenu_computer.css"
import {
    changeFocusedLabel,
    goBackToDashboard
} from "../../../../../actions/CacheViewActions";
import {Redirect} from "react-router";
import NavigationsButtons from "../../../../sharedComponents/CacheViewSideMenuComponents/NavigationsButtons";
import ReferenceButtons from "../../../../sharedComponents/CacheViewSideMenuComponents/ReferenceButtons";
import SortingButtons from "../../../../sharedComponents/CacheViewSideMenuComponents/SortingButtons";
import AccordionLabelContainer from "../../../../sharedComponents/CacheViewSideMenuComponents/AccordionLabelContainer";

class SideMenuComputer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }

    filterAndEnrichConfigAccordingToValuesFromDocument = (element) => {
        element.nestedMetadataMap = element.field.replace(/List$/, '') in this.props.nestedMetadataMap
        element.nestedReference = element.field.replace(/List$/, '') in this.props.nestedMetadataMap
        element.metadataKeywordTextMap = element.field in this.props.metadataKeywordTextMap
        element.metadataDateMap = element.field.replace(/List$/, '') in this.props.metadataDateMap
        return element.nestedMetadataMap || element.nestedReference || element.metadataKeywordTextMap || element.metadataDateMap
    }


    setBackToDashboardBoolean = () => {
        this.props.goBackToDashboard();
    }

    hasFilter = () => {
        return this.props.filters?.length>0
    }


    render() {
        return  <Grid.Column style={{width:"450px"}} only={"computer tablet"}>
                    <Sticky offset={0} context={this.props.contextRef} >
                        <div >
                            <Accordion onScroll={()=>this.props.changeFocusedLabel("")} style={{maxHeight: window.innerHeight}} id={"sideMenuCacheViewAccordionComputer"} fluid exclusive={false}>
                                {this.props.resultsLoaded && this.props.nestedMetadataMap &&
                                    <>
                                        <NavigationsButtons/>
                                        <Divider/>
                                        {(Object.keys(this.props.nestedMetadataMap).length > 0) && !this.props.metadataKeywordTextMap?.isMarkable ? <ReferenceButtons/> : null}
                                        <SortingButtons/>
                                        <div>
                                            {this.props.singleDocumentData ? <SearchTermLabel index={10000}/> : null}
                                            {this.props.configJson.filterMenu.filter(element => element.showInCacheView)
                                                .filter(element => this.filterAndEnrichConfigAccordingToValuesFromDocument(element))
                                                .map((item, index) => <AccordionLabelContainer key={index} item={item}/>)}
                                        </div>
                                        {this.hasFilter() ? <Divider/> : null}
                                        {this.hasFilter() ? <UsedFiltersAccordion cacheView={true} index={10001}/> : null}
                                        {this.props.newSearch ? <Redirect to={"/dashboard?" + this.props.URLString}/> : null}
                                    </>
                                }
                            </Accordion>
                        </div>
                    </Sticky>
                </Grid.Column>
    }
}

function mapStateToProps(state) {
    const { LEv4Reducer, URLReducer, CacheViewReducer, DashboardReducer,ConfigReducer} = state;
    const {resultsLoaded } = DashboardReducer
    const {filters, URLString} = URLReducer;
    const {newSearch} = LEv4Reducer;
    const {configJson } = ConfigReducer
    const { singleDocumentData }= CacheViewReducer
    const nestedMetadataMap = singleDocumentData.nestedMetadataMap;
    const metadataKeywordTextMap = singleDocumentData.metadataKeywordTextMap;
    const metadataDateMap = singleDocumentData.metadataDateMap;
    return {configJson, singleDocumentData, filters, URLString,resultsLoaded, nestedMetadataMap,metadataKeywordTextMap, metadataDateMap, newSearch};

}

const mapDispatchToProps = {
    goBackToDashboard,
    changeFocusedLabel
};

export default connect(mapStateToProps, mapDispatchToProps)(SideMenuComputer);