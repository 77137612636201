import {removeFilter, setFilter} from "../../../../../../../../actions/FilterActions";
import React from "react";
import {connect} from "react-redux";
import {Accordion, Grid, Header, Icon, Label} from "semantic-ui-react";
import {DateRangePicker} from 'rsuite';
import "./weblaw_dateRangePickerContainer_computer.css"



const mapDispatchToProps = {
    removeFilter,
    setFilter
};

class DateRangePickerContainerComputer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            active: this.props.item.accordionInitialState,
        };
        this.handleDateChange =this.handleDateChange.bind(this)
    }


    createFormatDateFrom = (date) => {
        var dateFrom = new Date(date.getTime() - (date.getTimezoneOffset() * 60000)).toJSON();
        return dateFrom
    }

    createFormatDateTo = (date) =>  {
        var dateTo = new Date(date.setHours(23, 59, 59, 999)).toJSON();
        return dateTo
    }

    handleDateChange = (data,event) => {
        if(data == null)
            this.props.removeFilter(this.props.item.field, undefined, this.props.item.filterType, this.props.URLReducer.filters, this.props.configJson.filterMenu)
        else if (data.length > 1 )
            this.props.setFilter(this.props.item.field,
                {'from': this.createFormatDateFrom(data[0]),'to': this.createFormatDateTo(data[1])},
                this.props.item.filterType, this.props.URLReducer.filters,
                this.props.configJson.filterMenu)
    }


    translateLanguageIntoLocals = () => {
        switch (this.props.guiLanguage) {
            case ("de"):
                return {
                    "sunday": "So",
                    "monday": "Mo",
                    "tuesday": "Di",
                    "wednesday": "Mi",
                    "thursday": "Do",
                    "friday": "Fr",
                    "saturday": "Sa",
                    "ok": "OK",
                    "today": "Heute",
                    "yesterday": "Gestern",
                    "last7Days": "Letzten 7 Tage",
                    "hours": "Std",
                    "minutes": "Minutes",
                    "seconds": "Sekunden"
                };
            case ("it"):
                return {
                    "sunday": "Do",
                    "monday": "Lu",
                    "tuesday": "Ma",
                    "wednesday": "Me",
                    "thursday": "Gi",
                    "friday": "Ve",
                    "saturday": "Sa",
                    "ok": "OK",
                    "today": "Oggi",
                    "yesterday": "Ieri",
                    "last7Days": "Ultimi 7 giorni",
                    "hours": "Ore",
                    "minutes": "Minuti",
                    "seconds": "Secondi"
                };
            case ("fr"):
                return {
                    "sunday": "Di",
                    "monday": "Lu",
                    "tuesday": "Ma",
                    "wednesday": "Me",
                    "thursday": "Je",
                    "friday": "Ve",
                    "saturday": "Sa",
                    "ok": "OK",
                    "today": "Aujourd'hui",
                    "yesterday": "Hier",
                    "last7Days": "Les 7 derniers jours",
                    "hours": "Heures",
                    "minutes": "Minutes",
                    "seconds": "Secondes"
                };
            case ("en"):
                return {
                    "sunday": "Su",
                    "monday": "Mo",
                    "tuesday": "Tu",
                    "wednesday": "We",
                    "thursday": "Th",
                    "friday": "Fr",
                    "saturday": "Sa",
                    "ok": "OK",
                    "today": "Today",
                    "yesterday": "Yesterday",
                    "last7Days": "Last 7 Days",
                    "hours": "Hours",
                    "minutes": "Minutes",
                    "seconds": "Seconds",
                };
            default:
                return {
                    "sunday": "So",
                    "monday": "Mo",
                    "tuesday": "Di",
                    "wednesday": "Mi",
                    "thursday": "Do",
                    "friday": "Fr",
                    "saturday": "Sa",
                    "ok": "OK",
                    "today": "Heute",
                    "yesterday": "Gestern",
                    "last7Days": "Letzten 7 Tage",
                    "hours": "Std",
                    "minutes": "Minutes",
                    "seconds": "Sekunden"
                };
        }
    }

    handleAccordionChange = () => {
        this.props.item.accordionInitialState = !this.state.active
        this.setState({active : !this.state.active})
    }


    render() {

        let dateFilter = this.props.URLReducer.filters?.find((f) => f.field === this.props.item.field)
        var dateValues = dateFilter?.values[0] ? Object.values(dateFilter.values[0]).map(date => new Date(date)) : [];
        var formattedDate = dateValues.length>1 ? [dateValues[0], dateValues[1]] : undefined

        return  <Grid.Row className={"generalGridRow " + (this.state.active ? "paddingBottomGrindRowActivated" : "paddingBottomGrindRowDeactivated")} key={this.props.index}>
                    <Accordion.Title className={"accordionTitle"} index={this.props.index} active={this.state.active} onClick={() => this.handleAccordionChange()}>
                        <Label className={"accordionLabelSideContent"}>
                            <Header className={"accordionHeaderSideContent"} as='h4'>
                                <Grid>
                                    <Grid.Column width={3}>
                                        <Icon style={{fontSize:"0.8em", marginLeft: "20px", marginRight: "21px"}} className={this.props.item.color +"Dot"} size={"mini"} name={"circle"}/>
                                    </Grid.Column>
                                    <Grid.Column width={13}>
                                        {this.props.item.label[this.props.guiLanguage]}
                                    </Grid.Column>
                                </Grid >
                            </Header>
                            <Icon size={"large"} className={"accordionIconSideContent"} name={this.state.active ?"angle down" :'angle up'}/>
                        </Label>
                    </Accordion.Title>
                    <Accordion.Content active={this.state.active}>
                        <div ref={this.container} style={{marginLeft: "42px", marginRight: "42px", marginTop : "19px"}}>
                            <DateRangePicker style={{width: "100%", paddingBottom : "14px"}}
                                             format={this.props.configJson.generalOptions.dateFormatLabels}
                                             placeholder={this.props.configJson.generalOptions.dateFormatLabels.toUpperCase()+" -  "+this.props.configJson.generalOptions.dateFormatLabels.toUpperCase()}
                                             onChange={this.handleDateChange}
                                             value={formattedDate}
                                             onClean={this.handleDateChange}
                                             locale={this.translateLanguageIntoLocals()}
                                             renderTitle={(date)=> date.toLocaleDateString('de-DE')}
                            />
                        </div>
                    </Accordion.Content>
                </Grid.Row>
    }
}
function mapStateToProps(state) {
    const { URLReducer,ConfigReducer } = state
    const {configJson, translationsConfig } = ConfigReducer
    const { searchTerm, guiLanguage}= URLReducer
    return {searchTerm,translationsConfig, configJson, URLReducer, guiLanguage}
}

export default connect(mapStateToProps,mapDispatchToProps) (DateRangePickerContainerComputer);