import {
    APPEND_DOCUMENTS_IN_STORE, CHANGE_AGGREGATIONS, CHANGE_SIDEBAR_OUTSIDE_CLICK_HANDLER_FOR_CALENDAR,
    LOADED_SEARCH_RESULTS_DASHBOARD,
    LOADING_SEARCH_RESULTS_DASHBOARD, SAVE_Y_SCROLL_POSITION_TO_STORE,
    TIMEOUT_ADDITIONAL_SEARCH_REQUEST_FROM_DASHBOARD,
    TIMEOUT_SEARCH_REQUEST_FROM_DASHBOARD,
    TOGGLE_SIDEBAR_DASHBOARD
} from "../actions/DashboardAction";
import {SAVE_SINGLE_DOC} from "../actions/CacheViewActions";

const initialState = {
    fetchedDocumentsFromDashboard: {},
    changeBodyHeader: true,
    showSidebarDashboard: false,
    searchRequestTimeout: false,
    additionalRequestTimeout: false,
    resultsLoaded: false,
    pageYOffset: 0,
    markedLeids: [],
    activateOutsideClickHandlerSideBarMobile: true
};

function DashboardReducer(state = initialState, action) {
    switch (action.type) {
        case APPEND_DOCUMENTS_IN_STORE:
            // This Action is used to store the results which are fetched from the query service.
            // We replace the variables to provoke a re-rendering in each relevant component.
            state.fetchedDocumentsFromDashboard.totalNumberOfDocuments = action.payload.totalNumberOfDocuments
            state.fetchedDocumentsFromDashboard.hasMoreResults = action.payload.hasMoreResults
            state.fetchedDocumentsFromDashboard.documents = action.payload.documents
            state.fetchedDocumentsFromDashboard.searchResultDocumentIdList = action.payload.searchResultDocumentIdList
            state.fetchedDocumentsFromDashboard.aggregations = action.payload.aggregations
            state.requestBody = action.payload.body
            state.changeBodyHeader = !state.changeBodyHeader
            state.additionalRequestTimeout =false
            state.searchRequestTimeout = false
            return {
                ...state
            }
        case TOGGLE_SIDEBAR_DASHBOARD:
            // This action is relevant for the mobile view.
            // It controls the side menu.
            state.showSidebarDashboard = !state.showSidebarDashboard;
            return {
                ...state
            }

        case TIMEOUT_SEARCH_REQUEST_FROM_DASHBOARD:
            // This boolean controls the error portal in the dashboard.
            // If the search params are changed, a new request is sent to the backend.
            // If this request runs into a timeout, the portal is displayed.
            state.searchRequestTimeout = true
            return {
                ...state
            }

        case TIMEOUT_ADDITIONAL_SEARCH_REQUEST_FROM_DASHBOARD:
            // The "infinite scroll" component sends request to the backend, if a certain scroll threshold is reached.
            // This is done to append the response to the result list.
            // If this request runs into a timeout, we don't want to display an errorPortal because it would cover
            state.additionalRequestTimeout = true
            return {
                ...state
            }
        // This boolean controls the loading screen in the dashboard.
        case LOADING_SEARCH_RESULTS_DASHBOARD:
            state.resultsLoaded = false
            return {
                ...state
            }
        case LOADED_SEARCH_RESULTS_DASHBOARD:
            // This boolean controls the loading screen in the dashboard.
            state.resultsLoaded = true
            return {
                ...state
            }
        case SAVE_Y_SCROLL_POSITION_TO_STORE:
            // When you move from the "dashboard" to the "cache view", the scrolling position is saved to this variable.
            // When you move back, the view is scrolled according to the value in this variable.
            state.pageYOffset = action.payload.pageYOffset;
            return {
                ...state
            }
        case SAVE_SINGLE_DOC:
            // This list contains all ids of the visited documents in the "cacheview".
            // Those documents are marked with special color in the result title.
            state.markedLeids.push(action.leid)
            return {
                ...state
            }
        case CHANGE_AGGREGATIONS:
            // This action changes the aggregation array in the multi checkbox facet
            state.fetchedDocumentsFromDashboard.aggregations[action.field] = action.newValue
            return {
                ...state
            }
        case CHANGE_SIDEBAR_OUTSIDE_CLICK_HANDLER_FOR_CALENDAR:
            state.activateOutsideClickHandlerSideBarMobile = action.newValue
            return {
                ...state
            }
        default:
            return state
    }
}
export default DashboardReducer;