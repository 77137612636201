import {
  APPEND_DOCUMENTS_IN_STORE,
  REMOVE_TO_DASHBOARD,
  CHANGE_GUI_LANGUAGE,
  SEARCHING_FINISHED,
  CHANGE_SORTING,
  SAVE_MOBILE_STATE,
  CHANGE_LOCATION
} from '../actions/DashboardAction';
import {LOGIN_REQUEST, LOGOUT_REQUEST, SUCCESS, FAILURE} from "../actions/AuthActions";


import {
  CHANGE_FILTER,
  CHANGE_SEARCH_TERM,
  CLEARED_FILTER,
  SHOW_DUPLICATE_FILTER_PORTAL
} from "../actions/FilterActions";
import {
  BACK_TO_DASHBOARD,
  SAVE_SORTING, SET_NEW_FOCUSED_LABEL,
} from "../actions/CacheViewActions";
import {SET_INITIAL_STATE_FROM_URL} from "../actions/URLActions";
import {CHANGED_OPENED_LABEL} from "../actions/MarkedHTMLActions";


const initialState = {
  username: "",
  isLoggedIn: "",
  newSearch: false,
  labelSorting: {},
  comesFromCacheView: false,
  showDuplicateFilterPortal: false,
  openedLabel: "",
  initialRequest: true,
  focusedLabel: "",
  mobile: false,
  viewportWidth: 0,
  isCacheView: false
};


function LEv4Reducer(state = initialState, action) {
  switch (action.type) {
    case SET_INITIAL_STATE_FROM_URL:
      // This action set the initalRequest flag to false. If the initalRequest flag is false, the component "app.js" sends a search request.
      // We need to wait until the url state is initiated because the url state is needed to generate the search request.
      // Label sorting is a json which shows the sorting state of the application.
      state.newSearch = true;
      state.initialRequest = false;
      state.labelSorting = {"turnOnSorting": JSON.parse(sessionStorage.getItem('turnOnSorting')),
        "alphabeticSorting": JSON.parse(sessionStorage.getItem('alphabeticSorting')),
        "sortDirection": JSON.parse(sessionStorage.getItem('sortDirection'))};
      return {
        ...state
      }
    case APPEND_DOCUMENTS_IN_STORE:
      // The APPEND_DOCUMENTS_IN_STORE is mainly used in the dashboard reducer, but some global flags are set in this reducer.
      state.newSearch = false;
      state.initialRequest = true
      return {
        ...state
      }
    case LOGIN_REQUEST:
    // The login action have global effects, which is why we have all login action in the LEv4Reducer.
    case LOGOUT_REQUEST:
      return {
        ...state,
      };
    case SUCCESS:
    case FAILURE:
      return {
        ...state,
        username: action.payload.username
      };
    case CHANGE_FILTER:
      // If we change filter (which is done in the URLReducer) we want to send a new search request, which is done by setting the newSearch flag to true.
      // If the comesFromCacheView is true, the documents who are stored in the redux store are displayed in the "dashboard" view. (this happens normally, when you push the back button in the "cacheview")
      state.comesFromCacheView = false
      state.newSearch = true;
      return {
        ...state
      }
    case CHANGE_SEARCH_TERM:
      // The search term is stored in the url state (URLReducer).
      // If we change the search term, we want to send a new search request
      state.newSearch = true;
      return {
        ...state
      }
    case CLEARED_FILTER:
      // The filter is cleared in the URL reducer.
      // We set the newSearch flag to send a new request.
      state.newSearch = true;
      return {
        ...state
      }
    case SAVE_SORTING:
      // If we change the sorting the "cacheview", the labelSorting json is replaced to provoke a re-rendering in relevant components.
      state.labelSorting = action.payload;
      return {
        ...state
      }
    case BACK_TO_DASHBOARD:
      // If the back button in the "cacheview" is pressed, we don't want to fetch a new request.
      // What we want is to display the documents from the redux store in the "dashboard view".
      // This is done by setting comesFromCacheView to true and ensure the newSearch is set on false.
      state.comesFromCacheView = true
      state.newSearch = false;
      return {
        ...state
      }
    case REMOVE_TO_DASHBOARD:
      // After we displayed the documents from the redux store in the "dashboard" view, we want to deactivate special behavior.
      // To achieve this, we reset the comesFromCacheView flag to false.
      state.comesFromCacheView = false
      return {
        ...state
      }
    case CHANGE_GUI_LANGUAGE:
      // If we change the guiLanguage, we want to send a new search request to the backend.
      // The guiLanguage is store in the URL reducer
      //state.newSearch = true;
      return {
        ...state
      }
    case SEARCHING_FINISHED:
      // If a search request is finished, we reset the newSearch flag.
      state.newSearch = false;
      return {
        ...state
      }
    case CHANGE_SORTING:
      // After we change the result sorting we want to send a new search request.
      state.newSearch = true;
      return{
        ...state
      }
    case SHOW_DUPLICATE_FILTER_PORTAL:
      // If we want to add a filter which is already in the filter array the user is informed by a portal, that the filter can't be added because he is allready set.
      // The portal is controlled by the showDuplicateFilterPortal flag.
      state.showDuplicateFilterPortal= action.payload;
      return {
        ...state
      }
    case CHANGED_OPENED_LABEL:
      // Only one label can be focused / opened in the whole application.
      // If we focus a label, we change the openedLabel string.
      // The labels are closed and opened accordingly to the value in openedLabel.
      state.openedLabel = action.payload
      return {
        ...state
      }
    case SET_NEW_FOCUSED_LABEL:
      // The focusedLabel controls which label is focused by the jump Portal in the "cacheview".
      state.focusedLabel = action.payload
      return {
        ...state
      }
    case SAVE_MOBILE_STATE:
      // The mobile state shows if the user is in the mobile view or not.
      // Further, we need the viewportWidth to compute the width of certain components in the "dashboard" or the "cacheview".
      state.mobile = action.payload.mobileState
      state.viewportWidth = action.payload.viewportWidth
      return {
        ...state
      }
    case CHANGE_LOCATION:
      // This flag shows if we are in the "cacheview" or not.
      // Components like "Label.js" need this flag for conditional rendering.
      state.isCacheView = action.payload
      return {
        ...state
      }
    default:
      return state
  }
}
export default LEv4Reducer
