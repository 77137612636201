import React from "react";
import {Grid} from "semantic-ui-react";
import HeaderMenuMobile from "./CacheViewMobileComponents/HeaderMenuMobile";
import ContentSegmentMobile from "./CacheViewMobileComponents/ContentSegmentMobile";


class CacheViewMobile extends React.Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

    render() {
        return  <>
                    <Grid.Row only={"mobile"}>
                        <HeaderMenuMobile/>
                    </Grid.Row>
                    <Grid.Row only={"mobile"} >
                        <ContentSegmentMobile/>
                    </Grid.Row>
                </>

    }

}

export default CacheViewMobile;