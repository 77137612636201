import React from "react";
import {connect} from "react-redux";


class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };

    }

    static getDerivedStateFromError() {
        return { hasError: true };
    }

    componentDidCatch(error, errorInfo) {
        window.location.href = '/error'
    }

    render() {
        if (this.state.hasError) {
            return (null);
        }

        return this.props.children;
    }
}

function mapStateToProps(state) {
    const { ConfigReducer } = state
    const {  configJson, translationsConfig} = ConfigReducer
    return {configJson, translationsConfig}
}

export default connect(mapStateToProps) (ErrorBoundary);