import axios from "axios";
export const LOGIN_REQUEST = "LOGIN_REQUEST";
export const LOGOUT_REQUEST = "LOGOUT_REQUEST";
export const SUCCESS = "SUCCESS";
export const FAILURE = "FAILURE";

const AUTH_URL = "/.netlify/functions/authenticate";
const AUTH_URL_SILENT = "/.netlify/functions/authenticateSilent";
export const authenticateUser = (username, password, ssoToken) => async (dispatch) => {
    dispatch(loginRequest());
    try {
        var netlifyHost = process.env.REACT_APP_NETLIFYHOST;
        if (netlifyHost === undefined)
            netlifyHost = "";

        let response;
        if(username == null && password == null){
            response = await axios.post(netlifyHost + AUTH_URL_SILENT, {},{
                headers: {mode: 'no-cors'}
            }).catch((error) => Promise.reject(error));
        }else{
            response = await axios.post(netlifyHost + AUTH_URL, {
                ...(username && {'username': username}),
                ...(password && {'password' : password}),
                ...(ssoToken && {'token' : ssoToken})
            },{
                headers: {mode: 'no-cors'}
            }).catch((error) => Promise.reject(error));
        }

        var displayName = response?.data.username ? response.data.username : username

        sessionStorage.setItem("jwtToken", response.data.jwttoken);
        sessionStorage.setItem("username", displayName);
        sessionStorage.setItem("isLoggedIn", "true");
        dispatch(success({ username: displayName}));

        return Promise.resolve(response.data);
    } catch (error) {
        dispatch(failure())
        return Promise.reject(error);
    }
};

export const logoutUser = () => {
    return (dispatch) => {
        dispatch(logoutRequest());
        sessionStorage.removeItem("jwtToken");
        sessionStorage.removeItem("username");
        sessionStorage.removeItem("isLoggedIn");
        localStorage.setItem("disableAutomaticLogin","true");
        dispatch(success({ username: "" }));
        if(sessionStorage.getItem("isLoginIn")) {
            var url = sessionStorage.getItem("isLoginIn");
            sessionStorage.removeItem("isLoginIn")
            window.location.href = url;
        }
    };
};

export const logInFromStorage = (username) => {
    return (dispatch) => {
        dispatch(success({ username: username }));
    };
};


const loginRequest = () => {
    return {
        type: LOGIN_REQUEST,
    };
};

const logoutRequest = () => {
    return {
        type: LOGOUT_REQUEST,
    };
};


export const success = (isLoggedIn) => {
    return {
        type: SUCCESS,
        payload: isLoggedIn,
    };
};

const failure = () => {
    return {
        type: FAILURE,
        payload: false,
    };
};